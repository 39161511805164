// import Cookies from 'js-cookie'
import { getConfigByCode, requestUserInfo, requestUserMenu, requestUserMenuName, requestUserPerm } from "api/login/login-api";
import { initMenu } from "@/utils/common";
// import Customer from '@/api/server/customermanage'

const state = {
    loginUser: {}, // 当前登录用户
    tyUserInfo: {}, //当前登录用户对应的天翼用户信息
    menuList: [],
    userPerm: [], // 来自服务器的用户权限
    currAuth: [], // 本地路由授权
    // userRoleList: {}, // loginUser.roleList
    // customerList: {},
    talkConfiguration: null // 话术赛道配置
},
  mutations = {
    setTyUserInfo(state, data){
      state.tyUserInfo = data
    },

    // 刷新页面重新获取用户信息
    setLoginUser(state, data) {
      console.log('data用户vuex信息',data);
      state.loginUser = data;
      // state.userRoleList = data.roleList[0];
    },
    // 登录设置状态
    // login (state, data) {
    //   localStorage.userToken = data.token
    //   localStorage.trackType = data.trackType
    // },
    setUserPerm (state, data) {
      state.userPerm = data
    },
    setCurrAuth (state, data) {
      state.currAuth = data
    },
    logout () {
      // Cookies.remove('access')
      // // 恢复默认样式
      // let themeLink = document.querySelector('link[name="theme"]')
      // themeLink.setAttribute('href', '')
      window.location.reload()
      // 清空打开的页面等数据，但是保存主题数据
      // let theme = ''
      // if (localStorage.theme) {
      //   theme = localStorage.theme
      // }
      localStorage.clear()
      // if (theme) {
      //   localStorage.theme = theme
      // }
    },
    // setCustomerList (state, data) {
    //   state.customerList = data
    // },
    setTalkConfiguration (state, data) {
      state.talkConfiguration = data
    },
    setMenuList(state, result) {
      state.menuList = result
    }
  },
  actions = {
    logout: ({commit}) => { // 执行多个 mutations 就需要用 action ,可以在这里触发其他的mutations方法
      commit('logout')
    },
    // 获取用户相关信息
    getUserInfo({ state, commit, dispatch }) {
      return new Promise((resolve, reject) => {
        requestUserInfo()
          .then((data) => {
            console.log(state)
            let { result } = data;
            console.log('result用户信息',result);
            commit("setLoginUser", result);
            dispatch("getUserMenu");
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 获取用户权限
    getUserPerm ({state, commit}, refresh) {
      return new Promise((resolve, reject) => {
        // 是否从缓存获取
        if (state.userPerm.length === 0 || refresh) {
          requestUserPerm().then(data => {
            let {code, result} = data
            if (code === 0) {
              commit('setUserPerm', result)
              resolve(result)
            } else {
              resolve([])
            }
          }).catch(err => {
            reject(err)
          })
        } else {
          resolve(state.userPerm)
        }
      })
    },
    // 获取用户菜单权限
    getUserMenuName ({state, commit}, refresh) {
      return new Promise((resolve, reject) => {
        console.log(commit)
        // 是否从缓存获取
        if (state.currAuth.length === 0 || refresh) {
          requestUserMenuName().then(data => {
            let {code, result} = data
            if (code === 0) {
              // commit('setCurrAuth', result)
              resolve(result)
            } else {
              resolve([])
            }
          }).catch(err => {
            reject(err)
          })
        } else {
          resolve(state.currAuth)
        }
      })
    },
    // 获取用户左侧菜单
    getUserMenu ({state, commit}) {
      console.log(state)
      return new Promise((resolve, reject) => {
        requestUserMenu().then(data => {
          let {code, result} = data
          let menuArr = []
          if (code === 0) {
            result.map(item => {
              if(item.clientType === 2) {
                menuArr = initMenu(item.children)
              }
            })
            commit('setMenuList', menuArr)
            resolve(result)
          } else {
            resolve([])
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 获取销售管理列表查询字段
    //   getCustomerSerchList ({state, commit}, refresh) {
    //     return new Promise((resolve, reject) => {
    //       Customer.getSelfdefSearchList().then(data => {
    //         let {result} = data
    //         commit('setCustomerList', result)
    //         resolve(data)
    //       }).catch(err => {
    //         reject(err)
    //       })
    //     })
    //   },
      getTalkConfiguration({state, commit}, refresh) {
        return new Promise((resolve, reject) => {
          getConfigByCode('MARKETING_WORDS_ART').then(data => {
            let { msg } = data
            commit('setTalkConfiguration', msg)
            resolve(msg)
          }).catch(err => {
            reject(err)
          })
        })
      }
  },
  // 相当于计算属性，可以自定义state所相关的属性，比如取反
  getters = {
    loginUser: (state) => {
      return state.loginUser;
    },

    getTyUserInfo:(state)=>{
      return state.tyUserInfo;
    }
    // getUserRoleList: state => state.userRoleList,
    // getCustomerList: state => state.customerList
  }

export default { state, mutations, actions, getters };
