/*
 * @Description:
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-04-28 11:23:37
 * @LastEditors: in hengqi by mengze
 * @LastEditTime: 2021-07-21 16:04:18
 */
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const importModules = (context) => {
  let map = {};

  for (const key of context.keys()) {
    const keyArr = key.split("/");
    keyArr.shift(); // 移除.

    map[keyArr.join(".").replace(/\.js$/g, "")] = context(key).default;
  }
  return map;
};

const modules = importModules(require.context("./modules", false, /\.js$/));

// 导入所有 vuex 模块，自动加入namespaced:true，用于解决vuex命名冲突
Object.keys(modules).forEach(() => {
  // modules[key]["namespaced"] = true;
});

const store = new Vuex.Store({
  modules,
});

export default store;
