/*
 * @Description:
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-12 15:53:49
 * @LastEditors: Seven
 * @LastEditTime: 2021-06-29 14:10:18
 */
/**
 * @class Socket
 *  重连Socket 机制
 *  by wang
 */
class Socket {
  constructor(option) {
    this.websocket = null;
    this.lockReconnect = false;
    // this.socketUrl = option.socketUrl;
    this.extnumber = option.extnumber
    // this.socketUrl = `ws://crm1.zthltx.com:345/websocket`;
    if(option.token !== undefined) {
      this.socketUrl = option.socketUrl + option.token;
    } else {
      this.socketUrl = option.socketUrl;
    }
    this.callback = option.soketCallback;
    console.log('this.callback', this.callback);
    this.websocket = null;
    this.lockReconnect = false;
    this.timeout = 10000;

    this.createWebSocket();
  }

  createWebSocket() {
    console.log('this.socketUrl', this.socketUrl);
    try {
      this.websocket = new WebSocket(this.socketUrl);
      // this.websocket.extnumber = this.extnumber
      this.initEventHandle();
    } catch (e) {
      console.log('e-----',e);
      this.reconnect(this.socketUrl, this.timeout);
    }
  }

  initEventHandle() {
    let that = this;
    this.websocket.onopen = () => {
      // console.log('this.extnumber', this.extnumber);
      if (this.extnumber) {
        that.websocket.send(`CONNECTING#${this.extnumber}#`);
      } else {
        that.websocket.send("socket已经打开...");
      }
    };
    this.websocket.onmessage = (evt) => {
      that.socketData = evt.data;
      let data = JSON.parse(that.socketData);
      console.log('data-------链接成功后', data);
      if (data.extnumber !== '') {
        that.callback(data);
        let statusType = data.data.status
        localStorage.setItem('uuid', data.data.uuid ? data.data.uuid : '')
        console.log('statusType', statusType);
        switch (statusType) {
          case "idle": // 空闲
            console.log('soket回调', data);
            window.parent.vue.$store.commit('setTinetCallStatuszthl', 'idle')
            break;
          case "offline": // 离线
          window.parent.vue.$store.commit('setTinetCallStatuszthl', 'offline')
            break;
          case "hangup": // 挂断
            console.log('this', this);
            window.parent.vue.$store.commit('setTinetCallStatuszthl', 'hangup')
            break;
          case "answer": // 通话中
          window.parent.vue.$store.commit('setTinetCallStatuszthl', 'answer')
            break;
          case "ring": // 响铃
          window.parent.vue.$store.commit('setTinetCallStatuszthl', 'ring')
            break;
          }
      } else {
        switch (data.type) {
          case 1000: // token验证失败
            that.lockReconnect = true;
            break;
          case 1001: // 主动断开
            that.lockReconnect = true;
            break;
          default:
            if (typeof that.callback === "function") {
              that.callback(data);
            }
        }
      }
    };
    this.websocket.onclose = () => {
      that.reconnect(that.timeout);
    };
    this.websocket.onerror = (error) => {
      that.reconnect(that.timeout);
      console.log("socket连接错误" + error);
    };
  }

  reconnect(timeout) {
    if (this.lockReconnect) return;
    this.lockReconnect = true;
    // 没连接上会一直重连，设置延迟避免请求过多
    setTimeout(() => {
      this.createWebSocket();
      this.lockReconnect = false;
    }, timeout);
  }
}

export default Socket;
