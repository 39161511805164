var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "el-button",
        {
          staticClass: "hq-button",
          attrs: {
            disabled: _vm.currCol.status === 1,
            type: "success",
            icon: "el-icon-delete"
          },
          on: {
            click: function($event) {
              _vm.invalidShow = true
            }
          }
        },
        [_vm._v("无效")]
      ),
      _c(
        "HqModal",
        {
          attrs: { visible: _vm.invalidShow, title: "无效", width: "400" },
          on: { "on-cancel": _vm.cancel }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.form, rules: _vm.invalidRules }
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "type" } },
                        [
                          _c("dict-select", {
                            attrs: { code: "INVALID_TYPE" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(props) {
                                  return _c("v-select", {
                                    attrs: {
                                      items: props.dictData,
                                      "menu-props": {
                                        bottom: true,
                                        offsetY: true
                                      },
                                      label: "类型",
                                      "item-text": "label",
                                      "item-value": "value",
                                      outlined: "",
                                      dense: true,
                                      height: 32,
                                      clearable: true,
                                      "clear-icon": "$clear",
                                      "hide-details": "auto"
                                    },
                                    model: {
                                      value: _vm.form.type,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "type", $$v)
                                      },
                                      expression: "form.type"
                                    }
                                  })
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "memo" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              outlined: "",
                              height: 80,
                              name: "input-7-4",
                              label: "备注",
                              value: ""
                            },
                            model: {
                              value: _vm.form.memo,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "memo", $$v)
                              },
                              expression: "form.memo"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }