/*
 * @Author: your name
 * @Date: 2020-09-02 10:27:30
 * @LastEditTime: 2022-03-16 16:35:40
 * @LastEditors: Seven
 * @Description: In User Settings Edit
 * @FilePath: \workbench-front\src\utils\fetch.js
 */
import axios from "axios"
import allApi from "@/api/order/index.js"
import { Message } from "element-ui"
import auth from "../utils/auth"
import Checker from "./Checker"
// import store from '../store'


const httpCodeMap = {
  // 招生工作台token过期状态码
  WORKBENCH_TOKEN_ERROR: 1132,
  // 网校token过期状态码
  ONLINE_SCHOOL_TOKEN_ERROR: 40002,
  // 服务器部署状态码
  SERVER_DEPOLY: 503,
  // 服务器错误
  SERVER_ERROR: 500
}

const service = axios.create({
  // timeout: 10000 * 2,
  timeout: 20000 * 2,
  withCredentials: true,
  // headers: {
  //   "content-type": "application/json",
  // },
  baseURL: ""
})


service.interceptors.request.use((req) => {
  if (auth.getToken()) {
    req.headers["hqjy-share-token"] = auth.getToken()
  }
  req.headers['token'] =  localStorage.getItem("onlineSchoolToken")
  let contentType = req.headers["content-type"];


  console.log("contentType: "+contentType)
  if(  contentType &&  contentType.indexOf("application/x-www-form-urlencoded") > -1   ){
    let data = req.data || {};
    let dataStr = "";
    Object.keys(data).forEach( key=>{
      dataStr += `${key}=${data[key]}&` 
    })
    dataStr = dataStr.replace(/&$/, '');
    req.data = encodeURI(dataStr);
    return req;
  }
  
  
  //auth.getOnlineSchoolToken()
  if (req && req.data && typeof req.data != 'string') {
    Object.keys(req.data).forEach(key => {
      if(typeof(req.data[key]) === 'string') {
        req.data[key] = req.data[key].trim()
      }
    })
  }

  return req
})


service.interceptors.response.use(
  (res) => {
    let { status, data } = res
    const { code } = data
    // const redirectUrl = window.location.href
    // const host = window.location.origin
    if (status >= 200 && status <= 300) {

      if (code === httpCodeMap.WORKBENCH_TOKEN_ERROR) {
        //  67-72 暂时注释
        // debugger
        // window.location.href = `${host}/login`
        // return
      }
      if (code == httpCodeMap.ONLINE_SCHOOL_TOKEN_ERROR) {
        getOnlineSchoolToken();
        return
      }
      // 提交订单接口需要获取code为500的状态码
      let url = '/qw_school/schoolcj/order/api/payModule/enrollPlatform/web/createOrder'
      let collectUrl = '/qw_school/schoolcj/order/api/payModule/webApi/web/billLinkInfo'
      if (code == httpCodeMap.SERVER_ERROR && res.config.url != url && res.config.url != collectUrl) {
        // Message.error(errorMsg)
        return Promise.reject(data)
      }

      data = data || {
        data: {
        }
      };

      let transferData = {};
      //如果是200，且是分情形，则转换结构；否则不处理
      if(data.code == 200 && data.data && Array.isArray( data.data.list) ){
        let innerData = data.data;
        transferData = {
          code: data.code,
          msg: data.msg || "",
          data: {
            ...innerData,
            dataList: innerData.list,
            pageSize: innerData.pages || innerData.pageSize,
            totalPage:innerData.pages || innerData.totalPage,
            totalCount: innerData.total || innerData.totalCount
          }
        }
        // delete transferData.list;
      }else {
        transferData = data;
      }
      return transferData
    } else {
      // Message.error(data.msg)
      return Promise.reject(data)
    }
  },
  (error) => {
    console.log('error', error);
    if (error && error.response && error.response.data) {
      Message.error(error.response.data)
    }
    else {
      Message.error('接口调用失败')
      // Message.error('服务器部署中')
    }
    return null
  },
)

function resolveUrlParams(urlParams, newApi, api) {
  if (Array.isArray(urlParams)) {
    let suffix = urlParams.reduce((accu, cur) => {
      return accu += `${cur}/`
    }, '').slice(0, -1)
    newApi.url = `${api.url}/${suffix}`
  } else if(Object.prototype.toString.call(urlParams) == "[object Object]") {
    let param
    for (let key in urlParams) {
      param = `${key}=${urlParams[key]}`
    }
    newApi.url = `${api.url}?${param}`
  } else {
    newApi.url = `${api.url}/${urlParams}`
  }
}

async function fetch(apiName, params, urlParams, isType) {
  
  const api = allApi[apiName]
  if (typeof api !== "object") {
    throw new Error(`调用api错误,请检查函数名${apiName}`)
  }
  const newApi = { ...api }
  // newApi.headers = {}
  const { method, isQueryPost, isUrlParams, headers } = api
  if (headers) {
    Object.keys(headers).forEach(key => {
      newApi[key] = api[key]
    })
  } else {
    newApi['headers'] =  {
      "content-type": "application/json",
    }
  }
  // url路径是动态的
  if(isType) {
    newApi.url = newApi.url.replace('type', isType)
  }

  if (method === "get" || isQueryPost)
    newApi.params = params
  else
    newApi.data = params

  if (isUrlParams) {
    resolveUrlParams(urlParams, newApi, api)
  }


  return service(newApi)
}


/***
 * 设立一个定时执行获取网校token的方法
 */
function  getOnlineSchoolToken(){
  const params = {
    appKey: "sellsystem",// process.env.VUE_APP_ONLINE_APP_KEY,
    appSecret: "1uTmfuVHHPLARdvA" // process.env.VUE_APP_ONLINE_SECRET_KEY
  }

  fetch("getOnlineSchoolToken", params).then( res =>{
    res = res || {};
    let data = res.data || {}
    if( res.code == 200 ){
      localStorage.setItem("onlineSchoolToken", data.token );
    }
  })
}

if( window.timer != null ){
  clearInterval(window.timer);
}

getOnlineSchoolToken();
window.timer = setInterval( ()=>{
  getOnlineSchoolToken()
}, 1800*1000)


export default fetch
