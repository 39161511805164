import { render, staticRenderFns } from "./appoint.vue?vue&type=template&id=581bfc22&scoped=true&"
import script from "./appoint.vue?vue&type=script&lang=js&"
export * from "./appoint.vue?vue&type=script&lang=js&"
import style0 from "./appoint.vue?vue&type=style&index=0&id=581bfc22&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "581bfc22",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VBtn,VDatePicker,VMenu,VSelect,VSpacer,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("E:\\qikecheng_new\\allnet_crm\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('581bfc22')) {
      api.createRecord('581bfc22', component.options)
    } else {
      api.reload('581bfc22', component.options)
    }
    module.hot.accept("./appoint.vue?vue&type=template&id=581bfc22&scoped=true&", function () {
      api.rerender('581bfc22', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/customer/components/appoint.vue"
export default component.exports