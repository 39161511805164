/*
 * @Description:
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-05-12 09:24:45
 * @LastEditors: Seven
 * @LastEditTime: 2021-12-15 10:04:26
 */
// import { ElNotification, Message } from 'element-plus';
import {
  Message
} from 'element-ui';
import {
  getUserFieIdGetByCustomerId,
  reqTinetSetTel,
} from "api/call/tinet-api";
import {
  reqCallRecordSave,
  reqPhoneAttr
} from "api/call/call-api";
// import StaffMap from '@/api/server/staffMap'

const state = {
    // CallCenterjs 加载完成标识
    tinetWsScript: false,
    // CallCenter主动离线状态
    tinetLogoutStatus: false,
    // CallCenter登录状态
    tinetLoginStatus: {
      status: "offline",
      type: "primary",
      show: "离线",
    },
    // CallCenter设备状态，就是打电话的状态
    tinetDeviceStatus: "idle",
    autoCall: true,
    // 外呼号码
    tinetCallTel: null,
    // 电话号码归属地
    tinetCallAttribution: "未知地区",
    // 外呼电话归属人的customId--在context.dispatch('getCustomerByPhone', phone)后
    // 拿到customerId后传给后台，拿一个userFieIdGetByCustomerId，如果没有customeId传0
    // customerIdForRecord: 0,
    // 通过customerId从后台拿的id，挂电话后传给CallCenter，CallCenter在整理好记录后会将记录推送给后台
    // userFieIdGetByCustomerId: '',
    // 外呼号码信息
    tinetCallInfo: null,
    // 外呼状态 0空闲，1拨号，2振铃,3客户接听，4挂机开始整理
    tinetCallStatus: 0,
    // 外呼状态 idle空闲，ring振铃,answer通话中，hangup挂断， offline离线
    tinetCallStatuszthl: '',
    // 通话时长
    tinetCallTimes: "00:00:00",
    CallTimes: "00:00:00",
    orangeCallErrorId: null,
    timer: '',
    // 计时器ID
    tinetIntervalId: null,
    // 坐席呼叫失败计时ID
    tinetCallErrorId: null,
    // 是否需要校验电话
    // tinetTelValidate: false,
    // 打电话工具条--true通话中--false保持中
    pauseOrPlayStatus: true,
    // 改用户是否存在于多个赛道中
    ifMultiTrack: false,
    // 连接ws按钮加载
    conLoading: false,
    // 区号
    AreaCode: null,
    // 客户轨迹手机号码查询
    trackTelNum: null,
    // 是否回呼
    backCall: false,
    // 回呼整理
    clearUp: false,
  },
  mutations = {
    setIfMultiTrack(state, status) {
      state.ifMultiTrack = status;
    },
    setPauseOrPlayStatus(state, status) {
      state.pauseOrPlayStatus = status;
    },
    setTinetWsScript(state, data) {
      state.tinetWsScript = data;
    },
    setTinetLogoutStatus(state, data) {
      state.tinetLogoutStatus = data;
    },
    setTinetLoginStatus(state, data) {
      state.tinetLoginStatus = data;
    },
    setTinetDeviceStatus(state, data) {
      state.tinetDeviceStatus = data;
    },
    setTinetautoCallStatus(state, data) {
      state.autoCall = data;
    },
    setTinetCallTel(state, data) {
      state.tinetCallTel = data;
    },
    setTinetAttribution(state, data) {
      state.tinetCallAttribution = data;
    },
    setTinetCallInfo(state, data) {
      state.tinetCallInfo = data;
    },
    setTinetCallStatus(state, data) {
      // 如果是回呼那么通话结束的话默认是5，不给通话栏影藏
      if (state.backCall) {
        if (data === 0) {
          state.tinetCallStatus = 5;
        } else {
          state.tinetCallStatus = data;
        }
      } else {
        state.tinetCallStatus = data;
      }
    },
    // 众投互联
    setTinetCallStatuszthl(state, data) {
      console.log('vuex', state, data);
      state.tinetCallStatuszthl = data;
    },
    setTinetCallTimes(state, data) {
      state.tinetCallTimes = data;
    },
    setTinetIntervalId(state, data) {
      state.tinetIntervalId = data;
    },
    setTinetCallErrorId(state, data) {
      state.tinetCallErrorId = data;
    },
    // setTinetTelValidate (state, data) {
    //   state.tinetTelValidate = data
    // },
    // 初始化CallCenter
    setInitTinet(state) {
      state.tinetLoginStatus = {
        status: "offline",
        type: "primary",
        show: "离线",
      };
      state.tinetDeviceStatus = "idle";
      state.autoCall = true;
      state.tinetCallTel = null;
      state.tinetCallInfo = null;
      state.tinetCallStatus = 0;
    },
    setConLoading(state, data) {
      state.conLoading = data;
    },
    setAreaCode(state, data) {
      state.areaCode = data;
    },
    setTrackTelNum(state, data) {
      state.trackTelNum = data;
    },
    setBackCall(state, data) {
      state.backCall = data;
    },
    setClearUp(state, data) {
      state.clearUp = data;
    },
    setCallTimes(state, data) {
      state.CallTimes = data;
    },
    setOrangeCallErrorId(state, data) {
      state.orangeCallErrorId = data
  },
  setTimer(state) {
    state.timer = null
}
  },
  actions = {
    countTime({state, commit},startTime) {
      if (!startTime) return;
            let start_time = new Date(startTime);
            console.log('timer定时器', state.timer);
            state.timer = setInterval(() => {
                let millisecond = new Date() - start_time;
                let h = Math.floor(millisecond / (60 * 60 * 1000));
                h = h < 10 ? "0" + h : h;
                let min = Math.floor((millisecond % (60 * 60 * 1000)) / (60 * 1000));
                min = min < 10 ? "0" + min : min;
                let sec = Math.floor(
                    ((millisecond % (60 * 60 * 1000)) % (60 * 1000)) / 1000
                );
                sec = sec < 10 ? "0" + sec : sec;
                state.CallTimes = h + ":" + min + ":" + sec;
                console.log('进入了计时', state.CallTimes);
                commit('setCallTimes', state.CallTimes)
            }, 1000);
    },
    // 清除定时器
    actionClearIntervalzthl({ state, commit }) {
      if (state.orangeCallErrorId) {
          console.log('清除拨号定时:' + state.orangeCallErrorId)
          clearInterval(state.orangeCallErrorId)
          commit('setTinetCallErrorId', null)
      }
  },
  // 清楚橙子定时器
  ClearOrangetime({ state, commit }) {
    console.log('清楚定时器',state);
    // clearInterval(state.orangeCallTimes)
    clearInterval(state.timer)
    commit("setTimer", null)
    commit('setCallTimes', '00:00:00')
},
    // 拿到customerId后，去后台拿userFieId
    async getUserFieIdGetByCustomerId({
      dispatch
    }, params) {
      try {
        const res = await getUserFieIdGetByCustomerId(params.customerId);
        if (res.code === 0) {
          // commit('setUserFieIdGetByCustomerId', res.result)
          // 一键外呼
          dispatch("autoClidOutCall", {
            tel: params.tel.tel,
            requestUniqueId: res.result
          }, );
        } else {
          // commit('setUserFieIdGetByCustomerId', 0)
          // commit('pushUserFieIdToTR': 0)
          // 没拿到不推，直接挂
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 刷新Iframe
    actionReloadIframe() {
      localStorage.tinetToLogin = false;
      document
        .getElementById("tinetIframe")
        .contentWindow.document.location.reload();
    },
    // 执行CallCenter方法
    executeAction({
      state
    }, {
      params
    }) {
      document
        .getElementById("tinetIframe")
        .contentWindow.CTILink.Agent.login(params, function (result) {
          if (result.code === "0") {
            window.parent.vue.cbLogin(result);
          } else {
            state.conLoading = false;
            Message({
              message: result.msg,
              type: "info",
            });
          }
        });
    },
    // 一键外呼
    autoClidOutCall({
      state
    }, params) {
      const callParams = {
        cno: localStorage.getItem("cno"),
        ...params
      }
      document
        .getElementById("tinetIframe")
        .contentWindow.CTILink.Agent.autoClidOutCall(callParams, function (result) {
          if (result.code === "0") {
            state.autoCall = false;
          }
          window.parent.vue.cbPreviewOutCall(result);
        });
    },
    // CallCenter方法取消外呼
    cancelCall() {
      document
        .getElementById("tinetIframe")
        .contentWindow.CTILink.Session.previewOutcallCancel({},
          function (result) {
            if (result.code === "0") {
              window.parent.vue.cbPreviewOutCallCancel();
            } else {
              document
                .getElementById("tinetIframe")
                .contentWindow.CTILink.Session.unlink({}, function () {
                  window.parent.vue.cbUnLink();
                });
            }
          }
        );
    },
    // 登录CallCenterwebsocket
    actionTinetdoLogin({
      dispatch
    }, data) {
      let params = {
        // bindType: '1',
        validateType: "2",
      };
      params.bindType = data.bindType;
      params.bindTel = data.bindTel;
      params.cno = data.cno;
      params.enterpriseId = data.enterpriseId.toString();
      params.token = data.securityToken;
      params.loginStatus = 1;
      params.pauseDescription = 1;
      params.sessionKey = data.sessionkey;
      params.webSocketUrl = data.webSocketUrl;
      localStorage.setItem("cno", data.cno)
      // dispatch("getFindUserExtendByCno", params.cno);
      dispatch("executeAction", {
        params: params
      });
    },
    // CallCenter登录成功回调，设置绑定号码到系统
    async actionSysSetTel({_},tel) {
      console.log(_)
      await reqTinetSetTel(tel);
      // 设置自动登录标识
      localStorage.tinetToLogin = true;
    },
    // 退出登录
    actionTinetDoLogout() {
      // logoutMode 0 不退出电话只退出座席  1退出电话同时退出座席
      // removeBinding 0 不解除绑定电话，1 解除
      let params = {
        logoutMode: 1,
        removeBinding: 1
      };
      document
        .getElementById("tinetIframe")
        .contentWindow.CTILink.Agent.logout(params, function (result) {
          if (result.code === "0") {
            localStorage.removeItem("cno")
            window.parent.vue.cbLogout(result);
          }
        });
      // 设置自动登录标识
      localStorage.tinetToLogin = false;
    },
    // 执行外呼
    doTinetCallOut({
      state,
      commit,
      dispatch
    }, {
      tel,
      customerId = 0
    }) {
      if (state.tinetLoginStatus.status === "offline") {
        Message({
          message: "CallCenter为离线状态，不能拨号",
          type: "info",
        });
        return;
      }
      if (tel.tel.length === 0) {
        Message({
          message: "电话号码不能为空",
          type: "info",
        });
        return;
      }
      // 没有计时器的时候才设置新的计时器
      if (!state.tinetCallErrorId) {
        let callErrorId = setInterval(function () {
          if (state.tinetCallStatus !== 0) {
            // ElNotification({
            //   title: "呼叫坐席失败",
            //   message: "请检查坐席当前电话状态",
            //   duration: 0,
            //   type: "info",
            // });
            // 如果呼叫40秒坐席未接听，当作呼叫失败，取消呼叫
            dispatch("cancelCall", {});
            commit("setTinetCallStatuszthl", 'hangup');
          }
          // 如果呼叫40秒坐席未接听，当作呼叫失败
        }, 40000);
        // 计时器保存到内存
        commit("setTinetCallErrorId", callErrorId);
      }
      // 拨打电话自动弹屏
      dispatch("gotoCustomerInfoByPhone", {
        tel,
        customerId
      });
      // }
      // 查询归属地Attribution
      dispatch("actionPhoneAttr", tel.tel);
    },
    // 外呼+弹屏
    actionTinetCallOutAndGoto({dispatch}, tel) {
      dispatch("doTinetCallOut", {
        tel: tel,
        gotoInfo: true
      });
    },
    // 单纯外呼
    actionTinetCallOut({
      dispatch
    }, {
      tel,
      customerId
    }) {
      // 如果通话工具栏未关闭的话就不让进行外呼操作
      if (this.getters.getBackCall) {
        Message({
          message: "请先关闭通话工具栏再进行外呼操作",
          type: "info",
        });
        return false;
      }
      dispatch("doTinetCallOut", {
        tel,
        gotoInfo: false,
        customerId
      });
    },
    // 清除定时器
    actionClearInterval({
      state,
      commit
    }) {
      if (state.tinetCallErrorId) {
        console.log("清除拨号定时:" + state.tinetCallErrorId);
        clearInterval(state.tinetCallErrorId);
        commit("setTinetCallErrorId", null);
      }
    },
    // 取消外呼
    actionTinetCallCancel({
      dispatch
    }) {
      dispatch("cancelCall", {});
    },
    // 拒接
    actionTinetDoRefuse() {
      document
        .getElementById("tinetIframe")
        .contentWindow.CTILink.Session.refuse({}, function (result) {
          console.log(result, "拒接");
        });
    },
    // 挂断
    actionTinetDoUnLink({
      dispatch
    }) {
      dispatch("cancelCall", {});
    },
    // 保持
    actionTinetDoHold() {
      document
        .getElementById("tinetIframe")
        .contentWindow.CTILink.Session.hold({}, function (result) {
          console.log(result, "保持");
        });
    },
    // 保持接回
    actionTineDoUnhold() {
      document
        .getElementById("tinetIframe")
        .contentWindow.CTILink.Session.unhold({}, function (result) {
          console.log(result, "保持接回");
        });
    },
    // 空闲
    actionTinetDoUnpause() {
      document
        .getElementById("tinetIframe")
        .contentWindow.CTILink.Agent.unpause({}, function (result) {
          console.log(result, "空闲");
        });
    },
    // 置忙
    actionTinetDopause(data) {
      let params = {};
      params.pauseType = 1;
      params.pauseDescription = data;
      document
        .getElementById("tinetIframe")
        .contentWindow.CTILink.Agent.pause(params, function () {});
    },
    // 保存通话记录,延时执行
    saveCallRecord(uniqueId) {
      setTimeout(() => {
        reqCallRecordSave(uniqueId).then((data) => {
          let {
            code,
            msg
          } = data;
          if (code === 0) {
            console.log("通话记录保成功");
          } else {
            console.log(msg);
          }
        });
      }, 1000);
    },
    actionPhoneAttr({
      commit
    }, phone) {
      reqPhoneAttr(phone).then((data) => {
        let {
          code,
          result
        } = data;
        if (code === 0) {
          commit("setTinetAttribution", result);
        } else {
          commit("setTinetAttribution", "归属地查询失败");
        }
      });
    },
    // 根据当前用户坐席号查询人员映射
    // getFindUserExtendByCno({
    //   state,
    //   commit
    // }, params) {
      // StaffMap.findUserExtendByCno({explicitAreaCode: params}).then(res => {
      //   let { code, result } = res
      //   if (code === 0 && result.explicitAreaCode) {
      //     commit('setAreaCode', result.explicitAreaCode)
      //   }
      // })
    // },
  },
  // 相当于计算属性，可以自定义state所相关的属性，比如取反
  getters = {
    getCallTimes: state => {
      return state.CallTimes
  },
  getorangeCallErrorId: state => {
    return state.orangeCallErrorId
},
    getIfMultiTrack: (state) => state.ifMultiTrack,
    getPauseOrPlayStatus: (state) => {
      return state.pauseOrPlayStatus;
    },
    getTinetWsScript: (state) => {
      return state.tinetWsScript;
    },
    getTinetLogoutStatus: (state) => {
      return state.tinetLogoutStatus;
    },
    getTinetLoginStatus: (state) => {
      return state.tinetLoginStatus;
    },
    getTinetDeviceStatus: (state) => {
      return state.tinetDeviceStatus;
    },
    getTinetautoCallStatus: (state) => {
      return state.autoCall
    },
    getTinetCallTel: (state) => {
      return state.tinetCallTel;
    },
    getTinetCallAttribution: (state) => {
      return state.tinetCallAttribution;
    },
    getTinetCallInfo: (state) => {
      return state.tinetCallInfo;
    },
    getTinetCallStatus: (state) => {
      return state.tinetCallStatus;
    },
    getTinetCallStatuszthl: (state) => {
      console.log('statevuex', state);
      return state.tinetCallStatuszthl;
    },
    getTinetCallTimes: (state) => {
      return state.tinetCallTimes;
    },
    getTinetIntervalId: (state) => {
      return state.tinetIntervalId;
    },
    getTinetCallErrorId: (state) => {
      return state.tinetCallErrorId;
    },
    // getTinetTelValidate: state => {
    //   return state.tinetTelValidate
    // },
    getConLoading: (state) => {
      return state.conLoading;
    },
    getTrackTelNum: (state) => {
      return state.trackTelNum;
    },
    getBackCall: (state) => {
      return state.backCall;
    },
    getClearUp: (state) => {
      return state.clearUp;
    },
  };

export default {
  state,
  mutations,
  actions,
  getters
};