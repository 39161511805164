/*
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-08-18 10:05:48
 * @LastEditors: Seven
 * @LastEditTime: 2021-10-27 17:20:08
 */
import Layout from "@/layout";
import customer from "./customer";
import orderMange from "./order";
// import goods from "./goods";
import count from "./count";

const constantRoutes = [
  {
    path: "/",
    name: "wbHome",
    redirect: "/wbHome",
    meta: { requireAuth: false },
    component: Layout,
    children: [
      {
        path: "/wbHome",
        name: "wbHome",
        title: "首页",
        meta: { requireAuth: false },
        component: () => import("@/views/home"),
      },
      {
        path: "/wbHome/user",
        name: "user",
        title: "个人中心",
        meta: { requireAuth: false },
        component: () => import("@/views/home/user"),
      },
    ],
  },
  // {
  //   path: '/403',
  //   meta: { requireAuth: false, title: '403-权限不足' },
  //   name: 'error-403',
  //   component: resolve => {
  //     require(['@//views/error-page/403.vue'], resolve)
  //   }
  // },
  // {
  //   path: '/*',
  //   name: 'error-404',
  //   meta: { requireAuth: false, title: '404-页面不存在' },
  //   component: resolve => {
  //     require(['@/views/error-page/404.vue'], resolve)
  //   }
  // },
  // {
  //   path: '/500',
  //   meta: { requireAuth: false, title: '500-服务端错误' },
  //   name: 'error-500',
  //   component: resolve => {
  //     require(['@/views/error-page/500.vue'], resolve)
  //   }
  // }
];

export const allRoutes = [
  ...constantRoutes,
  ...customer,
  ...orderMange,
  // ...goods,
  ...count,
];