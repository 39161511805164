/*
 * @Description:
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-04-28 11:11:20
 * @LastEditors: Seven
 * @LastEditTime: 2022-03-24 16:56:54
 */
import Layout from "@/layout";
const customer = [
  {
    path: "/workbenchCustomer",
    name: "workbenchCustomer",
    title: "客户管理",
    meta: { requireAuth: true },
    // redirect: "/workbenchCustomer/wbCustomer",
    component: Layout,
    children: [
      {
        path: "wbCustomer",
        name: "wbCustomer",
        title: "我的客户",
        meta: { requireAuth: true },
        component: () => import("@/views/customer/customer"),
      },
      {
        path: "wbHigsea",
        name: "wbHigsea",
        title: "公海客户",
        meta: { requireAuth: true },
        component: () => import("@/views/customer/higsea"),
      },
      {
        path: "wbAppointment",
        name: "wbAppointment",
        title: "预约客户",
        meta: { requireAuth: true },
        component: () => import("@/views/customer/appointment"),
      },
      {
        path: "wbTransferNc",
        name: "wbTransferNc",
        title: "转NC申请单",
        meta: { requireAuth: true },
        component: () => import("@/views/customer/transferNc"),
      },
      {
        path: "wbTelephone",
        name: "wbTelephone",
        title: "外呼记录",
        meta: { requireAuth: true },
        component: () => import("@/views/customer/telephone"),
      },
      {
        path: "wbInspect",
        name: "wbInspect",
        title: "无效客户",
        meta: { requireAuth: true },
        component: () => import("@/views/customer/inspect"),
      },
      {
        path: "wbDynamic",
        name: "wbDynamic",
        title: "跟进记录",
        meta: { requireAuth: true },
        component: () => import("@/views/customer/dynamic"),
      },
      {
        path: "wbFrost",
        name: "wbFrost",
        title: "冻结客户",
        meta: { requireAuth: true },
        component: () => import("@/views/customer/frost"),
      },
      {
        path: "introduce",
        name: "introduce",
        title: "转介绍客户",
        meta: { requireAuth: true },
        component: () => import("@/views/customer/introduce"),
      },
    ],
  },
];

export default customer;
