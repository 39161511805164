<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-05-20 15:09:54
 * @LastEditors: Seven
 * @LastEditTime: 2022-03-03 11:00:53
-->
<template>
  <div v-drag :class="['orange-panel', isShow?'show positionAbsolute':'']" :style="{left: isShow?callWidth:'-320px'}">
    <!-- <Button @click="dianji">点击</Button> -->
    <i class="el-icon-phone-outline" style="line-height: 90px;margin-right: 20px;font-size: 30px;color: green" size="30" color="#19be6b"></i>
    <div style="margin-top: 14px;">
      <div style="font-weight: bolder; font-size: 16px;">
        <!-- <span style="color: green;">{{statusList[$store.state.orangeCall.orangeStatus]}}: </span> -->
        <span style="color: #2d79ff" v-if="getTinetCallStatus === 1"
          >呼叫坐席中：</span
        >
        <span style="color: green" v-if="getTinetCallStatus === 2"
          >振铃中：</span
        >
        <span style="color: #ff1f31" v-if="getTinetCallStatus === 3"
          >通话中：</span
        >
        <span style="color: #ff8905" v-if="getTinetCallStatus === 4"
          >结束通话：</span
        >
        <span style="color: #ff8905" v-if="getTinetCallStatus === 5"
          >来电号码：</span
        >
        <span v-if="getTinetCallTel">{{ $getTel(getTinetCallTel) }}</span>
      </div>
      <el-tag size="small" effect="dark" style="margin-right: 4px">{{ getTinetCallTimes }}</el-tag>
      <el-tag size="small" type="success" effect="dark">{{ getTinetCallAttribution }}</el-tag>
    </div>
    <i @click="actionTinetDoUnLink" class="stop-call el-icon-phone" size="30" color="#19be6b"></i>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      isShow: false,
      statusList: {
        0: '空闲', 1: '通话中', 4: '未注册', 8: '振铃', 11: '示忙中'
      }
    }
  },
  computed: {
    ...mapGetters(["getTinetCallStatus", "getTinetCallTel", "getTinetCallTimes", "getTinetCallAttribution"]),
    callWidth() {
      return document.body.clientWidth - 340 + 'px'
    }
    // isShow() {
    //   return this.$store.state.orangeCall.orangeStatus !== 0
    // }
  },
  methods: {
    ...mapActions(["actionTinetDoUnLink"]),
    dianji() {
      this.isShow = true
    }
  },
  directives: {
    // 可拖动指令
    drag(el) {
      el.onmousedown = function (e) {
        let disx = e.pageX - el.offsetLeft;
        let disy = e.pageY - el.offsetTop;
        document.onmousemove = function (e) {
          el.style.left = e.pageX - disx + "px";
          el.style.top = e.pageY - disy + "px";
        };
        document.onmouseup = function () {
          document.onmousemove = document.onmouseup = null;
        };
      };
    },
  },
  watch: {
    // val值为5的时候为回呼电话，这时候通话工具条不会自动隐藏，需要手动关闭才能隐藏
    // 这里添加了一个backCall属性，来判断回呼后通话工具栏是否关闭，没有关闭的话是不能进行外呼操作的，回呼的话这里是控制不了的
    getTinetCallStatus(val) {
      if (val === 5) {
        this.isShow = true;
      } else if (val === 0) {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
    },
  },
}
</script>
<style lang="scss" scoped>
  .orange-panel {
    width: 304px;
    height: 100px;
    box-shadow: 0 0 2px #999;
    position: fixed;
    padding: 0 10px;
    // top: 80px;
    z-index: 6000;
    background-color: #fff;
    // transition: all .3s;
    display: flex;
  }

  .show {
    right: 20px;
  }

  .hidden {
    right: -320px;
  }

  .stop-call {
    line-height: 90px;
    margin-left: 20px;
    font-size: 30px;
    color: red;
    cursor: pointer;
  }
  .positionAbsolute {
    position: absolute;
    right: 20px;
    bottom: 50px;
  }
</style>
