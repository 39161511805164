/*
 * @Author: 刘锦
 * @Date: 2020-10-29 20:00:47
 * @LastEditTime: 2021-08-04 15:22:58
 * @LastEditors: in hengqi by mengze
 * @Description: 
 * @FilePath: \workbench-front\src\store\modules\order.js
 */
export default {
  state: {
    // 1为单选 2为多选 适用于升班，转班，退费选择订单
    selectOrderMode: "1",
    dropOutIds: [],
    upgradeIds: [],
    transferIds: [],
    updateOrderList: false // 新建记录后刷新页面，获取记录


    
  },
  mutations: {
    setSelectOrderMode(state, value) {
      state.selectOrderMode = value
    },
    setDropOutIds(state, value) {
      state.dropOutIds = value
    },
    setUpgradeIds(state, value) {
      state.upgradeIds = value
    },
    setTransferIds(state, value) {
      state.transferIds = value
    },
    setUpdateOrderList(state, value) {
      state.updateOrderList = value
    }
  },
  getters: {
    updateOrderList: state => state.updateOrderList
  }
}