/*
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-07-15 20:07:53
 * @LastEditors: Seven
 * @LastEditTime: 2021-09-06 17:57:01
 */
import WbCusotmer from "./customer" // 客户管理
import WbOrder from "./order" // 售后管理
import WbHome from "./home"
let Perm = {
  WbCusotmer,
  WbOrder,
  WbHome
}
export default Perm
