<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-08-09 10:26:04
 * @LastEditors: Seven
 * @LastEditTime: 2021-10-28 11:33:31
-->
<template>
  <el-drawer :wrapperClosable="wrapperClosable" :visible.sync="drawer" :title="title" :size="size" :with-header="showHeader" :modal="modal">
    <div class="close-detail" @click="drawer = false" size="small">
      <i class="el-icon-arrow-right"></i>
    </div>
    <div class="drawer__body"><slot></slot></div>
    <div class="footer_button">
      <slot name="footer"></slot>
    </div>
  </el-drawer>
</template>
<script>
export default {
  name: "hqDrawer",
  props: {
    title: {
      type: String,
      default: "提示"
    },
    size: {
      type: String,
      default: "90%"
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    modal: {
      type: Boolean,
      default: true
    },
    wrapperClosable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      drawer: false
    }
  },
  methods: {
    init() {
      this.drawer = true
    },
    close() {
      this.drawer = false
    }
  }
}
</script>
<style lang="scss" scoped>
  .close-detail {
    width: 20px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    position: absolute;
    background: #F3F6FB;
    top: 50%;
    left: 0px;
    border: 1px solid #F3F6FB;
    border-left: none;
    cursor: pointer;

    &:hover {
      background: #e3edf8;
    }
  }
  .footer_button {
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    width: 100%;
    border-top: 1px solid #E1E4EB;
    padding: 15px;
    background: #fff;
    z-index: 999;
  }
  ::v-deep .el-drawer__body {
    pointer-events: auto;
  }
</style>
