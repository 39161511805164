<!--
 * @Description: 商品详情
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-25 14:18:56
 * @LastEditors: Seven
 * @LastEditTime: 2022-04-22 10:43:20
-->
<template>
  <HqDrawer
    title="商品详情"
    :visible.sync="drawer"
    :showHeader="false"
    size="86%"
    ref="drawer"
    :modal="!!!code"
    :wrapperClosable="!!!code"
    :style="{'pointer-events': code?'none':'auto'}"
  >
    <!-- <el-button class="close-detail" @click="drawer = false" size="small">关闭</el-button> -->
    <div class="detail">
      <div style="background: #fff">
        <div class="contact-box">
          <div class="contact">
            <span class="user">{{ customerDetail.name }}</span>
            <span class="own">归属人：{{ customerDetail.userName?customerDetail.userName:"无" }}</span>
            <Contact :method="getCustomerData" ref="contact" :perm="perm" />
          </div>
          <div style="text-align: right;flex: 1">
            <!-- 报名 -->
            <el-button v-if="cansinUp&&isPublic" type="primary" @click="goSignUpPage" icon="el-icon-s-custom" class="hq-button" style="margin-bottom: 6px">报名</el-button>
            <!-- <el-button type="primary" @click="goSignUpPage" icon="el-icon-s-custom" class="hq-button">报名</el-button> -->
            <!-- 无效：数据权限范围内，且有归属的商机，可显示无效功能 -->
            <Invalid v-if="perm && customerDetail.userName && permUtil.WbCusotmer.customerInvalid()" :currCol="customerDetail" :detailInit="init" :method="method" />
            <!-- 领取：无归属的商机，可显示领取功能（无论商机状态） -->
            <el-button @click="reveice" type="primary" v-if="!customerDetail.userName && permUtil.WbCusotmer.higseaReceive()" class="hq-button" style="margin-bottom: 6px" icon="el-icon-error"
              >领取</el-button
            >
            <!-- 放弃：数据权限范围内的商机，且有归属的商机，可显示放弃功能 -->
            <el-button v-if="perm && customerDetail.userName && permUtil.WbCusotmer.customerReturn()" @click="giveUp" class="hq-button" icon="el-icon-error"
              >放弃</el-button
            >
            <!-- 转移：数据权限范围内的商机，可显示转移功能 -->
            <Transfer v-if="perm && permUtil.WbCusotmer.customerTransfer()" ref="transfer" :method="method" :detailInit="init" />
            <!-- 移入留存、移出留存是一对互斥功能：数据权限范围内，且有归属的商机，如果商机已被移入留存，则显示移除留存功能，如果商机为非留存商机，则显示移入留存功能 -->
            <el-button @click="reserve(1)" type="primary" v-if="perm && customerDetail.isReserve === 0 && permUtil.WbCusotmer.customerReserve()&&isPublic" class="hq-button" icon="el-icon-error"
              >移入留存</el-button
            >
            <el-button @click="reserve(0)" type="primary" v-if="perm && customerDetail.isReserve === 1 && permUtil.WbCusotmer.customerReserve()&&isPublic" class="hq-button" icon="el-icon-error"
              >移出留存</el-button
            >
            <!-- 预约：数据权限范围内的商机，可显示预约功能 -->
            <el-button v-if="perm && permUtil.WbCusotmer.customerAppoint()&&isPublic" @click="appoint" type="primary" class="hq-button" icon="el-icon-error"
              >预约</el-button
            >
          </div>
        </div>
      </div>
      <el-row :gutter="12" style="width: 96%; margin: 0 auto">
        <!-- 左边 -->
        <el-col :span="14">
          <el-row class="customer-detail">
            <el-col class="detail-line"></el-col>
            <!--客户详情 -->
            <el-col :span="24">
              <el-card class="customer-label">
                <div slot="header" class="clearfix">
                  <span style="display: inline-block">客户详情</span>
                  <add-label ref="label" :perm="perm" :method="method"></add-label>
                </div>
                <el-row :gutter="8">
                  <!--第一行 -->
                  <el-col :span="5">赛道</el-col>
                  <el-col :span="4">归属部门</el-col>
                  <el-col :span="4">商机状态</el-col>
                  <el-col :span="4">创建人</el-col>
                  <el-col :span="6">创建时间</el-col>
                  
                  
                </el-row>
                <el-row :gutter="8" class="detail-box">
                  <!--第二行 -->
                  <el-col :span="5">{{ customerDetail.trackTypeStr }}</el-col>
                  <el-col :span="4">{{ customerDetail.deptName }}</el-col>
                  <el-col :span="4">{{ customerDetail.statusName }}</el-col>
                  <el-col :span="4">{{ customerDetail.createName }}</el-col>
                  <el-col :span="6">{{ customerDetail.createTime }}</el-col>
                  
                  
                </el-row>
                <el-row :gutter="8">
                  <!--第三行 -->
                  <el-col :span="5">上次联系</el-col>
                  <el-col :span="4">商机来源</el-col>
                  <el-col :span="4">跟进次数</el-col>
                  <el-col :span="4">渠道名称</el-col>
                  <el-col :span="6">渠道类别</el-col>
                  <!-- <el-col :span="5">订单总数</el-col>
                  <el-col :span="4">成交总额</el-col>
                  <el-col :span="4">回款总额</el-col> -->
                </el-row>
                  <!--第四行 -->
                <el-row :gutter="8" class="detail-box">
                  <el-col :span="5">{{ customerDetail.followTime }}</el-col>
                  <el-col :span="4">{{ customerDetail.sourceName }}</el-col>
                  <el-col :span="4">{{ customerDetail.followNumber }}</el-col>
                  <el-col :span="4">{{ customerDetail.recruitChannelsName ? customerDetail.recruitChannelsName : '渠道丢失'}}</el-col>
                  <el-col :span="6">{{ customerDetail.channelsTypeName }}</el-col>
                  <!-- <el-col :span="5">{{ customerDetail.dealNum }}</el-col>
                  <el-col :span="4">{{ customerDetail.dealAmount }}</el-col>
                  <el-col :span="4">{{ customerDetail.receiptAmount }}</el-col> -->
                </el-row>
              </el-card>
            </el-col>
            <el-col class="detail-line"></el-col>
            <!--基本信息 -->
            <el-col :span="24">
              <el-card>
                <div slot="header" class="clearfix">
                  <span>基本信息</span>
                  <el-button
                    v-if="permUtil.WbCusotmer.customerUpdate() && perm"
                    style="float: right;margin-top: 8px"
                    class="hq-button"
                    type="primary"
                    @click="updateClick"
                    :disabled="status === 4"
                    >更新</el-button
                  >
                </div>
                <el-row :gutter="12" class="base-detail">
                  <el-col :span="8">
                    <v-text-field
                      v-model="contactForm.name"
                      label="学员姓名"
                      hide-details="auto"
                      outlined
                      :dense="true"
                      :height="32"
                      :clearable="true"
                    ></v-text-field>
                  </el-col>
                  <el-col :span="8">
                    <dict-select code="SEX">
                      <v-select
                        v-model="contactForm.sex"
                        slot-scope="props"
                        :items="props.dictData"
                        :menu-props="{ bottom: true, offsetY: true }"
                        label="性别"
                        item-text="label"
                        item-value="value"
                        outlined
                        :dense="true"
                        :height="32"
                        :clearable="true"
                        clear-icon="$clear"
                        hide-details="auto"
                      ></v-select>
                    </dict-select>
                  </el-col>
                  <el-col :span="8">
                    <dict-select code="EDUCATION_TYPE">
                      <v-select
                        v-model="contactForm.education"
                        slot-scope="props"
                        :items="props.dictData"
                        :menu-props="{ bottom: true, offsetY: true }"
                        label="学历"
                        item-text="label"
                        item-value="value"
                        outlined
                        :dense="true"
                        :height="32"
                        :clearable="true"
                        clear-icon="$clear"
                        hide-details="auto"
                      ></v-select>
                    </dict-select>
                  </el-col>
                  <el-col :span="8">
                    <v-text-field
                      v-model="contactForm.customerId"
                      label="客户编号"
                      hide-details="auto"
                      outlined
                      :dense="true"
                      :height="32"
                      disabled
                      :clearable="true"
                    ></v-text-field>
                  </el-col>
                  <el-col :span="8">
                    <v-text-field
                      v-model="contactForm.ncId"
                      label="NCID"
                      hide-details="auto"
                      outlined
                      :dense="true"
                      :height="32"
                      disabled
                      :clearable="true"
                    ></v-text-field>
                  </el-col>
                  <el-col :span="8">
                    <v-text-field
                      v-model="contactForm.address"
                      label="地址"
                      hide-details="auto"
                      outlined
                      :dense="true"
                      :clearable="true"
                      :height="32"
                    ></v-text-field>
                  </el-col>
                  <el-col :span="8">
                    <v-text-field
                      v-model="contactForm.oldStudentPhone"
                      label="老学员号码"
                      hide-details="auto"
                      outlined
                      :dense="true"
                      :height="32"
                      :clearable="true"
                    ></v-text-field>
                  </el-col>
                  <el-col :span="8">
                    <v-text-field
                      v-model="contactForm.url"
                      label="URL"
                      hide-details="auto"
                      outlined
                      :dense="true"
                      :clearable="true"
                      :height="32"
                    ></v-text-field>
                  </el-col>
                  <el-col :span="24">
                    <CheckBox
                      ref="check"
                      label="QQ验证状态"
                      :list="checkList"
                      v-bind:select.sync="contactForm.verification"
                    />
                  </el-col>
                  <el-col :span="24">
                    <CheckBox
                      ref="checkWX"
                      label="微信验证状态"
                      :list="checkList"
                      v-bind:select.sync="contactForm.wxVerification"
                    />
                  </el-col>
                  <el-col :span="24">
                    <v-textarea
                      v-model="contactForm.memo"
                      outlined
                      :height="80"
                      name="input-7-4"
                      label="备注"
                      value=""
                    ></v-textarea>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
            <el-col class="detail-line"></el-col>
            <!--列表 -->
            <el-col :span="24">
              <DetailList ref="detailList" />
            </el-col>
          </el-row>
        </el-col>
        <!-- 右边 -->
        <el-col :span="10">
          <el-row>
            <el-col class="detail-line"></el-col>
            <!-- 跟进记录 -->
            <el-col :span="24">
              <el-card>
                <div slot="header" class="clearfix">
                  <span>跟进记录</span>
                  <el-button :disabled="status === 4" v-if="perm && permUtil.WbCusotmer.customerFollow()" @click="addFollow" style="float: right;margin-top: 8px" class="hq-button" type="primary"
                    >发表</el-button
                  >
                </div>
                <el-form ref="form" :model="form" :rules="formRules">
                  <el-row :gutter="12" style="margin-top: 10px">
                    <el-col :span="12">
                      <el-form-item prop="contactType">
                        <dict-select code="COMM_WAY">
                          <v-select
                            v-model="form.contactType"
                            slot-scope="props"
                            :items="props.dictData"
                            :menu-props="{ bottom: true, offsetY: true }"
                            label="沟通方式"
                            item-text="label"
                            item-value="value"
                            outlined
                            :dense="true"
                            :height="32"
                            :clearable="true"
                            clear-icon="$clear"
                            hide-details="auto"
                            :disabled="
                              !customInfo.userName &&
                              customInfo.customerId &&
                              customInfo.status !== ''
                            "
                          ></v-select>
                        </dict-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item prop="followStatus">
                        <dict-select :code="loginUser.trackType === 1 || this.loginUser.trackType === 3
                          ? 'FOLLOW_STATUS_ACCOUNT'
                          : 'FOLLOW_STATUS_SELF_EXAM'">
                          <v-select
                            v-model="form.followStatus"
                            slot-scope="props"
                            :items="props.dictData.filter(res => res.value !== 0)"
                            :menu-props="{ bottom: true, offsetY: true }"
                            label="意向状态"
                            item-text="label"
                            item-value="value"
                            outlined
                            :dense="true"
                            :height="32"
                            :clearable="true"
                            clear-icon="$clear"
                            hide-details="auto"
                            :disabled="
                              !customInfo.userName &&
                              customInfo.customerId &&
                              customInfo.status !== ''
                            "
                          ></v-select>
                        </dict-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item>
                        <TimeSelect ref="time" :disabled="!customInfo.userName &&
                                customInfo.customerId &&
                                customInfo.status !== ''" :select.sync="form.nextTime" />
                      </el-form-item>
                    </el-col>
                  <el-col v-if="
                    ((loginUser.trackType === 1 || loginUser.trackType === 3) &&
                      form.followStatus === 4) ||
                    (loginUser.trackType === 2 && form.followStatus === 6)
                  ">
                    <el-form-item 
                      prop="invalidType"
                      :rules="
                      ((loginUser.trackType === 1 || loginUser.trackType === 3) &&
                        form.followStatus === 4) ||
                      (loginUser.trackType === 2 && form.followStatus === 6)
                      ? { required: true, message: '请选择无效类型' }
                      : []
                    ">
                      <v-select
                        v-model="form.invalidType"
                        :items="invalidList"
                        :menu-props="{ bottom: true, offsetY: true }"
                        label="无效类型"
                        item-text="label"
                        item-value="value"
                        outlined
                        :disabled="
                        !customInfo.userName &&
                        customInfo.customerId &&
                        customInfo.status !== ''"
                        :dense="true"
                        :height="32"
                        :clearable="true"
                        clear-icon="$clear"
                        hide-details="auto"
                      ></v-select>
                    </el-form-item>
                  </el-col>
                    <el-col :span="24">
                      <el-form-item prop="memo">
                        <v-textarea
                          v-model="form.memo"
                          outlined
                          :height="80"
                          name="input-7-4"
                          label="备注"
                          value=""
                          :disabled="
                            !customInfo.userName &&
                            customInfo.customerId &&
                            customInfo.status !== ''
                          "
                        ></v-textarea>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </el-card>
            </el-col>
            <el-col class="detail-line"></el-col>
            <!-- 所有动态 -->
            <el-col :span="24">
              <el-card>
                <div slot="header">
                  <span>所有动态</span>
                </div>
                <div class="time-line" v-if="operationList.length > 0">
                  <el-timeline :reverse="reverse">
                    <el-timeline-item color="#337AFF" v-for="item in operationList" :key="item.id">
                      <div class="follow-edit">
                        <h4>{{ item.operationTypeStr }}</h4>
                        <i @click="openEdit(item)" v-if="item.operationType === 4 && item.createId == loginUser.userId && item.edit" class="el-icon-edit-outline"></i>
                      </div>
                      <p style="color: #999">操作人：{{ item.createName }}&nbsp;&nbsp;{{ item.createTime }}</p>
                      <p v-if="item.operationType !== 2" v-html="item.memo"></p>
                      <p v-if="item.operationType === 2 && !!item.memo">{{ item.memo.split('<')[0] }}</p>
                      <audio v-if="item.operationType === 2 && item.url" :src="item.url"  controls="controls"></audio>
                    </el-timeline-item>
                  </el-timeline>
                </div>
                <div v-else style="line-height:120px;text-align: center">暂无动态</div>
              </el-card>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <!-- 预约 -->
    <Appoint ref="appoint" :method="method" :detailInit="init" />
    <!-- <div class="demo-drawer__footer">
      <el-button type="primary" @click="drawer = false">返回</el-button>
    </div> -->
    <!-- 修改跟进记录 -->
    <HqModal
      :visible="editModel"
      title="修改跟进记录"
      @on-cancel="cancel">
      <el-form ref="editCustomer" :model="editCustomer" :rules="editruleValidate">
        <el-row>
          <el-col :span="24">
            <el-form-item prop="contactType">
              <dict-select code="COMM_WAY">
                  <v-select
                    v-model="editCustomer.contactType"
                    slot-scope="props"
                    :items="props.dictData"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="沟通方式"
                    item-text="label"
                    item-value="value"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                  ></v-select>
              </dict-select>
            </el-form-item>
            <el-form-item prop="followStatus">
              <dict-select :code="loginUser.trackType === 1 || this.loginUser.trackType === 3
                ? 'FOLLOW_STATUS_ACCOUNT'
                : 'FOLLOW_STATUS_SELF_EXAM'">
                <v-select
                  v-model="editCustomer.followStatus"
                  slot-scope="props"
                  :items="props.dictData"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="意向状态"
                  item-text="label"
                  item-value="value"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                ></v-select>
              </dict-select>
            </el-form-item>
              <el-form-item prop="memo">
                <v-textarea
                v-model="editCustomer.memo"
                outlined
                :height="80"
                name="input-7-4"
                label="跟进详情"
                value=""
              ></v-textarea>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
        <div slot="footer">
          <el-button type="ghost" @click="cancel('editCustomer')" style="margin-left: 8px">取消</el-button>
          <el-button type="primary" @click="editSave('editCustomer')">保存</el-button>
      </div>
    </HqModal>
    <!-- 选择成交号码 -->
    <HqModal
      :visible="phoneShow"
      title="提示"
      width="450"
      @on-cancel="cancel"
    >
      <el-row>
        <el-col :span="24" style="margin-top: 10px">
           <v-autocomplete
             v-model="deptId"
             :items="deptList"
             :menu-props="{ bottom: true, offsetY: true }"
             label="成交校区"
             outlined
             item-text="deptName"
             item-value="deptId"
             :dense="true"
             :height="32"
             :clearable="true"
             clear-icon="$clear"
             hide-details="auto"
           ></v-autocomplete>
         </el-col>
        <el-col v-if="false" style="margin-top: 10px" :span="24"><i class="el-icon-warning" style="color: red"></i>该商机存在多个号码，请选择成交录单号码</el-col>
        <el-col :span="24" style="margin-top: 20px">
          <v-select
            v-model="phone"
            :items="dealPhoneList"
            :menu-props="{ bottom: true, offsetY: true }"
            label="成交号码"
            outlined
            :dense="true"
            :height="32"
            :clearable="true"
            clear-icon="$clear"
            hide-details="auto"
          ></v-select>
        </el-col>
      </el-row>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="phoneSave" type="primary">确认</el-button>
      </div>
    </HqModal>
    <div slot="footer">
      <el-button size="small" @click="$refs.drawer.close()">返回</el-button>
    </div>
  </HqDrawer>
</template>
<script>
import dictSelect from "components/dictSelect";
import CheckBox from "components/check-box";
import addLabel from "./add-label.vue";
import Invalid from "./invalid";
import Transfer from "./transfer";
import Contact from "./contact";
import Appoint from "./appoint";
import DetailList from "./detail-list";
import TimeSelect from "components/time-select";
import { 
  getCustomerDetail, 
  getCustomerData,
  getDealSummary,
  doMyCustomerUpdate,
  doAddFollow,
  doDetailReceive,
  doReturnToCommon,
  getReserveJudge,
  doAddReserve,
  getOperationList,
  doFollowUpdate,
  getNewestDetail,
  getCallRecordFile,
  getTransactionInfo,
  getBizInCurrentUserPermissions,
  doChoiceDealSchool,
  getJiamiCustomerData
} from "api/customer";
import { getQuerySchoolList, getQuerySchoolListdel } from "api/count";
import { doRegistauthority, getCustomerPhone } from "api/public";
import { reqDictByCode } from "api/dict";
import { mapGetters } from "vuex";
import { autoFixValue } from '@/utils';
export default {
  components: {
    CheckBox,
    dictSelect,
    addLabel,
    Invalid,
    Transfer,
    Contact,
    Appoint,
    DetailList,
    TimeSelect,
  },
  props: {
    method: {
      type: Function
    },
    code: {
      type: String
    }
  },
  data() {
    return {
      drawer: false,
      form: {
        contactType: "",
        followStatus: "",
        nextTime: "",
        memo: "",
        invalidType: "",
      },
      contactForm: {
        name: "",
        sex: "",
        education: "",
        // careerType: "",
        // calloutType: "",
        // areaType: "",
        // demandType: "",
        oldStudentPhone: "",
        address: "",
        verification: "",
        wxVerification:"",
        url: "",
        memo: "",
        customerId: "",
        ncId: ""
      },
      checkList: [
        { label: "已发送", value: 0 },
        { label: "已添加", value: 1 },
        { label: "未发送", value: 2 },
      ],
      tableData: [],
      currCol: {},
      formRules: {
        followStatus: [{ required: true, message: "请填写意向状态" }],
        contactType: [{ required: true, message: "请填写沟通方式" }],
        memo: [{required: true, message: '请填写跟进详情'}],
      },
      // 动态
      operationList: [],
      menu: false,
      detail: {
        verification: null
      },
      customerDetail: {},
      time: "00:00:00",
      reverse: true,
      activities: [{
        content: '年后再打算过来学习实操课程，今年有去参加成人高考，觉得考个初级没有用。明年在说吧。要等小孩去寄宿了才有时间学。',
        timestamp: '2018-04-15'
      }, {
        content: '通过审核',
        timestamp: '2020-05-16  16:45:59'
      }, {
        content: '创建成功',
        timestamp: '2018-04-11'
      }],
      editCustomer: {
        followStatus: '',
        contactType: '',
        memo: '',
        customerId: '',
        followId: ''
      },
      editruleValidate: {
        followStatus: [
          { message: '请选择沟通方式', required: true }
        ],
        contactType: [
          { message: '请选择意向状态', required: true }
        ],
        memo: [
          { message: '请输入跟进详情', required: true }
        ]
      },
      editModel: false,
      status: 0,
      invalidList: [],
      customInfo: {},
      // 是否可以报名
      cansinUp: false,
      phoneShow: false,
      phone: "",
      dealPhoneList: [],
      // 数据权限
      perm: true,
      customerId: "",
      row: {},
      deptList: [],
      deptId: ''
    };
  },
  computed: {
    ...mapGetters(["loginUser"]),
    /**是否公海客户 */
    isPublic(){
      return !!this.customerDetail.userName
    }
  },
  mounted() {
    reqDictByCode("INVALID_TYPE").then(data => {
      if(this.loginUser.roleList[0].roleCode === "ADMIN" || this.loginUser.roleList[0].roleCode === "SUPER_ADMIN") {
        this.invalidList = data.result
      } else if(this.loginUser.trackType === 1 || this.loginUser.trackType === 3) {
        this.invalidList = []
        data.result.map(item => {
          if(item.label == '空号、停机、号码不存在' ||
            item.label == '咨询非恒企赛道课程' ||
            item.label == '连续3次否认咨询' ||
            item.label == '连续5次无法联系' ||
            item.label == '年龄不符' ||
            item.label == '其他') {
              this.invalidList.push(item)
            }
        })
      } else {
        this.invalidList = []
        data.result.map(item => {
          if(item.label == '空号、停机、号码不存在' ||
            item.label == '咨询非恒企赛道课程' ||
            item.label == '连续3次否认咨询' ||
            item.label == '连续5次无法联系' ||
            item.label == '年龄不符' ||
            item.label == '其他') {
              this.invalidList.push(item)
            }
        })
      }
    })
    getQuerySchoolListdel().then((data) => this.deptList = data.result)
  },
  methods: {
    init(row) {
      this.$refs.drawer.init()
      this.row = row
      // this.customInfo = row
      this.status = row.status
      this.customerDetail = {}
      this.drawer = true
      row.customerId = autoFixValue(row.customerId)
      this.customerId = row.customerId

      // this.sourceId   = row.id;
      // debugger
      this.getCustomerDetail(row)
      this.getCustomerData()
      this.getDealSummary()
      this.getOperationList()
      this.$store.commit("setdetailcustomerId", row.customerId);
      localStorage.setItem("setdetailcustomerId", row.customerId)

      this.$nextTick(() => {
        this.$refs.label.init(this.customerId, 1)
        this.$refs.detailList.init(this.customerId?this.customerId:row.customerId, {trackType: row.trackType, url: row.url})
        
      })
      this.getBizInCurrentUserPermissions(this.customerId)
      // 客户详情报名单按钮权限
      let params = {
        customerId: this.customerId,
        deptId: this.loginUser.deptId,
        deptName: this.loginUser.deptName,
        userId: this.loginUser.userId,
      }
      doRegistauthority(params).then(data => {
        this.cansinUp = data.result
      })
    },
    // 获取数据权限
    async getBizInCurrentUserPermissions(id) {
      const { code, result } = await getBizInCurrentUserPermissions(id)
      if(code === 0) {
        this.perm = result
      }
    },
    // 报名
    async goSignUpPage(){
      // if(!this.deptList.length) return this.$message.error('您无权限做单，请走预约单给校区进行做单！')
      // 1、判断该商机是否有有效期内的转介绍单，如果没有执行2
      // 2、判断该商机是否有有效期内的小型公开课备案单，如果没有执行3
      // 3、判断该商机是否有有效期内的预约单，如果没有自动生成一条当天的预约已到访的预约单
      const { result } = await getCustomerPhone(this.customerId)
      if(!result.length) return this.$message.error("该商机无手机联系方式，请添加后再操作！")
      if(result.length == 1) {
        this.phone = result[0]
      }
      this.dealPhoneList = result;

      const res = await doChoiceDealSchool({customerId: this.customerId, phone: this.customerDetail.phone})
      if(res.result) {
        if(!this.deptList.length) return this.$message.error('您无权限做单，请走预约单给校区进行做单！')
      }
      if(result.length > 1 || res.result) {
        if(this.loginUser.type == 1) {
          this.deptId = this.loginUser.deptId
        }
        this.phoneShow = true
      } else {
        let sourceId = this.sourceId;
        if( !sourceId ){
          sourceId = ""
        }
        let params = {
          customerId: this.customerId,
          sourceId: sourceId,
          registSource: 2,
        }
        getTransactionInfo(params).then(data => {
          if(data.code === 0) {
            this.$refs.drawer.close()
            this.$router.push(`/afterSale/signup?customerId=${this.customerId}&sourceId=${sourceId}&registSource=2&phone=${this.phone}`);  
                
          } else {
            this.$message.error(data.msg)
          }
          this.phoneShow = false
        })
      }
    },
    async phoneSave() {
      if(!this.deptId) {
        this.$message.error("请选择成交校区！")
        return false
      }
      if(!this.phone) {
        this.$message.error("请选择成交号码！")
        return false
      }
      let sourceId = this.sourceId;
      if( !sourceId ){
        sourceId = ""
      }
      let params = {
        customerId: this.customerId,
        sourceId: sourceId,
        registSource: 2,
        dealDeptId: this.deptId,
        phone: this.phone,
      }
      getTransactionInfo(params).then(data => {
        if(data.code === 0) {
          this.$refs.drawer.close()
          this.$router.push(`/afterSale/signup?customerId=${this.customerId}&sourceId=${sourceId}&registSource=2&phone=${this.phone}&id=${this.deptId}`);  
              
        } else {
          this.$message.error(data.msg)
        }
        this.phoneShow = false
      })
    },
    // 领取
    reveice() {
      let params = [];
      params.push({
          customerId: this.customerDetail.customerId,
          phone: this.customerDetail.phone,
          deptId: this.customerDetail.deptId,
        });
      
      this.$hqMessageBox({
        message: `确定是否领取商机（${this.customerDetail.phone}）？`,
      })
        .then(async () => {
          const { code } = await doDetailReceive(params);
          if (code === 0) {
            this.$message.success("领取成功");
            this.method && this.method()
            // this.init(this.customerDetail)
            this.init(this.row)
          }
        })
        .catch(() => {
          console.log("取消");
        });
    },
    // 回退公海
    giveUp() {
      console.log('this.customerDetail.status', this.customerDetail.status);
      if (this.customerDetail.status == 0 || this.customerDetail.status == 2) {
        this.$hqMessageBox({
          message:
            "确定是否将商机（" + this.customerDetail.customerId + "）退回到公海？请注意退回后将无法再进行跟进",
          })
        .then(() => {
          doReturnToCommon([this.customerDetail.customerId]).then((data) => {
            const { code, msg } = data
            if (code === 0) {
              this.$message.success('商机成功退回公海')
              // this.init(this.customerDetail)
              this.init(this.row)
              this.method && this.method()
            } else {
              this.$message.error(msg)
            }
          })
        })
        .catch(() => {
          console.log("取消");
        })
      } else {
        this.$message.error('所选商机为非潜在和预约状态商机，无法退回公海。');
        return false
      }
      
    },
    // type 0:解锁，1锁定，移入留存
    reserve(type) {
      // 需要先判断是否达到留存上限
      getReserveJudge(this.customerId, type).then(res => {
        if(res.code === 0) {
          this.$hqMessageBox({
            title: "留存",
            message: res.result,
          })
            .then(async () => {
              const { code, msg } = await doAddReserve(this.customerId, type)
              if(code === 0) {
                this.$message.success(msg)
                // this.init(this.customerDetail)
                this.init(this.row)
                this.method && this.method()
              } 
            })
            .catch(() => {
              console.log("取消");
          });
        } else if(res.code === -1) {
          this.$message.info(res.msg)
        }
      })
    },
    // 获取基本资料
    async getCustomerData(val) {
        const { code, result } = await getJiamiCustomerData(this.customerId)
        // const { code, result } = await getCustomerData(this.customerId)
        if(code === 0) {
          this.contactForm.address = result.address
          this.contactForm.education = result.education
          this.contactForm.memo = result.memo
          this.contactForm.name = result.name
          this.contactForm.oldStudentPhone = result.oldStudentPhone
          this.contactForm.sex = result.sex
          this.contactForm.url = result.url
          this.contactForm.verification = result.verification
          this.contactForm.wxVerification = result.wxVerification
          this.contactForm.customerId = result.customerId
          this.contactForm.ncId = result.ncId
          // this.contactForm.calloutType = result.calloutType
          // this.contactForm.careerType = result.careerType
          // this.contactForm.demandType = result.demandType
          // this.contactForm.areaType = result.areaType
          this.detail = result
          this.$nextTick(() => {
            this.$refs.contact.init(this.detail)
            this.$refs.check.set(this.detail.verification)
            this.$refs.checkWX.set(this.detail.wxVerification)
          })
        }
      // }
    },
    // 客户成交汇总
    async getDealSummary() {
      const { code, result } = await getDealSummary(this.customerId)
      if(code === 0) {
        this.customerDetail = { ...this.customerDetail, ...result }
      }
    },
    // 获取详情
    async getCustomerDetail(/**val*/row) {
      // if (val) {
      //   let result = val
      //   this.contactForm.careerType = result.careerType
      //   this.customerDetail = { ...this.customerDetail, ...result }
      // }
      const { code, result = {} } = await getCustomerDetail(this.customerId)
      if(code === 0) {
        this.contactForm.careerType = result.careerType
        this.customerDetail = { ...this.customerDetail, ...result }
        console.log('this.customerDetail',this.customerDetail);
        this.customInfo = this.customerDetail
        this.$nextTick(()=>{
          this.$refs.transfer.init([row])
        })
      
      }
    },
    // 获取动态列表
    async getOperationList() {
      const { code, result } = await getOperationList(this.customerId)
      if(code === 0) {
        this.operationList = result
        if(result.length > 0) {
          let i = null;
          this.operationList.map(async (item, index) => {
            if(item.operationType === 4) {
              i = index
            }

            if(item.operationType === 2 && item.memo) {
              const data = await getCallRecordFile(item.memo.split("<br/>")[1], 0)
              if(data.code === 0 && data.result) {
                // item.url = data.result
                this.$set(item, 'url', data.result)
              }
            }
          })
          if(i !== null) {
            this.operationList[i].edit = true
          }
        }
      }
    },
    // 修改基本资料
    async updateClick() {
      const { code, msg } = await doMyCustomerUpdate(this.contactForm)
      if(code === 0) {
        // this.init(this.customerDetail)
        this.init(this.row)
        this.$message.success(msg)
        this.method && this.method()
      } else {
        this.$message.error(msg)
      }
    },
    // 新增跟进记录
    async addFollow() {
      this.$refs["form"].validate(async valid => {
        if(valid) {
          this.form.customerId = this.customerId
          this.form.processId = this.customerDetail.processId
          if(!this.customerId) {
            this.$message.error(`获取不到客户id${this.customerId}，请刷新页面再试试`)
            return false
          }
          const { code, msg } = await doAddFollow(this.form)
          if(code === 0) {
            this.$message.success(msg)
            // this.init(this.customerDetail)
            this.init(this.row)
            this.$refs.time.clear()
            this.form = {
              contactType: "",
              followStatus: "",
              nextTime: "",
              memo: ""
            }
            this.method && this.method()
          } else if(code === 4010) {
            this.$message.error("请先领取商机再跟进")
          } else {
            this.$message.error(msg)
          }
        }
      })
    },
    // 预约
    appoint() {
      this.$refs.appoint.init(this.customerDetail)
    },
    async openEdit(item) {
      const { code, result } = await getNewestDetail(item.customerId)
      if(code === 0) {
        this.editCustomer = result
        this.editCustomer.operationId = item.id
      }
      this.editModel = true
    },
    // 修改跟进记录
    editSave() {
      let _this = this
      this.$refs['editCustomer'].validate((valid) => {
        if (valid) {
          doFollowUpdate(this.editCustomer).then(res => {
            if (res.code === 0) {
              _this.$message.success(res.msg)
              this.cancel()
              this.method && this.method()
              // this.init(this.customerDetail)
              this.init(this.row)
            } else {
              _this.$message.error(res.msg)
            }
          })
        }
      })
    },
    cancel() {
      this.editModel = false
      this.phoneShow = false
      this.editCustomer = {
        followStatus: '',
        contactType: '',
        memo: '',
        customerId: '',
        followId: '',
      }
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .el-drawer__container {
  .el-drawer {
    overflow: visible!important;
  }
}
.close-detail {
  padding: 10px;
  position: absolute;
  top: 16px;
  left: -46px;
}
::v-deep.customer-label {
  .clearfix {
    display: flex;
    align-items: center;
    min-height: 40px;
  }
  .el-card__header {
    line-height: 0!important;
    height: auto!important;
    position: relative;
    padding: 4px 18px!important;
  }
}
.detail {
  width: 100%;
  // height: 100%;
  padding-bottom: 70px;
  background: #f5f5fa;

  ::v-deep.el-card__header {
    height: 42px;
    line-height: 42px;
    background-color: #f6faff;
    padding: 0 18px;

    span {
      font-weight: bold;
      color: #181818;
    }

    .hq-button {
      >span {
        color: #fff;
      }
    }
  }
  .detail-line {
    height: 12px;
    width: 100%;
    background-color: #f5f5fa;
  }

  .contact-box {
    width: 95%;
    margin: 0 auto;
    padding: 10px 0;
    display: flex;
  }

  .contact {
    font-size: 13px;
    flex: 2;

    .user {
      font-size: 18px;
      font-weight: bold;
      color: #181818;
    }

    .own {
      margin: 0 6px;
    }

    .phone {
      color: #181818;
      font-weight: 400;
      margin: 0 6px;
    }
  }

  // 客户详情
  .customer-detail {
    .el-row {
      font-size: 13px;
      line-height: 26px;
      color: #181818;
      font-weight: 400;

      &:nth-child(2n-1) {
        color: #999;
      }
    }
  }
  .detail-box {
    div {
      min-height: 26px;
    }
  }
  .base-detail {
    .el-col {
      margin-top: 12px;
    }

    .url {
      line-height: 42px;
      font-size: 16px;
      >span {
        display: inline-block;
      }
    }
  }
  
  .time-line {
    padding-top: 26px;
    max-height: 500px;
    overflow-y: auto;

    h4 {
      color: #333;
      font-size: 15px;
    }
    p {
      font-size: 14px;
      color: #333;
    }

    .follow-edit {
      display: flex;
      justify-content: space-between;

      >i {
        cursor: pointer;
        margin-right: 30px;
      }
    }
  }
}
.demo-drawer__footer {
  position: fixed;
  width: 90%;
  right: 0;
  bottom: 0;
  text-align: center;
  line-height: 120px;
  background: #fff;
}
</style>