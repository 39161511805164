/*
 * @Description:
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2020-03-17 10:18:04
 * @LastEditors: Seven
 * @LastEditTime: 2021-07-26 17:54:33
 */
import axios from "axios";

const baseUrl = "/qw_api";

// 请求登录
export const requestLogin = (params) => {
  return axios.post(`${baseUrl}/login`, params).then((res) => res.data);
};

// 用户登录信息
export const requestUserInfo = () => {
  return axios.get(`${baseUrl}/userInfo`).then((res) => res.data);
};

// 用户菜单信息
export const requestUserMenu = () => {
  return axios.get(`${baseUrl}/userMenu`).then((res) => res.data);
};

// 用户菜单权限信息
export const requestUserMenuName = () => {
  return axios.get(`${baseUrl}/userMenuName`).then((res) => res.data);
};

// 用户权限信息
export const requestUserPerm = () => {
  return axios.get(`${baseUrl}/userPerm`).then((res) => res.data);
};

// 退出登录
export const requestLogOut = () => {
  return axios.get(`${baseUrl}/logout`).then((res) => res.data);
};
// 钉钉扫码登录获取参数
export const getConfigByCode = (params) => {
  return axios
    .get(`${baseUrl}/sys/config/getConfigByCode?code=${params}`)
    .then((res) => res.data);
};
// 获取登录验证码
export const getPhoneCode = (phone) => {
  return axios
    .get(`${baseUrl}/api/open/v1/send/${phone}`)
    .then((res) => res.data);
};
// 验证码登录
export const doMsmLogin = (params) => {
  return axios.post(`${baseUrl}/msmLogin`, params).then((res) => res.data);
};

// =============================
// 部门
export const reqsysDeptTree = () => {
  return axios
    .get(`${baseUrl}/sys/dept/deptTree?isRoot=false`)
    .then((res) => res.data);
}
