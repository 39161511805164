/*
 * @Descripttion:
 * @version:
 * @Author: 刘锦
 * @Date: 2020-09-01 19:00:19
 * @LastEditors: Seven
 * @LastEditTime: 2020-12-09 11:57:33
 */
const prefix = `/base-api`
export default {
  // 获取客户轨迹用户信息
  getTrackUserInfo: {
    url: `${prefix}/marketingBiz/customer/getCutomerByContact`,
    method: "post"
  },
  // 根据客户编码获取客户轨迹用户信息
  getTrackUserInfoByCId: {
    url: `${prefix}/marketingBiz/customer/getCutomerByQuery`,
    method: "post"
  },
  // 获取流程记录表格数据
  getTrackFlowPathList: {
    url: `${prefix}/marketingBiz/process/listByPageByCustomerId`,
    method: "post",
  },
  // 获取客户跟进表格数据
  getFollowUpList: {
    url: `${prefix}/marketingBiz/follow/listByPageByCustomerId`,
    method: "post"
  },
  // 获取预约客户表格数据
  getAppointmentCustomerList: {
    url: `${prefix}/marketingBiz/customerAppointment/listByPageByCustomerId`,
    method: "post"
  },
  // 获取留存记录表格数据
  getPreserveList: {
    url: `${prefix}/marketingBiz/customerReserve/listByPageByCustomerId`,
    method: "post"
  },
  // 获取呼叫记录分页数据

  getCallRecordList: {
    url: `${prefix}/marketingThirdPart/callRecord/findPage`,
    method: "post"
  },
  // 获取呼叫记录 录音文件地址
  getRecordFileByIdAndType: {
    url: `${prefix}/marketingThirdPart/callRecord/record/recordFile`,
    method: "get",
    isUrlParams: true
  },
  // 检测学员归属是否是当前用户
  doCheckHandle: {
    url: `${prefix}/marketingBiz/customer/checkHandle`,
    method: 'post'
  }
}
