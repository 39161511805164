/*
 * @Author: your name
 * @Date: 2020-09-07 15:30:50
 * @LastEditTime: 2021-12-30 12:05:15
 * @LastEditors: Seven
 * @Description: In User Settings Edit
 * @FilePath: \workbench-front\src\utils\index.js
 */
import md5 from 'md5'
import Checker from './Checker'
export function download(url, file) {
  const xhr = new XMLHttpRequest()
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.onload = () => {
    const blob = new Blob([xhr.response])
    const a = document.createElement('a')
    const url = URL.createObjectURL(blob)
    a.href = url
    a.download = file || '音频文件.mp3'
    a.click()
    a.remove()
    URL.revokeObjectURL(url)
  }
  xhr.send(null)
}
export function downloadExcel(url, filename) {
  const a = document.createElement('a')
  a.download = filename
  a.href = url
  a.click()
  a.remove()
}
export function formatSecond(second) {
  let h = Math.floor(second / 3600)
  let m = Math.floor(second % 3600 / 60)
  let s = Math.floor(second % 60)
  h = h > 9 ? h : '0' + h
  m = m > 9 ? m : '0' + m
  s = s > 9 ? s : '0' + s
  return `${h}:${m}:${s}`
}
export function setTitle(title) {
  window.document.title = title
}


export function resolveUrlParams(params) {
  if (!(paramsArr instanceof Object)) throw new TypeError('必须是对象')
  return Object.keys(paramsArr).reduce((accu, cur) => accu + `${cur}=${params[cur]}&`, '').slice(0, -1)
}

export function md5Encode(value) {
  return md5(value)
}

export function exportData(type, domain, fileName, tmplateStr, list) {
  let url
  if (type == 2) {
    url = "/export/pdf"
    fileName += ".pdf"
  }
  else if (type == 3) {
    url = domain + "/export/xls"
    fileName += ".xls"
  } else {
    url = domain + "/export/xlsx"
    fileName += ".xlsx"
  }
  let dataArr = {
    fileName: fileName,
    reportPath: tmplateStr,
    data: JSON.stringify({ data: list })
  }
  //原生ajax
  let xhr = new XMLHttpRequest()
  //post方式请求后台的路径
  xhr.open("post", url)
  //导出的Excel是二进制数据类型，所以设置为blob
  xhr.responseType = "blob"
  //请求头（key,value），请求头可以设置多个key-value对
  xhr.setRequestHeader("Content-Type", "application/json;charset=utf-8")
  //返回成功，导出的Excel文件

  xhr.onload = function () {
    if (this.status == 200) {
      let blob = this.response
      let a = document.createElement("a")
      let url = window.URL.createObjectURL(blob)
      a.href = url
      //设置文件名称
      a.download = fileName
      a.click()
      a.remove()
      window.URL.revokeObjectURL(url)
    }
  }
  //请求的参数，json格式，后台要用json格式接收
  xhr.send(JSON.stringify(dataArr))
}


// 用于订单售后跳转 根据aftersaletype不同，返回不同的售后单明细页
// 0 ,退费 1，升班 2，转班
export function getAfterSaleUrl(type) {
  const map = {
    0: "/aftersale/list/drop-out",
    1: "/aftersale/list/upgrade",
    2: '/aftersale/list/upgrade'
  }
  return map[type]
}
/**
 * 返回云合同地址
 * @returns {string} 云合同页面地址
 */
export const getContactUrl = () =>{
  return process.env.VUE_APP_CONTRACT_URL
}
/**
 * 新标签打开
 * 支持相/绝对路径
 * @param {String} path 
 */
export const openNewWindow = path =>{
  const isHashMode = window.vue.$router.mode === 'hash'
  window.open(/^\//i.test(path) ? `${isHashMode ? '#' : ''}${path}` : path)
}
/**
 * 环境鉴别
 */
export const envUtil = {
  isBeta(){
    return process.env.VUE_APP_TITLE === 'beta'
  },
  isDev(){
    return process.env.VUE_APP_TITLE === 'development'
  },
  isProd(){
    return process.env.VUE_APP_TITLE === 'production'
  },
  isTesting(){
    return process.env.VUE_APP_TITLE === 'testing'
  }
}
/**
 * 判断源数据originValue是[undefined|null|'']为其中一项则返回outputValue；否则返回originValue
 * @param {any} originValue 
 * @param {any} outputValue
 */
export const autoFixValue = (originValue, outputValue = 0) =>{
  if(Checker.isNull(originValue) || Checker.isUndefined(originValue) || originValue === 'undefined' || originValue === '' ) {
    console.warn('[autoFixValue], originValue:', originValue, ' outputValue:', outputValue);
    return outputValue
  }
  return originValue
}