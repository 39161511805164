<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-05-11 17:46:53
 * @LastEditors: Seven
 * @LastEditTime: 2021-11-11 10:27:00
-->
<template>
  <span>
    <tntalk></tntalk>
    <!-- {{getTinetLoginStatus.status}}
    {{tinetLoginFrom}} -->
    <div class="lock-screen-btn-con" style="width: 70px">
       <el-button
            class="call-btn"
            :type="getTinetLoginStatus.type"
            size="mini"
            @click="showClick"
            >{{ getTinetLoginStatus.show }}</el-button
          >
      <!-- <el-popover
        v-model="statusVib"
        @show="getTinetUserInfo"
        placement="bottom"
        :width="244"
      >
        <template #reference>
          <el-button
            class="call-btn"
            :type="getTinetLoginStatus.type"
            size="mini"
            @click="showClick"
            >{{ getTinetLoginStatus.show }}</el-button
          >
        </template> -->
        <!-- <el-card
          v-if="tinetLoginFrom == false"
          :bordered="false"
          :padding="4"
          :dis-hover="true"
          style="width: 220px"
        >
          <template #header>
            <div>
              <i class="iconfont icon-telephone"></i>
              CallCenter login
            </div>
          </template>
          <div @keydown.enter="doLoginCheck">
            <el-select
              size="small"
              v-model="tinetFrom.enterpriseId"
              @change="priseChange"
              placeholder="请输入呼叫中心编号"
              :transfer="true"
              style="margin: 10px 0"
            >
              <el-option
                v-for="item in enterpriseList"
                :value="item.enterpriseId"
                :label="item.name"
                :key="item.enterpriseId"
              ></el-option>
            </el-select>
            <el-input
              size="small"
              v-model="tinetFrom.cno"
              placeholder="请输入座席工号"
            >
            </el-input>
            
            <el-button
              size="small"
              type="primary"
              long
              @click="doLoginCheck"
              :loading="checkLoading"
              style="width: 100%; margin-top: 10px"
              >登录</el-button
            >
          </div>
        </el-card> -->
        <!-- <el-card
          v-show="tinetLoginFrom"
          :bordered="false"
          :padding="4"
          :dis-hover="true"
          style="width: 220px"
        > -->
          <!-- <template #header>
            <div class="status-set">
              <el-tag>{{
                tinetFrom.bindType == 1 ? "普通电话" : "虚拟电话"
              }}</el-tag>
              <p>状态设置</p>
              <a href="#" @click.prevent="tinetLogout" style="color: #2d8cf0">
                <i class="el-icon-turn-off"></i>
                注销
              </a>
            </div>
          </template> -->
          <!-- <div style="margin-top: 4px">
            <el-button
              size="mini"
              type="primary"
              @click="actionTinetDoUnpause"
              style="padding: 4px 6px"
              >空闲</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="actionTinetDopause('忙碌')"
              style="padding: 4px 4px"
              >忙碌</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="actionTinetDopause('勿打扰')"
              style="padding: 4px 5px"
              >勿打扰</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="actionTinetDopause('吃饭饭')"
              style="padding: 4px 5px"
              >吃饭饭</el-button
            >
          </div> -->
          <!-- <el-row v-if="getTinetDeviceStatus === 'idle'">
            <el-col>
              <div class="ivu-input-group" style="margin: 10px 0">
                <div>
                  <el-autocomplete
                    class="inline-input"
                    v-model="tinetFrom.bindTel"
                    :data="telList"
                    placeholder="请输入绑定分机号码"
                    size="small"
                    :fetch-suggestions="querySearch"
                  ></el-autocomplete>
                </div>
              </div>
            </el-col>
            <el-col>
              
              <el-button
                size="small"
                :type="
                  getTinetLoginStatus.status === 'offline'
                    ? 'primary'
                    : 'success'
                "
                style="width: 100%; margin-bottom: 10px"
                @click="conTinetWS"
                :loading="getConLoading"
              >
                <i
                  :type="
                    getTinetLoginStatus.status === 'offline'
                      ? 'android-call'
                      : 'ios-browsers'
                  "
                ></i>
                {{
                  getTinetLoginStatus.status === "offline"
                    ? "绑定电话"
                    : "更换绑定"
                }}
              </el-button>
            </el-col>
          </el-row> -->
        <!-- </el-card> -->
      <!-- </el-popover> -->
    </div>

    <!-- <div class="lock-screen-btn-con">
      <el-dropdown trigger="click">
        <i class="el-icon-phone" :size="24" style="color: #19be6b"></i>
        <el-dropdown-menu slot="dropdown">
          <div
            :class="[
              { positionAbsolute: fixation },
              'udesk-callcenter-component',
            ]"
          >
            <div data-reactrootforEach="">
              <div class="top-bar" unselectable="on">
                <div class="buttons">
                  <div style="margin-right: 20px" @click="fixation = !fixation">
                    <i :type="fixation ? 'arrow-up-b' : 'arrow-down-b'"></i>
                  </div>
                  <div class="resize-btn" @click="visible = !visible">
                    <i
                      :type="
                        hideConWrapper
                          ? 'android-checkbox-outline-blank'
                          : 'close-round'
                      "
                    ></i>
                  </div>
                </div>
                <div class="title">拨号</div>
              </div>
              <div :class="[{ hide: hideConWrapper }, 'content-wrapper']">
                <div>
                  <div style="margin-bottom: 20px">
                    <el-input
                      size="small"
                      v-model="callPhoneNum"
                      :maxlength="16"
                    >
                      <i slot="suffix" v-hejinyo="{ backgroundColor: 'red' }"
                          @click="numberBtnDel" class="el-input__icon el-icon-back"></i>
                    </el-input>
                  </div>
                  <div :class="[{ hide: hideKeyboard }, 'keyboard']">
                    <div class="left">
                      <button
                        v-hejinyo
                        v-for="item in ['1', '4', '7', '*']"
                        :key="item"
                        class="numberButton"
                        @click="numberBtnClick(item)"
                      >
                        {{ item }}
                      </button>
                    </div>
                    <div class="center">
                      <button
                        v-hejinyo
                        v-for="item in ['2', '5', '8', '0']"
                        :key="item"
                        class="numberButton"
                        @click="numberBtnClick(item)"
                      >
                        {{ item }}
                      </button>
                    </div>
                    <div class="right">
                      <button
                        v-hejinyo
                        v-for="item in ['3', '6', '9', '#']"
                        :key="item"
                        class="numberButton"
                        @click="numberBtnClick(item)"
                      >
                        {{ item }}
                      </button>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <el-button
                    size="small"
                    v-show="getTinetautoCallStatus"
                    long
                    :type="
                      getTinetDeviceStatus === 'idle' ? 'success' : 'error'
                    "
                    @click="callOut"
                    style="width: 100%;margin-bottom: 4px"
                  >
                    <i type="ios-telephone" color="#FFF" size="10"></i>
                    {{ getTinetDeviceStatus === "idle" ? "呼出" : "挂断" }}
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
    </div> -->
    <HqModal :visible="selectShow" width="450" @on-cancel="cancel">
      <el-form ref="form" :model="form" :rules="formRules" style="text-align: center">
        <v-select
          style="width: 282px; margin: 18px auto 0"
          v-model="form.trackType"
          :items="trackTypeList"
          :menu-props="{ bottom: true, offsetY: true }"
          label="所属赛道"
          item-text="trackTypeName"
          item-value="trackType"
          outlined
          :dense="true"
          :height="32"
          :clearable="true"
          clear-icon="$clear"
          hide-details="auto"
        ></v-select>
        <div style="color: #f54955; font-size: 12px; line-height: 24px">
          查询的数据存在多个赛道，请选择赛道后继续查询
        </div>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="save" type="primary">确认</el-button>
      </div>
    </HqModal>
  </span>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import {
  reqTinetUserInfo,
  reqLoginCheck,
  reqTinetCnoTel,
  reqTinetLogout,
  getEnterpriseList,
  requserConfigCheck,
  reqLoginphoneCheck,
  reqlogout,
  reqLogincall
} from "api/call/tinet-api";
import Socket from '@/utils/webSocket'
import { getSelectByDetail } from "@/api/home";


export default {
  name: "call-center",
  // 创建CallCenter通话组件Iframe标签
  components: {
    tntalk: {
      render (createElement) {
        return createElement("iframe", {
          attrs: {
            style: "display: none",
            id: "tinetIframe",
            width: "100%",
            height: "100%",
            name: "toolbar",
            scrolling: "no",
            frameborder: "0",
            src: "static/html/tntoolbar.html",
          }
        })
      }
    }
  },
  props: {
    btnShow: {
      default: "选择",
    },
  },
  data() {
    return {
      selectShow: false,
      form: {
        trackType: "",
      },
      formRules: {
        trackType: [
          { require: true, message: "请选择赛道"  }
        ]
      },
      trackTypeList: [],
      fixation: false,
      statusVib: false,
      visible: false,
      hideConWrapper: false,
      hideKeyboard: false,
      // 连接ws按钮加载
      // conLoading: false,
      // CallCenter设置面板
      tinetSettingVib: false,
      // CallCenter登录面板
      tinetLoginFrom: false,
      // CallCenter绑定号码连接wesocket面板
      tinetConnectionFrom: false,
      // 电话验证码发送成功
      validateCodeSuccess: false,
      tinetFrom: {
        bindTel: null,
        enterpriseId: "",
        cno: "",
        pwd: "",
        securityCode: "",
        securityValue: "",
        validateCode: null,
        userValidataCode: null,
        authCode: "",
        bindType: "1",
      },
      telList: [],
      codeList: [],
      security: {
        code: null,
        codeImg: null,
      },
      callPhoneNum: "",
      acf: false,
      // 1 -- axb外呼
      docallType: "0",
      // 鉴权loading
      checkLoading: false,
      // 指定外显号码
      telNumber: localStorage.getItem("telNumber"),
      enterpriseList: [],
      enterpriseId: "",
    };
  },
  computed: {
    ...mapGetters([
      "getTinetWsScript",
      "getTinetLoginStatus",
      "getTinetDeviceStatus",
      "getTinetautoCallStatus",
      "getTinetOvertCallStatus",
      "getTinetAbsolveCallStatus",
      "getTinetAssignCallStatus",
      "getTinetLogoutStatus",
      "getIfMultiTrack",
      "getConLoading",
      "getBackCall",
      "loginUser",
    ]),
  },
  mounted() {
    this.getTinetUserInfo();
    this.getEnterprise();
    this.$nextTick(() => {
      this.GetuserConfigCheck()
    })
  },
  methods: {
    ...mapActions([
      "actionTinetdoLogin",
      "actionTinetCallOutAndGoto",
      "actionTinetCallCancel",
      "actionTinetDoUnpause",
      "actionTinetDopause",
      "actionTinetDoLogout",
      "gotoCustomerInfoByPhone",
    ]),
    ...mapMutations(["setIfMultiTrack"]),
    cancel() {
      this.selectShow = false;
    },
    save() {
      console.log('this.callPhoneNum.replace(/\s+/g, "")', this.callPhoneNum.replace(/\s+/g, ""));
      // this.actionTinetCallOutAndGoto({
      //   tel: this.callPhoneNum.replace(/\s+/g, ""),
      //   // doCallType: this.doCallType,
      //   trackType: this.form.trackType
      // }); 
    },
    async showClick() {
      // let { result, code } = await getFindSeatNumber()
      // if(code === 0) {
        //   this.tinetFrom.enterpriseId = result.enterpriseId
      //   this.tinetFrom.cno = result.cno
      // }

      // if (this.tinetLoginFrom == true) {
      //   this.tinetLoginFrom = true
      // } else {
        // }
        this.tinetLoginFrom = false
      console.log('点击', this.tinetLoginFrom);
    },

    // 拨号盘按钮
    numberBtnClick(num) {
      if (this.callPhoneNum.length < 12) {
        this.callPhoneNum = this.callPhoneNum + "" + num;
      }
    },

    // 拨号盘删除
    numberBtnDel() {
      if (this.callPhoneNum.length > 0) {
        this.callPhoneNum = this.callPhoneNum.substring(
          0,
          this.callPhoneNum.length - 1
        );
      }
    },

    priseChange(val) {
      this.enterpriseList.map((item) => {
        if (item.enterpriseId == val) {  
          this.tinetFrom.callCenterConfigId = item.id
          this.tinetFrom.source = item.source
        }
      })
      console.log('val', val, this.tinetFrom);
      localStorage.setItem("enterpriseId", val);
    },

    // 获取CallCenter用户信息
    getTinetUserInfo() {
      this.visible = false;
      // reqTinetUserInfo().then((data) => {
      //   let { code, msg, result } = data;
      //   if (code === 0) {
      //     // 已经登录CallCenter
      //     this.tinetLoginFrom = true;
      //     this.tinetFrom = result;
      //     localStorage.setItem("enterpriseId", result.enterpriseId);
      //     this.enterpriseId = result.enterpriseId;
      //     this.tinetFrom.bindType = localStorage.getItem("bindType") | 1;
      //     // 查询绑定电话列表
      //     this.getTinetCnoTel();
      //   } else if (code === 1200) {
      //     this.tinetLoginFrom = false;
      //   } else {
      //     this.$message.error(msg);
      //   }
      // });
    },
    // 获取新坐席信息接口userinfo
      GetuserConfigCheck() {
        requserConfigCheck().then(res => {
          console.log('res-----userinfo', res);
          if (res.code == 0) {
            this.tinetFrom.callCenterConfigId = res.result.callCenterConfigId
            this.tinetFrom.enterpriseId = res.result.enterpriseId
            this.tinetFrom.cno = res.result.cno
            this.tinetFrom.status = res.result.status
            this.tinetFrom.source = res.result.source
            if(res.result && res.result.cno !== undefined && res.result.source == 2) {
              this.GetLoginphoneCheck(res.result)
              this.getorangeLogin(res.result)
            }
          }
        })
      },
      // 获取新坐席信息后的登录
      GetLoginphoneCheck(val){
        let params = {
          callCenterConfigId: val.callCenterConfigId,
          source: val.source,
          cno: val.cno,
          enterpriseId: val.enterpriseId
        }
        reqLoginphoneCheck(params).then(res => {
          console.log('自动登陆', res);
          if (res.code == 0) {
            this.tinetFrom.callCenterConfigId = res.result.callCenterConfigId
            this.tinetFrom.enterpriseId = res.result.enterpriseId
            this.tinetFrom.source = res.result.source
            this.tinetFrom.cno = res.result.cno
            this.enterpriseId = res.result.enterpriseId
            this.source = res.result.source
            localStorage.setItem('source',res.result.source)
            // if (res.result.source == 2) {
            //   this.getorangeLogin(res.result)
            // }
          }
          
        })
      },
      getorangeLogin(val) {
        let params = {
          callCenterConfigId: val.callCenterConfigId,
          cno: val.cno,
          enterpriseId: val.enterpriseId,
          source: val.source
        }
        reqLoginCheck(this.tinetFrom).then((data) => {
          let { code, msg, result } = data;
          if (code === 0) {
              this.acf = true;
              this.checkLoading = false;
              // 隐藏登录界面，显示绑定电话列表框
              this.tinetLoginFrom = true;
              // // 查询绑定电话列表
              // this.getTinetCnoTel();
              let bindType = this.tinetFrom.bindType;
              this.tinetFrom = result;
              this.tinetFrom.securityValue = "";
              this.tinetFrom.bindType = bindType;
              if(this.tinetFrom.extensionStatus != 0) {
                  this.socket(this.tinetFrom)
              }
              this.$message.success(msg);
              // localStorage.setItem("bindType", this.tinetFrom.bindType);
            } else {
              this.tinetLoginFrom = false
              this.$message.error(msg);
              this.checkLoading = false;
            }
            console.log('tinetLoginFrom',this.tinetLoginFrom);
          });
      },

    // 鉴权
    doLoginCheck() {
      // this.tinetFrom.securityCode = this.security.code
      this.enterpriseId = this.tinetFrom.enterpriseId;
      this.checkLoading = true;
      console.log('登陆', this.tinetFrom);
      return reqLoginCheck(this.tinetFrom).then((data) => {
        let { code, msg, result } = data;
        if (code === 0) {
          this.acf = true;
          this.checkLoading = false;
          // 隐藏登录界面，显示绑定电话列表框
          this.tinetLoginFrom = true;
          // // 查询绑定电话列表
          // this.getTinetCnoTel();
          let bindType = this.tinetFrom.bindType;
          this.tinetFrom = result;
          this.tinetFrom.securityValue = "";
          this.tinetFrom.bindType = bindType;
          this.socket(this.tinetFrom)
          this.$message.success(msg);
          // localStorage.setItem("bindType", this.tinetFrom.bindType);
        } else {
          this.$message.error(msg);
          this.checkLoading = false;
        }
      });
    },
    // 众投连接socket
      socket(val){
        console.log('参数',val);
        let _this = this
        let Websocket = `${val.webSocketUrl}/`
        // let Websocket = val.webSocketUrl
        new Socket({
          // soket服务地址
          socketUrl: Websocket,
          extnumber: val.extension,
          // 回调data
          soketCallback: (data) => {
            console.log('data000000001111', data);
            _this.soketCallback(data)
            // _this.$store.commit('setTinetCallStatuszthl')
          }
        })
      },
       // soket回调
      soketCallback(data, url) {
        console.log('soket回调', data);
        switch (data.data.status) {
        case "idle": // 空闲
          // this.$store.commit('setTinetCallStatuszthl', 'idle')
          this.$store.commit('setTinetLoginStatus', {
            status: 'idle',
            type: 'success',
            show: '空闲'
          })
          break;
        case "offline": // 离线
          // this.$store.commit('setTinetCallStatuszthl', 'offline')
          this.$store.commit('setTinetLoginStatus', {
            status: 'offline',
            type: 'primary',
            show: '离线'
          })
          break;
        case "hangup": // 挂断
          console.log('this', this);
          // this.$store.commit('setTinetCallStatuszthl', 'hangup')
          this.$store.commit('setTinetLoginStatus', {
            status: 'hangup',
            type: 'success',
            show: '空闲'
          })
          break;
        case "answer": // 通话中
          // this.$store.commit('setTinetCallStatuszthl', 'answer')
          this.$store.commit('setTinetLoginStatus', {
            status: 'answer',
            type: 'primary',
            show: '通话中'
          })
          break;
        case "ring": // 响铃
          // this.$store.commit('setTinetCallStatuszthl', 'ring')
          this.$store.commit('setTinetLoginStatus', {
            status: 'ring',
            type: 'primary',
            show: '响铃中'
          })
          break;
        default:
          console.log('进入到回调', that.callback == 'function');

      }
       
      },

    //  返回绑定电话列表
    getTinetCnoTel() {
      reqTinetCnoTel().then((data) => {
        let { code, msg, result } = data;
        if (code === 0) {
          let tel = [];
          result.map((item) => {
            tel.push({ value: item });
          });
          this.telList = tel;
        } else {
          this.$message.error(msg);
        }
      });
    },

    // 登录CallCenterwebsocket
    conTinetWS() {
      this.tinetFrom.enterpriseId = Number(this.enterpriseId);
      if (!this.tinetFrom.bindTel || this.tinetFrom.bindTel.length === 0) {
        this.$message.error("请选择绑定电话");
        return;
      }
      // 把输入的指定外呼资源存起来
      if (this.telNumber) {
        localStorage.telNumber = this.telNumber;
      }
      // this.conLoading = true
      this.$store.commit("setConLoading", true);

      this.tinetFrom.bindType = localStorage.getItem("bindType") | 1;
      this.doLoginCheck()
        .then(() => {
          this.actionTinetdoLogin(this.tinetFrom);
        })
        .finally(() => {
          this.$store.commit("setConLoading", false);
        });
    },

    // 退出登录
    async tinetLogout() {
      this.tinetFrom.enterpriseId = this.enterpriseId

      // this.tinetFrom.enterpriseId = localStorage.getItem("enterpriseId");
      // this.tinetFrom.bindType = localStorage.getItem("bindType");
      console.log('this.getTinetLoginStatus.status', this.getTinetLoginStatus.status);
      reqlogout().then(res => {
          if (res.code == 0) {
            this.tinetLoginFrom = false;
            this.$message.success('退出成功')
            this.$store.commit('setTinetLoginStatus', {
              status: 'offline',
              type: 'primary',
              show: '离线'
            })
          } else {
            this.$message.error(res.msg)
          }
        })
      // 如果CallCenter不在线，进行本系统退出
      if (this.getTinetLoginStatus.status === "idle") {
        // // 登录面板
        // // 系统CallCenter退出
        // await reqTinetLogout();
      } else {
        // websocke退出
        // this.actionTinetDoLogout();
      }
      
    },

    callCustomer(status) {
      this.doCallType = status;
      this.doCallClick();
    },

    checkedTrack(trackType) {
      this.doCallClick(trackType);
      this.setIfMultiTrack(false);
    },

    // 拨号盘拨打按钮
    doCallClick(trackType = null) {
     // 如果通话工具栏未关闭的话就不让进行外呼操作
      if (this.getTinetDeviceStatus === "idle") {
        if (this.getBackCall) {
          this.$message.warning("请先关闭通话工具栏再进行外呼操作");
          return false;
        }
        let paramTel = {
          toPhone: this.callPhoneNum,
          customerId: 0
        }
        this.getOrangePhonezthl(paramTel)
        // this.gotoCustomerInfoByPhone({tel: this.callPhoneNum, customerId: 0});
        // this.actionTinetCallOutAndGoto({
        //   tel: this.callPhoneNum.replace(/\s+/g, ""),
        //   doCallType: this.doCallType,
        //   trackType: this.$store.state.user.loginUser.trackType
        // });

        // let params = {
        //   customerId: 0,
        //   toPhone: this.callPhoneNum
        // }
        // reqLogincall(params).then(res => {
        //   if (res.code != 0) {
        //       this.$Message.error(res.msg)
        //     }
        // })

      } else {
        if (this.getTinetDeviceStatus != "calling") {
          this.actionTinetCallCancel();
        }
      }
    },
    // 众投外呼请求
    getOrangePhonezthl(params) {
       try {
          reqLogincall(params).then(res => {
            console.log('进入了众投外呼判断了', res);
            if (res.code != 0) {
              this.$message.error(res.msg)
            } else {
              let _this = this
              new Socket({
                // soket服务地址
                socketUrl: 'wss://crm1.zthltx.com:2333/websocket/',
                extnumber: params.toPhone,
                // 回调data
                soketCallback: (data) => {
                  console.log('data00000000', data);
                  this.soketCallbacktel(data)
                  // _this.$store.commit('setTinetCallStatuszthl')
                }
              })
            }
          })
        } catch (erroe) {

        }
    },
    soketCallbacktel(data) {
      let statusType = data.data.status
      console.log('statusType', statusType, data.data);
      
      console.log('soket回调', data);
      switch (statusType) {
        case "idle": // 空闲
          this.$store.commit('setTinetCallStatuszthl', 'idle')
          this.$store.commit('setTinetLoginStatus', {
            status: 'idle',
            type: 'success',
            show: '空闲'
          })
          break;
        case "offline": // 离线
          this.$store.commit('setTinetCallStatuszthl', 'offline')
          this.$store.commit('setTinetLoginStatus', {
            status: 'offline',
            type: 'primary',
            show: '离线'
          })
          break;
        case "hangup": // 挂断
          console.log('this', this);
          this.$store.commit('setTinetCallStatuszthl', 'hangup')
          this.$store.commit('setTinetLoginStatus', {
            status: 'hangup',
            type: 'success',
            show: '空闲'
          })
          break;
        case "answer": // 通话中
          this.$store.commit('setTinetCallStatuszthl', 'answer')
          this.$store.commit('setTinetLoginStatus', {
            status: 'answer',
            type: 'primary',
            show: '通话中'
          })
          break;
        case "ring": // 响铃
          this.$store.commit('setTinetCallStatuszthl', 'ring')
          this.$store.commit('setTinetLoginStatus', {
            status: 'ring',
            type: 'primary',
            show: '响铃中'
          })
          break;
        default:
          console.log('进入到回调', that.callback == 'function');
      }
    },

    // 一键外呼
    callOut() {
      this.doCallClick()
      // console.log('this.callPhoneNum', this.callPhoneNum);
      // let trackType = this.$store.state.user.loginUser.trackType
      // let tel = {
      //   tel: this.callPhoneNum,
      //   trackType,
      //   tinetFrom: this.tinetFrom
      // }
      // if (this.callPhoneNum) {
      //   this.gotoCustomerInfoByPhone({tel, customerId: 0});
      // }
    },

    // 弹屏按钮
    gotoInfo() {
      if (this.callPhoneNum) {
        this.gotoCustomerInfoByPhone(this.callPhoneNum);
      }
    },

    querySearch(queryString, cb) {
      let telList = this.telList;
      let results = queryString
        ? telList.filter(this.createFilter(queryString))
        : telList;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },

    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },

    // 获取呼叫中心列表
    async getEnterprise() {
      let { result, code } = await getEnterpriseList(2);
      if (code === 0) {
        console.log('result呼叫列表', result);
        this.enterpriseList = result;
      }
    },
  },
  watch: {
    // tientValidateTTT(val) {
    //   if (!val) {
    //     this.validateCodeSuccess = false;
    //   }
    // },
    // getTinetTelValidate(val) {
    //   if (val) {
    //     this.tientValidateTTT = val;
    //   }
    // },
    getTinetWsScript(val) {
      // 从localStorage获取是否需要自动登录，不再通过服务器去判断
      if (
        val &&
        this.getTinetLoginStatus.status === "offline" &&
        this.tinetFrom.bindTel
      ) {
        // 进行登录CallCenter操作
        if (localStorage.getItem("tinetToLogin") === "true") {
          console.log("用户绑定了电话而且登录过，所以进行自动登录");
          if (this.tinetFrom.bindTel != null) {
            this.tinetFrom.bindType =
              this.tinetFrom.bindType | localStorage.getItem("bindType");
            this.doLoginCheck();
          }
          let _this = this;
          // this.conTinetWS()
          setTimeout(function () {
            if (_this.acf) {
              _this.conTinetWS();
            }
          }, 1000);
        }
      }
    },
    // 登录状态监听
    // getTinetLoginStatus(val) {
    //   // this.conLoading = false
    //   this.$store.commit("setConLoading", false);
    // },
    // 监听退出CallCenter结果，退出成功，同时退出本系统
    getTinetLogoutStatus(val) {
      if (val) {
        // 登录面板
        this.tinetLoginFrom = false;
        this.$store.commit("setTinetLogoutStatus", false);
        // 系统CallCenter退出
        // TinetApi.reqTinetLogout().then((data) => {});
      }
    },
  },
  // beforeCreate() {
  //   // 组件实例化之前
  //   this.$store.commit("setInitTinet");
  // },
  // destroyed() {
  //   // 组件已经销毁
  //   this.$store.commit("setInitTinet");
  // },
  directives: {
    // 可拖动指令
    // drag(el) {
    //   el.onmousedown = function (e) {
    //     let disx = e.pageX - el.offsetLeft;
    //     let disy = e.pageY - el.offsetTop;
    //     document.onmousemove = function (e) {
    //       el.style.left = e.pageX - disx + "px";
    //       el.style.top = e.pageY - disy + "px";
    //     };
    //     document.onmouseup = function () {
    //       document.onmousemove = document.onmouseup = null;
    //     };
    //   };
    // },
    // 按钮按下弹起效果指令
    hejinyo: function (el, binding) {
      let oldColor = el.style.color;
      let oldBackgroundColor = el.style.backgroundColor;
      el.onmousedown = function () {
        el.style.backgroundColor =
          binding.value && binding.value.backgroundColor
            ? binding.value.backgroundColor
            : "#19be6b";
        el.style.color =
          binding.value && binding.value.color
            ? binding.value.color
            : "#ffffff";
      };
      el.onmouseup = function () {
        el.style.backgroundColor = oldColor;
        el.style.color = oldBackgroundColor;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
// @mixin circle($r) {
//   -webkit-border-radius: $r;
//   -moz-border-radius: $r;
//   border-radius: $r;
// }

.lock-screen-btn-con {
  display: inline-block;
  width: 30px;
  // padding: 18px 0;
  text-align: center;
  cursor: pointer;

  i {
    vertical-align: middle;
  }
}

.call-btn {
  padding: 4px 10px;
  min-height: 20px;
}

.call-login {
  width: 100%;
  background: #409eff;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  text-align: center;
  line-height: 30px;
  margin-bottom: 10px;
}

.status-set {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.numberButton {
  z-index: 999;
  outline: none;
  width: 94%;
  height: 24px;
  border: 1px solid #e4e4e4;
  background-color: #eee;
  margin-bottom: 7px;
  font-size: 16px;
  color: #666;
  cursor: pointer;
}

#udesk-callcenter-component-alert {
  z-index: 999;
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 30px;
  color: #fff;
}

.udesk-callcenter-component {
  z-index: 999;
  background-color: #fff;
  width: 228px;
  /*
        width: 270px;
    */
  /*position: absolute;*/
  /* position: absolute;
     right: 0;*/
  border-bottom: 1px solid #e4e4e4;
  .top-bar {
    height: 34px;
    line-height: 34px;
    padding-left: 11px;
    padding-right: 11px;
    background-color: #333;
    //cursor: move;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
    .title {
      color: #fff;
      height: 100%;
      float: left;
    }
    .buttons {
      float: right;
      > * {
        cursor: pointer;
        display: inline-block;
        color: #fff;
        margin-left: 5px;
      }
    }
  }
  .content-wrapper {
    /*padding: 20px;*/
    padding: 10px;
    border: 0 solid #e4e4e4;
    border-right-width: 1px;
    border-left-width: 1px;
    color: #666;
    font-size: 14px;
  }
  .agent-state-panel {
    background-color: #f2f2f2;
    padding: 15px;
    .way-select {
      width: 50px;
    }
    .state-select {
      width: 65px;
      float: right;
    }
    .working {
      font-size: 14px;
    }
  }
  .ucm-dropdown {
    position: relative;
    font-size: 14px;
    > img,
    div {
      cursor: pointer;
    }
    > img {
      float: right;
      margin-top: 4px;
    }
    ul {
      border: 1px solid #ccc;
      padding: 3px;
      position: absolute;
      left: 0;
      top: 100%;
      margin: 0;
      width: 100%;
      background-color: #fff;
      z-index: 9999999;
      list-style: none;
      li {
        padding: 3px 0;
        cursor: pointer;
        &:hover {
          background-color: #c3c3c3;
        }
      }
      &.up {
        bottom: 100%;
        top: auto;
      }
    }
  }
  .work-state-idle,
  .work-state-busy,
  .work-state-resting,
  .work-state-offline,
  .work-state-ringing,
  .work-state-talking,
  .work-state-neaten {
    i {
      display: inline-block;
      vertical-align: middle;
      width: 10px;
      height: 10px;
      margin-right: 3px;
      // @include circle(10px);
    }
  }
  .work-state-idle {
    i {
      background-color: #49b34f;
    }
  }
  .work-state-busy {
    i {
      background-color: #d75452;
    }
  }
  .work-state-resting {
    i {
      background-color: #d75452;
    }
  }
  .work-state-offline {
    i {
      background-color: #d75452;
    }
  }
  .work-state-neaten {
    i {
      background-color: #b5c342;
    }
  }
  .work-state-talking {
    i {
      background-color: #d75452;
    }
  }
  .work-state-ringing {
    i {
      background-color: #d75452;
    }
  }
  .agent-select {
    position: relative;
    .dropdown {
      height: 240px;
      overflow: auto;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 100%;
      border: 1px solid #c3c3c3;
      background-color: #fff;
      margin: 0;
      padding: 0;
      li {
        list-style-type: none;
        text-align: left;
        padding: 5px;
        cursor: pointer;
        &:hover {
          background-color: #e0e0e0;
        }
        &.text-center {
          text-align: center;
        }
      }
    }
    .display-frame {
      border: 1px solid #c3c3c3;
      padding: 5px;
      font-size: 12px;
      text-align: left;
      cursor: pointer;
      img {
        float: right;
        margin-top: 3px;
      }
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .text-center {
    text-align: center;
  }
  .hide {
    display: none;
  }
  .common-btn {
    border: 0px solid #ccc;
    text-align: center;
    font-size: 12px;
    padding: 12px 15px 0;
    background-color: #fff;
    cursor: pointer;

    img {
      height: 20px;
      width: 20px;
      vertical-align: middle;
    }
  }
  .common-btn:hover {
    background: rgba(240, 240, 240, 1);
  }
  button.common-btn {
    outline: none;
  }
  .btn-group {
    display: inline-block;
    //border-right: 1px solid #ccc;
  }
  .inline-block {
    display: inline-block;
  }
  .number-input {
    height: 36px;
    border: 1px solid #e4e4e4;
    margin-bottom: 20px;
    > img {
      float: right;
      margin-top: 6px;
      margin-right: 5px;
      cursor: pointer;
    }
    > div {
      margin-right: 30px;
      height: 100%;
      > input {
        border: 0;
        width: 100%;
        height: 100%;
        outline: none;
        font-size: 16px;
        padding: 0 0 0 5px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
      }
    }
  }
  .keyboard {
    .left,
    .center,
    .right {
      float: left;
      width: 33.333333333%;
    }
    .left {
      text-align: left;
    }
    .center {
      text-align: center;
    }
    .right {
      text-align: right;
    }
  }
  
  .customer-info {
    padding-top: 10px;
    .number-content {
      margin-right: 15px;
    }
  }

  hr {
    border: 0;
    height: 1px;
    background-color: #ccc;
    margin-bottom: 18px;
  }

  .desc-info {
    margin-bottom: 50px;
  }

  .time-info {
    margin-bottom: 70px;
  }
  .text-center {
    text-align: center;
  }
  .talking-panel {
    .agent-select-wrapper {
      padding: 0 30px;
      margin-bottom: 24px;
    }
    .time-info {
      margin-bottom: 25px;
    }
    .bottom-btns {
      margin-bottom: 12px;
    }
  }
  .pull-right {
    float: right;
  }
  .image-button {
    img {
      width: 20px;
      height: 20px;
    }
    vertical-align: middle;
  }
  .bottom-extension {
    border: 0 solid #e4e4e4;
    border-left-width: 1px;
    border-right-width: 1px;
  }
  .btn-manual-screen-pop {
    background-color: #7085b3;
    color: #fff;
    border: 0;
    height: 47px;
    width: 100%;
    margin-top: 15px;
  }

  ::v-deep.el-input__suffix {
    background-color: #eee;
  }
}
</style>
<style lang="scss" scoped>
.call-center {
  ::v-deep.ivu-form-item-label {
    padding: 8px 7px;
    background-color: #eee;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border: 1px solid #dddee1;
  }
  ::v-deep.ivu-select-selection {
    border-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    text-align: left;
  }
}
</style>
