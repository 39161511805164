<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-04-22 15:58:02
 * @LastEditors: Seven
 * @LastEditTime: 2021-11-11 09:25:38
-->
<template>
  <div class="sidebar">
    <div class="sidebar-tab">
      <div
        :class="[
          'sidebar-item',
          {'activeName': activeName === item.path},
          {'active': active === index},
        ]"
        v-for="(item, index) in itemList.children"
        :key="item.name"
        @mouseenter="selectStyle(index)"
        @mouseleave="outStyle"
        @click="sideClick(item)"
      >
        <!-- 路由有参数的active-class="sidebar-active"无效 ，原因不明 -->
        <router-link exact :to="item.path" :class="{'sidebar-active':activeName === item.path}">
          {{ item.title }}
        </router-link>
        <i></i>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    itemList: {
      type: Object,
    },
  },
  data() {
    return {
      active: null
    }
  },
  computed: {
    activeName() {
      return this.$route.path
    }
  },
  methods: {
    sideClick(item) {
      this.$emit("getSide", item.title, this.itemList)
    },
    selectStyle(index) {
      this.active = index;
    },
    outStyle() {
      this.active = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.sidebar {
  display: flex;
  justify-content: center;
  height: 40px;
  background: #fff;
  border-top: 1px solid #cecece;
  border-bottom: 2px solid #f5f7f9;

  .sidebar-tab {
    display: flex;
    justify-content: flex-start;
  }

  .active {
    > a {
      color: #409eff !important;
    }

    &:hover {
      i {
        width: 100%; 
        left: 0;
        transition: all 0.3s;
      }
    }
  }
  .sidebar-item {
    margin: 0 14px;
    position: relative;
    text-align: center;
    line-height: 39px;

    i {
      position: absolute;
      width: 0%;
      height: 3px;
      background-color: #337aff;
      bottom: 4px;
      left: 50%;
    }

    a {
      color: #000;
    }
    .sidebar-active {
      color: #409eff !important;
    }
  }

  .activeName {
    i {
      width: 100%; 
      left: 0;
    }
  }
}
</style>
