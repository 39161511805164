/*
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-10-09 14:19:45
 * @LastEditors: Seven
 * @LastEditTime: 2022-05-24 10:39:54
 */
import axios from "axios";

const baseUrl = "/qw_report_api";
const base = "/qw_api";

// 查询校区客户跟进仪表盘
export const getQuerySchoolFollowReport = (data) => {
  return axios.post(`${baseUrl}/report/querySchoolFollowReport`, data).then(res => res.data) 
}
// 查询仪表盘客户详情
export const getQueryCustomerDetail = (data) => {
  return axios.post(`${baseUrl}/report/customer/queryCustomerDetail`, data).then(res => res.data) 
}
// 根据用户数据权限获取校区列表 
export const getQuerySchoolListdel = () => {
  return axios.post(`${base}/user/dept/scope/deal/list`).then(res => res.data) 
}
export const getQuerySchoolList = () => {
  return axios.post(`${base}/workbench/dept/querySchoolList`).then(res => res.data) 
}
// 查询招生业绩报表
export const getQueryTeacherReport = (data) => {
  return axios.post(`${baseUrl}/report/recruit/queryRecruitTeacherReport`, data).then(res => res.data) 
}


/**
 * 漏斗模型数据
 * @param {*} param0 
 * @returns 
 */
 export function queryFunnelModel(data){
  return axios.post(`${baseUrl}/report/funnelModel/queryFunnelModelOne`,data).then(res=>res.data)
}