/*
 * @Description:
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2020-03-17 10:18:04
 * @LastEditors: Seven
 * @LastEditTime: 2021-07-26 17:53:55
 */
import axios from "axios";

const baseUrl = "/qw_api";

// 保存通话记录
export const reqCallRecordSave = (uniqueId) => {
  return axios({
    url: `${baseUrl}/call/record`,
    method: "post",
    params: { uniqueId: uniqueId },
  }).then((res) => res.data);
};

// 通话记录分页查询
export const reqCallRecordListPage = (method, params, data) => {
  return axios({
    url: `${baseUrl}/call/record/listPage`,
    method: method,
    data: data,
    params: params,
  }).then((res) => res.data);
};

// 查询电话归属地
export const reqPhoneAttr = (phone) => {
  return axios.get(`${baseUrl}/call/attr/` + phone).then((res) => res.data);
};

// 个人通话首页统计
export const reqPersonStatis = (type) => {
  return axios
    .get(`${baseUrl}/call/statis/person/` + type)
    .then((res) => res.data);
};

// 拨打频次校验
export const reqCheckCall = (params) => {
  return axios
    .post(
      `${baseUrl}/call/checkCall?customerId=${params.customerId}&phone=${params.phone}`
    )
    .then((res) => res.data);
};

// 拨打频次配置列表
export const callListPage = (method, data, params) => {
  return axios({
    url: `${baseUrl}/call/configuration/listPage`,
    method: method,
    data: data,
    params: params,
  }).then((res) => res.data);
};

// 拨打频次配置修改
export const callEdit = (data) => {
  return axios
    .put(`${baseUrl}/call/configuration`, data)
    .then((res) => res.data);
};

// 拨打频次配置删除
export const callDele = (id) => {
  return axios
    .delete(`${baseUrl}/call/configuration/` + id)
    .then((res) => res.data);
};

// 拨打频次配置删除
export const getFindSeatNumber = () => {
  return axios
    .get(`${baseUrl}/sys/user/findSeatNumber`)
    .then((res) => res.data);
};
