/*
 * @Description:
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-04-28 09:31:24
 * @LastEditors: Seven
 * @LastEditTime: 2022-02-28 14:22:34
 */
import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import store from "@/store";
import { checkAuth } from "@/utils/common";
import { allRoutes } from "./moudles";

Vue.use(VueRouter);

const errorRouter = [
  {
    path: '/403',
    meta: { requireAuth: false, title: '403-权限不足' },
    name: 'error-403',
    component: resolve => {
      require(['@//views/error-page/403.vue'], resolve)
    }
  },
  {
    path: '/*',
    name: 'error-404',
    meta: { requireAuth: false, title: '404-页面不存在' },
    component: resolve => {
      require(['@/views/error-page/404.vue'], resolve)
    }
  },
  // {
  //   path: '/500',
  //   meta: { requireAuth: false, title: '500-服务端错误' },
  //   name: 'error-500',
  //   component: resolve => {
  //     require(['@/views/error-page/500.vue'], resolve)
  //   }
  // }
]
// 路由配置
const RouterConfig = {
  // mode: 'history',
  routes: [...allRoutes, ...errorRouter]
};
export const router = new VueRouter(RouterConfig);

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

NProgress.inc(0.2);
NProgress.configure({ easing: "ease", speed: 600, showSpinner: false });

router.beforeEach(async (to, from, next) => {
  if (to.query.token) {
    // localStorage.userToken = to.query.token
    localStorage.setItem("userToken", to.query.token)
  }
  let userToken = localStorage.getItem("userToken")
  if(userToken) {
    
    if(store.state.user.loginUser.trackType === undefined) {
      await store.dispatch("getUserInfo") 
    }
    // 判断进入的页面是否需要权限
    if(to.meta.requireAuth) {
      
      store.dispatch('getUserMenuName').then(access => {
        if (checkAuth(access, to.name)) {
          next();
        } else {
          // 无权限页面
          next({replace: true, name: 'error-403'})
        }
      })
    } else {
      next();
    }
    // 启动进度条
    NProgress.start();
  } else {
    // 没有登录，跳转登录页面
    if(process.env.VUE_APP_TITLE === "development") {
      // location.href = `http://crm.dev.hqjy.com/#/login`
    } else if(process.env.NODE_ENV === "testing") {
      location.href = `http://crm.hqbis.com:18080/#/login`
    } else if(process.env.NODE_ENV === "production") {
      location.href = `http://crm.hqjy.com/#/login`
    }
  }
});

router.afterEach(() => {
  // 关闭进度条
  NProgress.done();
});

export default router;
