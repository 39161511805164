/*
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-08-02 16:14:34
 * @LastEditors: Seven
 * @LastEditTime: 2021-08-02 19:34:28
 */
import Vue from "vue";
import Checker from "./Checker";
import { formatTimes } from "./timeDate";
import dayjs from 'dayjs'

/**
 * @description: 过滤
 * @param {*} order 过滤名称 value1 数据属性名称 value2 参数
 * @return {*}
 * @author: huliekao
 */
Vue.filter("convert", (value1, value2) => {
  return value2[value1] || "-";
});

/**
 * @description: 时间过滤
 * @param {*} order 过滤名称 value1 数据属性名称 value2 参数
 * @return {*}
 * @author: huliekao
 */
 Vue.filter("formatDate", (value, value2 = 'YYYY-MM-DD HH:mm:ss') => {
  let formatVal = value ? formatTimes(value, value2) : "";
  return formatVal;
});
/**
 * 格式化为小数点保留2位
 */
Vue.filter('decimal2', val => {
  if(/--|_/.test(val)) return val
  if(isNaN(+val)) return '0.00'
  return (+val).toFixed(2)
})
/**
 * 空数据展示
 */
Vue.filter('empty', (val, defalutStr = '--') => {
  if(Checker.isUndefined(val) || Checker.isNull(val) || val === '' || val === 'undefined' || val === 'null') return defalutStr
  return val
})
Vue.filter("formatDate2", (value, schema = 'YYYY-MM-DD HH:mm:ss') => {
  return dayjs(value).format(schema)
});