<!--
 * @Description: 添加联系方式
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-07-02 11:41:17
 * @LastEditors: Seven
 * @LastEditTime: 2022-04-18 10:37:56
-->
<template>
  <span>
    <template v-for="(item, index) in contactList">
      <!-- <el-input :value="item.detail" :key="index" /> -->
        <!-- :value="loginUser.roleList[0].roleCode === 'ADMIN' || loginUser.trackType === 2?item.seeShow && (item.type !== 0 || item.type !== 2 || item.type !== 1 || item.type !== 4)?item.detail:$getInputreset(item.detail):item.detail" -->
        <!-- :value="loginUser.roleList[0].roleCode === 'ADMIN'?item.detail:$getInputreset(item.detail)"  -->
      <el-input 
        ref="Entry"
        :value="loginUser.roleList[0].roleCode === 'SUPER_ADMIN' || item.seeShow ? item.detail : $getInputreset(item.detail)"
        style="width: 220px;margin: 0 10px 10px 0" 
        size="small" 
        :readonly="item.isSave"
        :key="index"
        @input="onInput($event, item)">
        <span slot="prepend" class="phoneClass">
          <!-- <el-tooltip v-if="item.type === 0 || item.type === 4" transfer placement="top" content="点击外呼">
            <i  color="green" class="icon_style" size="18" :class="typeContect(item.type)" @click="callout(item)"></i>
          </el-tooltip> -->
          <i color="green" class="icon_style" size="18" :class="typeContect(item.type)" @click="callout(item)"></i>
        </span>
        <span slot="append" v-if="item.isSave">
          <i size="18" class="icon_style el-icon-edit-outline" @click="toSave(item, index)"></i>
          <i size="18" class="icon_style el-icon-delete" @click="toDelete(item, index)"></i>
          <i :class="['icon_style iconfont', item.seeShow?'icon-Eyeblind':'icon-eye']" @click="seeShowClick(item, index)" size="18" :type="item.seeShow?'eye-disabled':'eye'"></i>
        </span>
        <span slot="append" v-else>
          <el-button @click="saveClick(item, index)">保存</el-button>
        </span>
      </el-input>
    </template>
    <el-tooltip transfer placement="top" content="添加联系方式">
      <el-button v-if="perm" @click="modal = true" type="text" icon="el-icon-circle-plus-outline"></el-button>
    </el-tooltip>
    <HqModal
      :visible="modal"
      title="添加联系方式"
      @on-cancel="cancel">
      <el-form ref="form" :model="form" :rules="contactRules">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item prop="type">
              <v-select
                v-model="form.type"
                :items="typeList"
                :menu-props="{ bottom: true, offsetY: true }"
                label="联系方式"
                item-text="name"
                item-value="id"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
                clear-icon="$clear"
                hide-details="auto"
              ></v-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="detail">
              <v-text-field
                v-model="form.detail"
                label="内容"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
              ></v-text-field>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="addContact" type="primary">确认</el-button>
      </div>
    </HqModal>
  </span>
</template>
<script>
import { 
  doAddContact, 
  getJudgetLookContack, 
  doOperationSave, 
  doIsAllowUpdateOrDelete,
  doUpdateDetail,
  doDeleteDetail,
  doSeePhone,
} from "api/customer";
import {
  reqLogincall
} from "api/call/tinet-api";
import Socket from '@/utils/webSocket'
import ValidateType from "@/utils/validate";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "contact",
  props: {
    method: {
      type: Function,
    },
    perm: {
      type: Boolean,
    }
  },
  data() {
    return {
      modal: false,
      typeList: [
        {name: "手机", id: "0"},
        {name: "微信", id: "1"},
        {name: "QQ", id: "2"},
        // {name: "邮箱", id: "3"},
        {name: "座机", id: "4"}
      ],
      form: {
        type: "",
        detail: ""
      },
      contactRules: {
        type: [
          {required: true, message: '请填写联系方式', trigger: 'change'}
        ],
        detail: [
          {required: true, validator: this.typevalidate, trigger: 'change'}
        ]
      },
      contactList: [],
      isSave: true,
      oldDetail: null,
      customerDetail: {}
    }
  },
  computed: {
    ...mapGetters(['loginUser']),
  },
  methods: {
    ...mapActions([
      "actionTinetCallOutAndGoto",
    ]),
    // 一键外呼
    async callout(v) {
      console.log('打电话按钮', v);
      if(v.type == 0) {
        this.doCallClick(v)
      }
      // const params = {
      //   detail: v.phone,
      //   customerId: v.customerId,
      //   operationKey: 'callRecord',
      //   operationType: 2
      // }
      
      // try {
      //   let res = await doSeePhone(v.customerId, v.contactId)
      //   if(res.code === 0) {
      //     this.doRecord(v, res.result)
      //     // await doOperationSave(params) 
      //   } 
      // } catch (error) {
      //   console.log(error);
      // }
    },
    doCallClick(val) {
      let paramTel = {
          toPhone: val.detail,
          customerId: val.customerId
        }
      this.getOrangePhonezthl(paramTel)
    },
    // 众投外呼请求
    getOrangePhonezthl(params) {
       try {
          reqLogincall(params).then(res => {
            console.log('进入了众投外呼判断了', res);
            if (res.code != 0) {
              this.$message.error(res.msg)
            } else {
              let _this = this
              new Socket({
                // soket服务地址
                socketUrl: 'wss://crm1.zthltx.com:2333/websocket/',
                extnumber: params.toPhone,
                // 回调data
                soketCallback: (data) => {
                  console.log('data00000000', data);
                  this.soketCallbacktel(data)
                  // _this.$store.commit('setTinetCallStatuszthl')
                }
              })
            }
          })
        } catch (erroe) {

        }
    },
    soketCallbacktel(data) {
      let statusType = data.data.status
      console.log('statusType', statusType, data.data);
      console.log('soket回调', data);
      switch (statusType) {
        case "idle": // 空闲
          this.$store.commit('setTinetCallStatuszthl', 'idle')
          this.$store.commit('setTinetLoginStatus', {
            status: 'idle',
            type: 'success',
            show: '空闲'
          })
          break;
        case "offline": // 离线
          this.$store.commit('setTinetCallStatuszthl', 'offline')
          this.$store.commit('setTinetLoginStatus', {
            status: 'offline',
            type: 'primary',
            show: '离线'
          })
          break;
        case "hangup": // 挂断
          console.log('this', this);
          this.$store.commit('setTinetCallStatuszthl', 'hangup')
          this.$store.commit('setTinetLoginStatus', {
            status: 'hangup',
            type: 'success',
            show: '空闲'
          })
          break;
        case "answer": // 通话中
          this.$store.commit('setTinetCallStatuszthl', 'answer')
          this.$store.commit('setTinetLoginStatus', {
            status: 'answer',
            type: 'primary',
            show: '通话中'
          })
          break;
        case "ring": // 响铃
          this.$store.commit('setTinetCallStatuszthl', 'ring')
          this.$store.commit('setTinetLoginStatus', {
            status: 'ring',
            type: 'primary',
            show: '响铃中'
          })
          break;
        default:
          console.log('进入到回调', that.callback == 'function');
      }
    },

    doRecord(v, result) {
      const telList = {
        tel: result,
        doCallType: '0'
      }
      this.$store.dispatch('actionTinetCallOut', {tel: telList, customerId: v.customerId})
    },
    onInput(value, item){
      item.detail = value
      this.$forceUpdate();
    },
    init(detail) {
      console.log('this.loginUser', this.loginUser);
      detail.contactList?.map(item => {
        item.isSave = true
        if(item.type == 0 || item.type == 4 || item.type == 2 || item.type == 1) {
          item.seeShow = false
        } else {
          item.seeShow = true
        }
      })
      this.customerDetail.customerId = detail.customerId
      this.customerDetail.trackType = detail.contactList[0]?.trackType
      this.contactList = detail.contactList
    },
    typeContect (type) {
      switch (type) {
        case 0:
          return 'el-icon-phone'
        case 1:
          return 'iconfont icon-weixin'
        case 2:
          return 'iconfont icon-qq'
        case 3:
          return 'el-icon-phone'
          // return 'iconfont icon-185078emailmailstreamline'
        case 4:
          return 'el-icon-phone'
      }
    },
    // 用户操作记录
    seeShowClick(v, index) {
      let _this = this
      console.log('v',v);
      if(!v.seeShow) {
        getJudgetLookContack(this.loginUser.userId).then(async data => {
          if(data.result) {
            const params = {
              detail: v.detail,
              customerId: v.customerId,
              operationKey: 'getDetailByCustomerId'
            }
            if (v.type == 0) {
              params.operationType = 1
            } else if (v.type == 1) {
              params.operationType = 8
            } else if (v.type == 2) {
              params.operationType = 9
            } else if (v.type == 4) {
              params.operationType = 7
            }
            await doOperationSave(params)
            _this.contactList.map((item, i) => {
              if(v.type == 0 || v.type == 4 || v.type == 1 || v.type == 2) {
                if(i == index) {
                  item.seeShow = !item.seeShow
                }
              }
            })
            this.$forceUpdate()
            this.$copyText(v.detail).then(function () {
              _this.$message.success("已复制号码")
            }, function () {
              _this.$message.error("抱歉，复制号码失败")
            })
          } else {
            _this.contactList.map((item, i) => {
              if(v.type == 0 || v.type == 4 || v.type == 1 || v.type == 2) {
                if(i == index) {
                  item.seeShow = false
                }
              }
            })
            this.$forceUpdate()
            _this.$message.error("抱歉，复制号码失败")
          }
        })
      } else {
        _this.contactList.map((item, i) => {
          if(v.type == 0 || v.type == 4 || v.type == 1 || v.type == 2) {
            if(i == index) {
              item.seeShow = !item.seeShow
            }
          }
        })
        this.$forceUpdate()
      }
    },
    // 点击编辑
    async toSave(item, index) {
      this.oldDetail = item.detail

      // 手机和电话才需要判断
      if(item.type === 0 || item.type === 4) {
        // 判断是否可以操作
        const data = await doIsAllowUpdateOrDelete(item)
        if(data.code === 0) {
          this.contactList.map((v, i) => {
            if(i == index) {
              v.seeShow = !v.seeShow
              v.isSave = !v.isSave
            }
          })
        } else {
          this.$message.info(data.msg)
        }
      } else {
        this.contactList.map((v, i) => {
          if(i == index) {
            v.isSave = !v.isSave
          }
        })
      }
      this.$forceUpdate()
    },
    // 保存编辑联系方式
    async saveClick(item, index) {
      let reg = null, reg2 = null
      switch (Number(item.type)) {
        case 0:
          // reg = /^$|^((13[0-9])|(14[0-9])|(15[^4,\D])|(16[0-9])|(17[0-9])|(18[0-9])|(19[0-9]))\d{8}$/
          reg = /^1[3-9]\d{9}$/
          if(!reg.test(this.$refs.Entry[index].value)) {
            this.$message.error('手机号码格式不正确！')
            return false
          }
          break
        case 2:
          reg = /^[1-9][0-9]{4,11}$/gim
          if(!reg.test(this.$refs.Entry[index].value)) {
            this.$message.error('QQ格式不正确！')
            return false
          }
          break
        // case 3:
        //   reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/
        //   if(!reg.test(this.$refs.Entry[index].value)) {
        //     this.$message.info('邮箱格式不正确！')
        //     return false
        //   }
        //   break
        case 3:
          reg = /^(([0+]\d{2,3}-)?(0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/;
          reg2 = /^(\d{7,8})(-(\d{3,}))?$/;
          if(!reg.test(this.$refs.Entry[index].value) && !reg2.test(this.$refs.Entry[index].value)) {
            this.$message.error('座机格式不正确！')
            return false
          }
          break
      }
      this.contactList.map((v, i) => {
        if(item.type == 0 || item.type == 4) {
          if(i == index) {
            v.seeShow = !v.seeShow
          }
        }
        if(i == index) {
          v.isSave = !v.isSave
        }
      })
      item.updateId = this.$store.state.user.loginUser.userId
      item.detail = this.$refs.Entry[index].value
      item.oldDetail = this.oldDetail
    
      const data = await doUpdateDetail(item)
      if(data.code === 0) {
        this.$message.success(data.msg)
        this.method()
      } else {
        this.$message.error(data.msg)
        item.detail = this.oldDetail
      }
      this.$forceUpdate()
    },
    // 删除
    async toDelete(item) {
      // 如果联系方式只有一个则不可以删除
      if(this.contactList.length === 1) {
        this.$message.info('唯一联系方式不允许删除')
        return false
      }
      // 判断是否可以操作
      const data = await doIsAllowUpdateOrDelete(item)
      if(data.code === -1) {
        this.$message.info(data.msg)
        return false
      } else {
        this.$hqMessageBox({
          title: '提示',
          message: '确定删除该联系方式吗？',
        })
        .then(async () => {
          const res = await doDeleteDetail(item)
          if(res.code === 0) {
            this.$message.success(data.msg)
            setTimeout(() => {
              // 调用父组件方法更新子组件
              this.method()
            }, 5)
          }
        })
      }
    },
    typevalidate (rule, value, callback) {
      switch (this.form.type) {
        case '0':
          ValidateType.validatePhone(rule, value, callback)
          break
        case '1':
          ValidateType.validateweiXin(rule, value, callback)
          break
        case '2':
          ValidateType.validateQQ(rule, value, callback)
          break
        case '3':
          ValidateType.validateEmail(rule, value, callback)
          break
        case '4':
          ValidateType.validateTel(rule, value, callback)
          break
      }
    },
    addContact () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.addloading = true
          this.form.customerId = this.customerDetail.customerId
          this.form.trackType = this .customerDetail.trackType
          doAddContact(this.form).then((data) => {
            const { code, msg } = data
            if (code === 0) {
              this.addloading = false
              this.$message.success(msg)
              this.cancel()
              // 调用父组件方法更新子组件
              this.method()
            } else {
              this.addloading = false
              this.$message.error(msg)
            }
          }).catch(() => {
            this.addloading = false
          })
        }
      })
    },
    cancel() {
      this.modal = false
      this.form = {
        type: "",
        detail: ""
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .phone {
    margin-left: 10px;
    i {
      margin-right: 4px;
    }
  }
  // .phoneClass {
  //   background-color: rgb(204, 230, 255);
  //   color: rgb(51, 122, 255);
  // }
  ::v-deep .el-input {
    >:first-child {
      background-color: rgb(204, 230, 255);
      color: rgb(51, 122, 255);
    }
    .el-input-group__append, .el-input-group__prepend {
      padding: 0 4px;

      .el-button {
        padding: 0 20px;
      }
    }

    .icon_style {
      cursor:pointer;
      width: 12px;
      margin: 0 3px
    }
  }
</style>
