var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-autocomplete", {
    attrs: {
      items: _vm.items,
      "menu-props": { bottom: true, offsetY: true },
      label: _vm.label,
      outlined: "",
      "item-text": _vm.itemText,
      "item-value": _vm.itemValue,
      dense: true,
      height: 32,
      clearable: true,
      "clear-icon": "$clear",
      "hide-details": "auto",
      "no-data-text": "暂无数据",
      multiple: ""
    },
    on: { change: _vm.selectChange },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function(ref) {
          var item = ref.item
          var index = ref.index
          return [
            index === 0
              ? _c("span", { staticClass: "showtext" }, [
                  _vm._v(_vm._s(item[_vm.itemText]))
                ])
              : _vm._e(),
            index === 1
              ? _c("span", { staticClass: "grey--text text-caption" }, [
                  _vm._v(" (+" + _vm._s(_vm.select.length - 1) + ") ")
                ])
              : _vm._e()
          ]
        }
      },
      {
        key: "prepend-item",
        fn: function() {
          return [
            _c(
              "v-list-item",
              { attrs: { ripple: "" }, on: { click: _vm.toggle } },
              [
                _c(
                  "v-list-item-action",
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          color: _vm.select.length > 0 ? "indigo darken-4" : ""
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.select.length === _vm.items.length
                                ? "mdi-close-box"
                                : _vm.select.length > 0 &&
                                  !(_vm.select.length === _vm.items.length)
                                ? "mdi-minus-box"
                                : "mdi-checkbox-blank-outline"
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "v-list-item-content",
                  [_c("v-list-item-title", [_vm._v(" 全选 ")])],
                  1
                )
              ],
              1
            ),
            _c("v-divider", { staticClass: "mt-2" })
          ]
        },
        proxy: true
      }
    ]),
    model: {
      value: _vm.select,
      callback: function($$v) {
        _vm.select = $$v
      },
      expression: "select"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }