/*
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-07-15 20:07:16
 * @LastEditors: Seven
 * @LastEditTime: 2021-07-15 20:15:23
 */
import store from '../store'
class Permissions {
  static checkPerm (perm) {
    for (let item of store.state.user.userPerm) {
      if (item === perm) {
        return true
      }
    }
    return false
  }
}
export default Permissions
