var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    [
      _c(
        "el-col",
        { attrs: { xs: 24, sm: 10 } },
        [
          _c("el-card", [
            _c("p", { attrs: { slot: "header" }, slot: "header" }, [
              _vm._v("标签选择")
            ]),
            _c("div", { staticClass: "main_label" }, [
              _vm.tabContents.length
                ? _c(
                    "div",
                    { staticClass: "main_box" },
                    _vm._l(_vm.navList, function(item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "li",
                          class: { active: index == _vm.num },
                          on: {
                            click: function($event) {
                              return _vm.tabClick(index)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item) + " ")]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.tabContents.length
                ? _c(
                    "div",
                    { staticClass: "tabCon" },
                    [
                      _vm._l(_vm.tabContents[0], function(i, index) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: 0 == _vm.num,
                                expression: "0 == num"
                              }
                            ],
                            key: index
                          },
                          [
                            _c("li", { staticClass: "tab_com_li" }, [
                              _vm._v(_vm._s(i.groupName))
                            ]),
                            i.labelList
                              ? _c(
                                  "div",
                                  { staticClass: "tab_com_tag" },
                                  [
                                    _vm._l(i.labelList, function(v) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            key: v.sysLabelId,
                                            staticClass: "tag_list",
                                            style: {
                                              backgroundColor:
                                                "" + (v.used ? "#19be6b" : ""),
                                              color: "" + (v.used ? "#fff" : "")
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.tagListClick(v)
                                              }
                                            }
                                          },
                                          [_vm._v(_vm._s(v.labelName))]
                                        )
                                      ]
                                    })
                                  ],
                                  2
                                )
                              : _vm._e(),
                            !i.labelList
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      "line-height": "40px"
                                    }
                                  },
                                  [_vm._v(" 暂无标签 ")]
                                )
                              : _vm._e()
                          ]
                        )
                      }),
                      _vm._l(_vm.tabContents[_vm.num], function(item, index) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: 0 != _vm.num,
                                expression: "0 != num"
                              }
                            ],
                            key: index + item.groupName
                          },
                          [
                            _c("li", { staticClass: "tab_com_li" }, [
                              _vm._v(_vm._s(item.groupName))
                            ]),
                            item.labelList
                              ? _c(
                                  "div",
                                  { staticClass: "tab_com_tag" },
                                  [
                                    _vm._l(item.labelList, function(v) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            key: v.labelId,
                                            staticClass: "tag_list",
                                            style: {
                                              backgroundColor:
                                                "" + (v.used ? "#19be6b" : ""),
                                              color: "" + (v.used ? "#fff" : "")
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.tagListClick(v)
                                              }
                                            }
                                          },
                                          [_vm._v(_vm._s(v.labelName))]
                                        )
                                      ]
                                    })
                                  ],
                                  2
                                )
                              : _vm._e(),
                            !item.labelList
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      "line-height": "40px"
                                    }
                                  },
                                  [_vm._v(" 暂无标签 ")]
                                )
                              : _vm._e()
                          ]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e(),
              _vm.tabContents.length === 0
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        "line-height": "300px",
                        "text-align": "center"
                      }
                    },
                    [_vm._v(" 暂无数据 ")]
                  )
                : _vm._e()
            ])
          ])
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { xs: 24, sm: 14 } },
        [
          _c(
            "el-card",
            [
              _c("p", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("话术推荐")
              ]),
              _c("span", [_vm._v("选中标签：")]),
              _vm._l(_vm.selectLabelList, function(item) {
                return _c(
                  "el-tag",
                  {
                    key: item.id + item.name,
                    ref: "Tag",
                    refInFor: true,
                    staticStyle: { color: "#fff", margin: "0 3px 6px 0" },
                    attrs: { size: "small", color: "#47cb89", closable: "" },
                    on: {
                      close: function($event) {
                        return _vm.handleClose(item)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(item.name) + " ")]
                )
              }),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "right", "margin-bottom": "6px" }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.searchContent }
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "danger" },
                      on: { click: _vm.reset }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              ),
              _vm.contentList.length
                ? _c(
                    "div",
                    { staticStyle: { height: "455px", "overflow-y": "auto" } },
                    _vm._l(_vm.contentList, function(item) {
                      return _c(
                        "el-card",
                        { key: item.markId, staticClass: "content_box" },
                        [
                          _vm._l(item.labelList, function(v, index) {
                            return _c("el-tag", { key: index }, [
                              _vm._v(_vm._s(v))
                            ])
                          }),
                          _c("div", {
                            staticClass: "content",
                            domProps: {
                              innerHTML: _vm._s(item.verbalTrickContent)
                            }
                          })
                        ],
                        2
                      )
                    }),
                    1
                  )
                : _vm._e(),
              !_vm.contentList.length
                ? _c(
                    "el-card",
                    {
                      staticStyle: {
                        height: "455px",
                        "line-height": "200px",
                        "text-align": "center"
                      }
                    },
                    [_vm._v(" 暂无数据 ")]
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }