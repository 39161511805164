var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "HqDrawer",
    {
      ref: "drawer",
      style: { "pointer-events": _vm.code ? "none" : "auto" },
      attrs: {
        title: "商品详情",
        visible: _vm.drawer,
        showHeader: false,
        size: "86%",
        modal: !!!_vm.code,
        wrapperClosable: !!!_vm.code
      },
      on: {
        "update:visible": function($event) {
          _vm.drawer = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "detail" },
        [
          _c("div", { staticStyle: { background: "#fff" } }, [
            _c("div", { staticClass: "contact-box" }, [
              _c(
                "div",
                { staticClass: "contact" },
                [
                  _c("span", { staticClass: "user" }, [
                    _vm._v(_vm._s(_vm.customerDetail.name))
                  ]),
                  _c("span", { staticClass: "own" }, [
                    _vm._v(
                      "归属人：" +
                        _vm._s(
                          _vm.customerDetail.userName
                            ? _vm.customerDetail.userName
                            : "无"
                        )
                    )
                  ]),
                  _c("Contact", {
                    ref: "contact",
                    attrs: { method: _vm.getCustomerData, perm: _vm.perm }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "text-align": "right", flex: "1" } },
                [
                  _vm.cansinUp && _vm.isPublic
                    ? _c(
                        "el-button",
                        {
                          staticClass: "hq-button",
                          staticStyle: { "margin-bottom": "6px" },
                          attrs: { type: "primary", icon: "el-icon-s-custom" },
                          on: { click: _vm.goSignUpPage }
                        },
                        [_vm._v("报名")]
                      )
                    : _vm._e(),
                  _vm.perm &&
                  _vm.customerDetail.userName &&
                  _vm.permUtil.WbCusotmer.customerInvalid()
                    ? _c("Invalid", {
                        attrs: {
                          currCol: _vm.customerDetail,
                          detailInit: _vm.init,
                          method: _vm.method
                        }
                      })
                    : _vm._e(),
                  !_vm.customerDetail.userName &&
                  _vm.permUtil.WbCusotmer.higseaReceive()
                    ? _c(
                        "el-button",
                        {
                          staticClass: "hq-button",
                          staticStyle: { "margin-bottom": "6px" },
                          attrs: { type: "primary", icon: "el-icon-error" },
                          on: { click: _vm.reveice }
                        },
                        [_vm._v("领取")]
                      )
                    : _vm._e(),
                  _vm.perm &&
                  _vm.customerDetail.userName &&
                  _vm.permUtil.WbCusotmer.customerReturn()
                    ? _c(
                        "el-button",
                        {
                          staticClass: "hq-button",
                          attrs: { icon: "el-icon-error" },
                          on: { click: _vm.giveUp }
                        },
                        [_vm._v("放弃")]
                      )
                    : _vm._e(),
                  _vm.perm && _vm.permUtil.WbCusotmer.customerTransfer()
                    ? _c("Transfer", {
                        ref: "transfer",
                        attrs: { method: _vm.method, detailInit: _vm.init }
                      })
                    : _vm._e(),
                  _vm.perm &&
                  _vm.customerDetail.isReserve === 0 &&
                  _vm.permUtil.WbCusotmer.customerReserve() &&
                  _vm.isPublic
                    ? _c(
                        "el-button",
                        {
                          staticClass: "hq-button",
                          attrs: { type: "primary", icon: "el-icon-error" },
                          on: {
                            click: function($event) {
                              return _vm.reserve(1)
                            }
                          }
                        },
                        [_vm._v("移入留存")]
                      )
                    : _vm._e(),
                  _vm.perm &&
                  _vm.customerDetail.isReserve === 1 &&
                  _vm.permUtil.WbCusotmer.customerReserve() &&
                  _vm.isPublic
                    ? _c(
                        "el-button",
                        {
                          staticClass: "hq-button",
                          attrs: { type: "primary", icon: "el-icon-error" },
                          on: {
                            click: function($event) {
                              return _vm.reserve(0)
                            }
                          }
                        },
                        [_vm._v("移出留存")]
                      )
                    : _vm._e(),
                  _vm.perm &&
                  _vm.permUtil.WbCusotmer.customerAppoint() &&
                  _vm.isPublic
                    ? _c(
                        "el-button",
                        {
                          staticClass: "hq-button",
                          attrs: { type: "primary", icon: "el-icon-error" },
                          on: { click: _vm.appoint }
                        },
                        [_vm._v("预约")]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ]),
          _c(
            "el-row",
            {
              staticStyle: { width: "96%", margin: "0 auto" },
              attrs: { gutter: 12 }
            },
            [
              _c(
                "el-col",
                { attrs: { span: 14 } },
                [
                  _c(
                    "el-row",
                    { staticClass: "customer-detail" },
                    [
                      _c("el-col", { staticClass: "detail-line" }),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-card",
                            { staticClass: "customer-label" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "clearfix",
                                  attrs: { slot: "header" },
                                  slot: "header"
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: { display: "inline-block" }
                                    },
                                    [_vm._v("客户详情")]
                                  ),
                                  _c("add-label", {
                                    ref: "label",
                                    attrs: {
                                      perm: _vm.perm,
                                      method: _vm.method
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 8 } },
                                [
                                  _c("el-col", { attrs: { span: 5 } }, [
                                    _vm._v("赛道")
                                  ]),
                                  _c("el-col", { attrs: { span: 4 } }, [
                                    _vm._v("归属部门")
                                  ]),
                                  _c("el-col", { attrs: { span: 4 } }, [
                                    _vm._v("商机状态")
                                  ]),
                                  _c("el-col", { attrs: { span: 4 } }, [
                                    _vm._v("创建人")
                                  ]),
                                  _c("el-col", { attrs: { span: 6 } }, [
                                    _vm._v("创建时间")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                {
                                  staticClass: "detail-box",
                                  attrs: { gutter: 8 }
                                },
                                [
                                  _c("el-col", { attrs: { span: 5 } }, [
                                    _vm._v(
                                      _vm._s(_vm.customerDetail.trackTypeStr)
                                    )
                                  ]),
                                  _c("el-col", { attrs: { span: 4 } }, [
                                    _vm._v(_vm._s(_vm.customerDetail.deptName))
                                  ]),
                                  _c("el-col", { attrs: { span: 4 } }, [
                                    _vm._v(
                                      _vm._s(_vm.customerDetail.statusName)
                                    )
                                  ]),
                                  _c("el-col", { attrs: { span: 4 } }, [
                                    _vm._v(
                                      _vm._s(_vm.customerDetail.createName)
                                    )
                                  ]),
                                  _c("el-col", { attrs: { span: 6 } }, [
                                    _vm._v(
                                      _vm._s(_vm.customerDetail.createTime)
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 8 } },
                                [
                                  _c("el-col", { attrs: { span: 5 } }, [
                                    _vm._v("上次联系")
                                  ]),
                                  _c("el-col", { attrs: { span: 4 } }, [
                                    _vm._v("商机来源")
                                  ]),
                                  _c("el-col", { attrs: { span: 4 } }, [
                                    _vm._v("跟进次数")
                                  ]),
                                  _c("el-col", { attrs: { span: 4 } }, [
                                    _vm._v("渠道名称")
                                  ]),
                                  _c("el-col", { attrs: { span: 6 } }, [
                                    _vm._v("渠道类别")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                {
                                  staticClass: "detail-box",
                                  attrs: { gutter: 8 }
                                },
                                [
                                  _c("el-col", { attrs: { span: 5 } }, [
                                    _vm._v(
                                      _vm._s(_vm.customerDetail.followTime)
                                    )
                                  ]),
                                  _c("el-col", { attrs: { span: 4 } }, [
                                    _vm._v(
                                      _vm._s(_vm.customerDetail.sourceName)
                                    )
                                  ]),
                                  _c("el-col", { attrs: { span: 4 } }, [
                                    _vm._v(
                                      _vm._s(_vm.customerDetail.followNumber)
                                    )
                                  ]),
                                  _c("el-col", { attrs: { span: 4 } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.customerDetail.recruitChannelsName
                                          ? _vm.customerDetail
                                              .recruitChannelsName
                                          : "渠道丢失"
                                      )
                                    )
                                  ]),
                                  _c("el-col", { attrs: { span: 6 } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.customerDetail.channelsTypeName
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("el-col", { staticClass: "detail-line" }),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-card",
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "clearfix",
                                  attrs: { slot: "header" },
                                  slot: "header"
                                },
                                [
                                  _c("span", [_vm._v("基本信息")]),
                                  _vm.permUtil.WbCusotmer.customerUpdate() &&
                                  _vm.perm
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "hq-button",
                                          staticStyle: {
                                            float: "right",
                                            "margin-top": "8px"
                                          },
                                          attrs: {
                                            type: "primary",
                                            disabled: _vm.status === 4
                                          },
                                          on: { click: _vm.updateClick }
                                        },
                                        [_vm._v("更新")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                {
                                  staticClass: "base-detail",
                                  attrs: { gutter: 12 }
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "学员姓名",
                                          "hide-details": "auto",
                                          outlined: "",
                                          dense: true,
                                          height: 32,
                                          clearable: true
                                        },
                                        model: {
                                          value: _vm.contactForm.name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contactForm,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "contactForm.name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c("dict-select", {
                                        attrs: { code: "SEX" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function(props) {
                                              return _c("v-select", {
                                                attrs: {
                                                  items: props.dictData,
                                                  "menu-props": {
                                                    bottom: true,
                                                    offsetY: true
                                                  },
                                                  label: "性别",
                                                  "item-text": "label",
                                                  "item-value": "value",
                                                  outlined: "",
                                                  dense: true,
                                                  height: 32,
                                                  clearable: true,
                                                  "clear-icon": "$clear",
                                                  "hide-details": "auto"
                                                },
                                                model: {
                                                  value: _vm.contactForm.sex,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.contactForm,
                                                      "sex",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "contactForm.sex"
                                                }
                                              })
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c("dict-select", {
                                        attrs: { code: "EDUCATION_TYPE" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function(props) {
                                              return _c("v-select", {
                                                attrs: {
                                                  items: props.dictData,
                                                  "menu-props": {
                                                    bottom: true,
                                                    offsetY: true
                                                  },
                                                  label: "学历",
                                                  "item-text": "label",
                                                  "item-value": "value",
                                                  outlined: "",
                                                  dense: true,
                                                  height: 32,
                                                  clearable: true,
                                                  "clear-icon": "$clear",
                                                  "hide-details": "auto"
                                                },
                                                model: {
                                                  value:
                                                    _vm.contactForm.education,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.contactForm,
                                                      "education",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contactForm.education"
                                                }
                                              })
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "客户编号",
                                          "hide-details": "auto",
                                          outlined: "",
                                          dense: true,
                                          height: 32,
                                          disabled: "",
                                          clearable: true
                                        },
                                        model: {
                                          value: _vm.contactForm.customerId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contactForm,
                                              "customerId",
                                              $$v
                                            )
                                          },
                                          expression: "contactForm.customerId"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "NCID",
                                          "hide-details": "auto",
                                          outlined: "",
                                          dense: true,
                                          height: 32,
                                          disabled: "",
                                          clearable: true
                                        },
                                        model: {
                                          value: _vm.contactForm.ncId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contactForm,
                                              "ncId",
                                              $$v
                                            )
                                          },
                                          expression: "contactForm.ncId"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "地址",
                                          "hide-details": "auto",
                                          outlined: "",
                                          dense: true,
                                          clearable: true,
                                          height: 32
                                        },
                                        model: {
                                          value: _vm.contactForm.address,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contactForm,
                                              "address",
                                              $$v
                                            )
                                          },
                                          expression: "contactForm.address"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "老学员号码",
                                          "hide-details": "auto",
                                          outlined: "",
                                          dense: true,
                                          height: 32,
                                          clearable: true
                                        },
                                        model: {
                                          value:
                                            _vm.contactForm.oldStudentPhone,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contactForm,
                                              "oldStudentPhone",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "contactForm.oldStudentPhone"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "URL",
                                          "hide-details": "auto",
                                          outlined: "",
                                          dense: true,
                                          clearable: true,
                                          height: 32
                                        },
                                        model: {
                                          value: _vm.contactForm.url,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contactForm,
                                              "url",
                                              $$v
                                            )
                                          },
                                          expression: "contactForm.url"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c("CheckBox", {
                                        ref: "check",
                                        attrs: {
                                          label: "QQ验证状态",
                                          list: _vm.checkList,
                                          select: _vm.contactForm.verification
                                        },
                                        on: {
                                          "update:select": function($event) {
                                            return _vm.$set(
                                              _vm.contactForm,
                                              "verification",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c("CheckBox", {
                                        ref: "checkWX",
                                        attrs: {
                                          label: "微信验证状态",
                                          list: _vm.checkList,
                                          select: _vm.contactForm.wxVerification
                                        },
                                        on: {
                                          "update:select": function($event) {
                                            return _vm.$set(
                                              _vm.contactForm,
                                              "wxVerification",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          outlined: "",
                                          height: 80,
                                          name: "input-7-4",
                                          label: "备注",
                                          value: ""
                                        },
                                        model: {
                                          value: _vm.contactForm.memo,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contactForm,
                                              "memo",
                                              $$v
                                            )
                                          },
                                          expression: "contactForm.memo"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("el-col", { staticClass: "detail-line" }),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [_c("DetailList", { ref: "detailList" })],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { staticClass: "detail-line" }),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-card",
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "clearfix",
                                  attrs: { slot: "header" },
                                  slot: "header"
                                },
                                [
                                  _c("span", [_vm._v("跟进记录")]),
                                  _vm.perm &&
                                  _vm.permUtil.WbCusotmer.customerFollow()
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "hq-button",
                                          staticStyle: {
                                            float: "right",
                                            "margin-top": "8px"
                                          },
                                          attrs: {
                                            disabled: _vm.status === 4,
                                            type: "primary"
                                          },
                                          on: { click: _vm.addFollow }
                                        },
                                        [_vm._v("发表")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "el-form",
                                {
                                  ref: "form",
                                  attrs: {
                                    model: _vm.form,
                                    rules: _vm.formRules
                                  }
                                },
                                [
                                  _c(
                                    "el-row",
                                    {
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: { gutter: 12 }
                                    },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "contactType" } },
                                            [
                                              _c("dict-select", {
                                                attrs: { code: "COMM_WAY" },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "default",
                                                    fn: function(props) {
                                                      return _c("v-select", {
                                                        attrs: {
                                                          items: props.dictData,
                                                          "menu-props": {
                                                            bottom: true,
                                                            offsetY: true
                                                          },
                                                          label: "沟通方式",
                                                          "item-text": "label",
                                                          "item-value": "value",
                                                          outlined: "",
                                                          dense: true,
                                                          height: 32,
                                                          clearable: true,
                                                          "clear-icon":
                                                            "$clear",
                                                          "hide-details":
                                                            "auto",
                                                          disabled:
                                                            !_vm.customInfo
                                                              .userName &&
                                                            _vm.customInfo
                                                              .customerId &&
                                                            _vm.customInfo
                                                              .status !== ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form
                                                              .contactType,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "contactType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.contactType"
                                                        }
                                                      })
                                                    }
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "followStatus" } },
                                            [
                                              _c("dict-select", {
                                                attrs: {
                                                  code:
                                                    _vm.loginUser.trackType ===
                                                      1 ||
                                                    this.loginUser.trackType ===
                                                      3
                                                      ? "FOLLOW_STATUS_ACCOUNT"
                                                      : "FOLLOW_STATUS_SELF_EXAM"
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "default",
                                                    fn: function(props) {
                                                      return _c("v-select", {
                                                        attrs: {
                                                          items: props.dictData.filter(
                                                            function(res) {
                                                              return (
                                                                res.value !== 0
                                                              )
                                                            }
                                                          ),
                                                          "menu-props": {
                                                            bottom: true,
                                                            offsetY: true
                                                          },
                                                          label: "意向状态",
                                                          "item-text": "label",
                                                          "item-value": "value",
                                                          outlined: "",
                                                          dense: true,
                                                          height: 32,
                                                          clearable: true,
                                                          "clear-icon":
                                                            "$clear",
                                                          "hide-details":
                                                            "auto",
                                                          disabled:
                                                            !_vm.customInfo
                                                              .userName &&
                                                            _vm.customInfo
                                                              .customerId &&
                                                            _vm.customInfo
                                                              .status !== ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form
                                                              .followStatus,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "followStatus",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.followStatus"
                                                        }
                                                      })
                                                    }
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            [
                                              _c("TimeSelect", {
                                                ref: "time",
                                                attrs: {
                                                  disabled:
                                                    !_vm.customInfo.userName &&
                                                    _vm.customInfo.customerId &&
                                                    _vm.customInfo.status !==
                                                      "",
                                                  select: _vm.form.nextTime
                                                },
                                                on: {
                                                  "update:select": function(
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.form,
                                                      "nextTime",
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      ((_vm.loginUser.trackType === 1 ||
                                        _vm.loginUser.trackType === 3) &&
                                        _vm.form.followStatus === 4) ||
                                      (_vm.loginUser.trackType === 2 &&
                                        _vm.form.followStatus === 6)
                                        ? _c(
                                            "el-col",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop: "invalidType",
                                                    rules:
                                                      ((_vm.loginUser
                                                        .trackType === 1 ||
                                                        _vm.loginUser
                                                          .trackType === 3) &&
                                                        _vm.form
                                                          .followStatus ===
                                                          4) ||
                                                      (_vm.loginUser
                                                        .trackType === 2 &&
                                                        _vm.form
                                                          .followStatus === 6)
                                                        ? {
                                                            required: true,
                                                            message:
                                                              "请选择无效类型"
                                                          }
                                                        : []
                                                  }
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.invalidList,
                                                      "menu-props": {
                                                        bottom: true,
                                                        offsetY: true
                                                      },
                                                      label: "无效类型",
                                                      "item-text": "label",
                                                      "item-value": "value",
                                                      outlined: "",
                                                      disabled:
                                                        !_vm.customInfo
                                                          .userName &&
                                                        _vm.customInfo
                                                          .customerId &&
                                                        _vm.customInfo
                                                          .status !== "",
                                                      dense: true,
                                                      height: 32,
                                                      clearable: true,
                                                      "clear-icon": "$clear",
                                                      "hide-details": "auto"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.invalidType,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "invalidType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.invalidType"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "memo" } },
                                            [
                                              _c("v-textarea", {
                                                attrs: {
                                                  outlined: "",
                                                  height: 80,
                                                  name: "input-7-4",
                                                  label: "备注",
                                                  value: "",
                                                  disabled:
                                                    !_vm.customInfo.userName &&
                                                    _vm.customInfo.customerId &&
                                                    _vm.customInfo.status !== ""
                                                },
                                                model: {
                                                  value: _vm.form.memo,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "memo",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.memo"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("el-col", { staticClass: "detail-line" }),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c("el-card", [
                            _c(
                              "div",
                              { attrs: { slot: "header" }, slot: "header" },
                              [_c("span", [_vm._v("所有动态")])]
                            ),
                            _vm.operationList.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "time-line" },
                                  [
                                    _c(
                                      "el-timeline",
                                      { attrs: { reverse: _vm.reverse } },
                                      _vm._l(_vm.operationList, function(item) {
                                        return _c(
                                          "el-timeline-item",
                                          {
                                            key: item.id,
                                            attrs: { color: "#337AFF" }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "follow-edit" },
                                              [
                                                _c("h4", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.operationTypeStr
                                                    )
                                                  )
                                                ]),
                                                item.operationType === 4 &&
                                                item.createId ==
                                                  _vm.loginUser.userId &&
                                                item.edit
                                                  ? _c("i", {
                                                      staticClass:
                                                        "el-icon-edit-outline",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openEdit(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    })
                                                  : _vm._e()
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticStyle: { color: "#999" }
                                              },
                                              [
                                                _vm._v(
                                                  "操作人：" +
                                                    _vm._s(item.createName) +
                                                    "  " +
                                                    _vm._s(item.createTime)
                                                )
                                              ]
                                            ),
                                            item.operationType !== 2
                                              ? _c("p", {
                                                  domProps: {
                                                    innerHTML: _vm._s(item.memo)
                                                  }
                                                })
                                              : _vm._e(),
                                            item.operationType === 2 &&
                                            !!item.memo
                                              ? _c("p", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.memo.split("<")[0]
                                                    )
                                                  )
                                                ])
                                              : _vm._e(),
                                            item.operationType === 2 && item.url
                                              ? _c("audio", {
                                                  attrs: {
                                                    src: item.url,
                                                    controls: "controls"
                                                  }
                                                })
                                              : _vm._e()
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "line-height": "120px",
                                      "text-align": "center"
                                    }
                                  },
                                  [_vm._v("暂无动态")]
                                )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("Appoint", {
        ref: "appoint",
        attrs: { method: _vm.method, detailInit: _vm.init }
      }),
      _c(
        "HqModal",
        {
          attrs: { visible: _vm.editModel, title: "修改跟进记录" },
          on: { "on-cancel": _vm.cancel }
        },
        [
          _c(
            "el-form",
            {
              ref: "editCustomer",
              attrs: { model: _vm.editCustomer, rules: _vm.editruleValidate }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "contactType" } },
                        [
                          _c("dict-select", {
                            attrs: { code: "COMM_WAY" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(props) {
                                  return _c("v-select", {
                                    attrs: {
                                      items: props.dictData,
                                      "menu-props": {
                                        bottom: true,
                                        offsetY: true
                                      },
                                      label: "沟通方式",
                                      "item-text": "label",
                                      "item-value": "value",
                                      outlined: "",
                                      dense: true,
                                      height: 32,
                                      clearable: true,
                                      "clear-icon": "$clear",
                                      "hide-details": "auto"
                                    },
                                    model: {
                                      value: _vm.editCustomer.contactType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editCustomer,
                                          "contactType",
                                          $$v
                                        )
                                      },
                                      expression: "editCustomer.contactType"
                                    }
                                  })
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "followStatus" } },
                        [
                          _c("dict-select", {
                            attrs: {
                              code:
                                _vm.loginUser.trackType === 1 ||
                                this.loginUser.trackType === 3
                                  ? "FOLLOW_STATUS_ACCOUNT"
                                  : "FOLLOW_STATUS_SELF_EXAM"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(props) {
                                  return _c("v-select", {
                                    attrs: {
                                      items: props.dictData,
                                      "menu-props": {
                                        bottom: true,
                                        offsetY: true
                                      },
                                      label: "意向状态",
                                      "item-text": "label",
                                      "item-value": "value",
                                      outlined: "",
                                      dense: true,
                                      height: 32,
                                      clearable: true,
                                      "clear-icon": "$clear",
                                      "hide-details": "auto"
                                    },
                                    model: {
                                      value: _vm.editCustomer.followStatus,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editCustomer,
                                          "followStatus",
                                          $$v
                                        )
                                      },
                                      expression: "editCustomer.followStatus"
                                    }
                                  })
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "memo" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              outlined: "",
                              height: 80,
                              name: "input-7-4",
                              label: "跟进详情",
                              value: ""
                            },
                            model: {
                              value: _vm.editCustomer.memo,
                              callback: function($$v) {
                                _vm.$set(_vm.editCustomer, "memo", $$v)
                              },
                              expression: "editCustomer.memo"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "8px" },
                  attrs: { type: "ghost" },
                  on: {
                    click: function($event) {
                      return _vm.cancel("editCustomer")
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.editSave("editCustomer")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "HqModal",
        {
          attrs: { visible: _vm.phoneShow, title: "提示", width: "450" },
          on: { "on-cancel": _vm.cancel }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "margin-top": "10px" }, attrs: { span: 24 } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.deptList,
                      "menu-props": { bottom: true, offsetY: true },
                      label: "成交校区",
                      outlined: "",
                      "item-text": "deptName",
                      "item-value": "deptId",
                      dense: true,
                      height: 32,
                      clearable: true,
                      "clear-icon": "$clear",
                      "hide-details": "auto"
                    },
                    model: {
                      value: _vm.deptId,
                      callback: function($$v) {
                        _vm.deptId = $$v
                      },
                      expression: "deptId"
                    }
                  })
                ],
                1
              ),
              false
                ? _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 24 }
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-warning",
                        staticStyle: { color: "red" }
                      }),
                      _vm._v("该商机存在多个号码，请选择成交录单号码")
                    ]
                  )
                : _vm._e(),
              _c(
                "el-col",
                { staticStyle: { "margin-top": "20px" }, attrs: { span: 24 } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.dealPhoneList,
                      "menu-props": { bottom: true, offsetY: true },
                      label: "成交号码",
                      outlined: "",
                      dense: true,
                      height: 32,
                      clearable: true,
                      "clear-icon": "$clear",
                      "hide-details": "auto"
                    },
                    model: {
                      value: _vm.phone,
                      callback: function($$v) {
                        _vm.phone = $$v
                      },
                      expression: "phone"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.phoneSave } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  return _vm.$refs.drawer.close()
                }
              }
            },
            [_vm._v("返回")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }