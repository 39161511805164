var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "customer-label-box",
      staticStyle: { "margin-left": "20px" }
    },
    [
      _vm.items.length > 0
        ? _c(
            "span",
            { staticStyle: { "line-height": "0" } },
            _vm._l(_vm.items, function(i, index) {
              return _c(
                "div",
                {
                  key: i.labelId,
                  staticClass: "label_box",
                  style: { width: i.width + "px" },
                  on: {
                    mouseenter: function($event) {
                      return _vm.selectStyle(index)
                    },
                    mouseleave: function($event) {
                      return _vm.mouseLeave(index)
                    }
                  }
                },
                [
                  _c(
                    "el-tag",
                    {
                      ref: "Tag",
                      refInFor: true,
                      staticClass: "add_el_tag",
                      staticStyle: { color: "#337AFF" },
                      attrs: {
                        closable: i.close,
                        size: "small",
                        color: "#CCE6FF",
                        type: "plain"
                      },
                      on: {
                        close: function($event) {
                          return _vm.tagClose(i)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(i.labelName) + " ")]
                  )
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm.permUtil.WbCusotmer.customerGetDef() && _vm.perm
        ? _c(
            "el-button",
            {
              staticClass: "hq-button add-label-btn",
              attrs: { type: "primary" },
              on: { click: _vm.addLabelClick }
            },
            [_vm._v("标签")]
          )
        : _vm._e(),
      _c(
        "HqModal",
        {
          attrs: { visible: _vm.labelVisible, width: "550" },
          on: { "on-cancel": _vm.cancel }
        },
        [
          _c("div", { staticClass: "main_label_add" }, [
            _c(
              "div",
              { staticClass: "main_label_box" },
              _vm._l(_vm.navList, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "li",
                    class: { active: index == _vm.num },
                    on: {
                      click: function($event) {
                        return _vm.tab(index)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(item) + " ")]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "tabCon" },
              [
                _vm._l(_vm.tabContents, function(i, indexOne) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: 0 == _vm.num,
                          expression: "0 == num"
                        }
                      ],
                      key: i.groupId
                    },
                    [
                      _c("li", { staticClass: "tab_com_li" }, [
                        _vm._v(_vm._s(i.geoupName))
                      ]),
                      i.bizCustomerLabels
                        ? _c(
                            "div",
                            { staticClass: "tab_com_tag" },
                            [
                              _vm._l(i.bizCustomerLabels, function(
                                v,
                                indexTwo
                              ) {
                                return [
                                  _vm.selectLabelIds.indexOf(v.labelId) !== -1
                                    ? _c(
                                        "span",
                                        {
                                          key: v.labelId,
                                          staticClass: "tag_list",
                                          style: {
                                            backgroundColor:
                                              "" + (v.click ? "#19be6b" : ""),
                                            color: "" + (v.click ? "#fff" : "")
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.tagListClick(
                                                indexOne,
                                                indexTwo
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(v.name))]
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-left": "40px",
                                "line-height": "40px",
                                color: "blue"
                              }
                            },
                            [_vm._v("暂无标签")]
                          )
                    ]
                  )
                }),
                _vm._l(_vm.tabContents, function(item, indexOne) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: indexOne + 1 == _vm.num,
                          expression: "(indexOne + 1) == num"
                        }
                      ],
                      key: indexOne + item.geoupName
                    },
                    [
                      _c("li", { staticClass: "tab_com_li" }, [
                        _vm._v(_vm._s(item.geoupName))
                      ]),
                      item.bizCustomerLabels
                        ? _c(
                            "div",
                            { staticClass: "tab_com_tag" },
                            [
                              _vm._l(item.bizCustomerLabels, function(
                                v,
                                indexTwo
                              ) {
                                return [
                                  _vm.selectLabelIds.indexOf(v.labelId) !== -1
                                    ? _c(
                                        "span",
                                        {
                                          key: v.labelId,
                                          staticClass: "tag_list",
                                          style: {
                                            backgroundColor:
                                              "" + (v.click ? "#19be6b" : ""),
                                            color: "" + (v.click ? "#fff" : "")
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.tagListClick(
                                                indexOne,
                                                indexTwo
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(v.name))]
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-left": "40px",
                                "line-height": "40px",
                                color: "blue"
                              }
                            },
                            [_vm._v("暂无标签")]
                          )
                    ]
                  )
                })
              ],
              2
            )
          ]),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-checkbox",
                {
                  staticStyle: { "margin-right": "220px" },
                  on: { change: _vm.checkChange },
                  model: {
                    value: _vm.single,
                    callback: function($$v) {
                      _vm.single = $$v
                    },
                    expression: "single"
                  }
                },
                [_vm._v("仅查看已选标签")]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.cancel } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    loading: _vm.addloading
                  },
                  on: { click: _vm.saveClick }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }