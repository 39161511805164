<template>
  <transition name="el-notification-fade">
    <div
      :class="['el-notification', customClass, horizontalClass]"
      v-show="visible"
      :style="positionStyle"
      @mouseenter="clearTimer()"
      @mouseleave="startTimer()"
      @click="click"
      role="alert"
    >
      <i
        class="el-notification__icon"
        :class="[typeClass, iconClass]"
        v-if="type || iconClass"
      >
      </i>
      <div
        style="width: 100%"
        class="el-notification__group"
        :class="{ 'is-with-icon': typeClass || iconClass }"
      >
        <div class="a-notice">
          <span v-if="position == 'top-right'">
            <i
              class="iconfont iconbellfill"
              style="color: #337AFF;margin-right: 6px"
            ></i>
            <span>一条新的消息</span>
          </span>
          <span v-else class="el-notification__title" v-text="title"></span>
        </div>
        <span v-if="position == 'top-right'">
          <i
            :class="[
              'iconfont',
              title.indexOf('失败') === -1
                ? 'iconcheck'
                : 'iconbiaoqianguanbi-shubiaotingliu'
            ]"
            :style="{
              color: title.indexOf('失败') === -1 ? '#16B861' : '#F54955',
              marginRight: '6px'
            }"
          ></i>
          <span class="el-notification__title" v-text="title"></span>
        </span>
        <span v-else>
          <i
            :class="[
              'iconfont',
              title.indexOf('失败') === -1
                ? 'iconcheck'
                : 'iconbiaoqianguanbi-shubiaotingliu'
            ]"
            :style="{
              color: title.indexOf('失败') === -1 ? '#16B861' : '#F54955',
              marginRight: '6px'
            }"
          ></i>
          <span>推送内容</span>
        </span>
        <div
          class="el-notification__content"
          v-show="message"
          style="margin-top: 6px"
        >
          <slot>
            <p v-if="!dangerouslyUseHTMLString">{{ message }}</p>
            <p v-else v-html="message"></p>
          </slot>
        </div>
        <div
          class="el-notification__closeBtn el-icon-close"
          v-if="showClose"
          @click.stop="close"
        ></div>
        <div
          v-if="position == 'bottom-right'"
          style="text-align: center;margin-top: 10px"
        >
          <el-button size="mini" @click.stop="close">朕知道了</el-button>
          <el-button size="mini" type="primary" @click.stop="click"
            >去跟进</el-button
          >
        </div>
      </div>
    </div>
  </transition>
</template>

<script type="text/babel">
let typeMap = {
  success: "success",
  info: "info",
  warning: "warning",
  error: "error"
};

export default {
  data() {
    return {
      visible: false,
      title: "",
      message: "",
      duration: 4500,
      type: "",
      showClose: true,
      customClass: "",
      iconClass: "",
      onClose: null,
      onClick: null,
      closed: false,
      verticalOffset: 0,
      timer: null,
      dangerouslyUseHTMLString: false,
      position: "top-right"
    };
  },

  computed: {
    typeClass() {
      return this.type && typeMap[this.type]
        ? `el-icon-${typeMap[this.type]}`
        : "";
    },

    horizontalClass() {
      return this.position.indexOf("right") > -1 ? "right" : "left";
    },

    verticalProperty() {
      return /^top-/.test(this.position) ? "top" : "bottom";
    },

    positionStyle() {
      return {
        [this.verticalProperty]: `${this.verticalOffset}px`
      };
    }
  },

  watch: {
    closed(newVal) {
      if (newVal) {
        this.visible = false;
        this.$el.addEventListener("transitionend", this.destroyElement);
      }
    }
  },

  methods: {
    destroyElement() {
      this.$el.removeEventListener("transitionend", this.destroyElement);
      this.$destroy(true);
      this.$el.parentNode.removeChild(this.$el);
    },

    click() {
      this.visible = false;
      if (typeof this.onClick === "function") {
        this.onClick();
      }
    },

    close() {
      this.closed = true;
      if (typeof this.onClose === "function") {
        this.onClose();
      }
    },

    clearTimer() {
      clearTimeout(this.timer);
    },

    startTimer() {
      if (this.duration > 0) {
        this.timer = setTimeout(() => {
          if (!this.closed) {
            this.close();
          }
        }, this.duration);
      }
    },
    keydown(e) {
      if (e.keyCode === 46 || e.keyCode === 8) {
        this.clearTimer(); // detele 取消倒计时
      } else if (e.keyCode === 27) {
        // esc关闭消息
        if (!this.closed) {
          this.close();
        }
      } else {
        this.startTimer(); // 恢复倒计时
      }
    }
  },
  mounted() {
    if (this.duration > 0) {
      this.timer = setTimeout(() => {
        if (!this.closed) {
          this.close();
        }
      }, this.duration);
    }
    document.addEventListener("keydown", this.keydown);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.keydown);
  }
};
</script>
<style lang="scss" scoped>
.a-notice {
  margin-bottom: 18px;
}
.el-notification {
  z-index: 5000!important;
}
</style>
