<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-09 14:43:13
 * @LastEditors: Seven
 * @LastEditTime: 2022-03-29 15:32:33
-->
<template>
  <div id="app">
    <v-app>
      <router-view v-if="isRouterAlive"/>
    </v-app>
  </div>
</template>
<script>
export default {
  provide () {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
</script>
<style lang="scss">
@import url("./assets/css/reset.scss");
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  font-size: 14px;
}

.el-message--success {
  top: 100px !important;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.fb24{
  font-size: 24px;
  font-weight: bold;
  color: #F54955;
}
.fb14{
  font-size: 14px;
  font-weight: bold;
}
.color_title {
  color: #999999;
}
.color_price {
  color: #F54955;
  line-height: 40px;
}

.start {
  color: #F54955;
}
.inout_bar{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #E5F2FF;
  height: 48px;
  padding: 0 40px 0 10px;
  font-size: 14px;
  color: #333;
  .color{
    color: #337AFF;
    cursor: pointer;
  }
}
::v-deep table{
  font-size: 14px;
}
</style>
