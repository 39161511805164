/*
 * @Description: 首页
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-08-16 19:11:58
 * @LastEditors: Seven
 * @LastEditTime: 2021-09-06 17:56:51
 */
import Permissions from "./perm"
class WbHome extends Permissions {
  // 我的地盘
  static Homeone () {
    return WbHome.checkPerm('wb:biz:statisticsBiz')
  }
//   筛选勤奋榜
static Homeqinfen () {
    return WbHome.checkPerm('wb:ranking:diligent')
  }
  //   筛选动态榜
static Homedongtai () {
    return WbHome.checkPerm('wb:ranking:dynamic')
  }
// 联系情况分析
static Homelianxi () {
    return WbHome.checkPerm('wb:biz:fllowStatistical')
  }
// 我的消息
static Homexiaoxi () {
    return WbHome.checkPerm('wb:biz:statimesaage')
  }
// 呼叫动态
static Homehujiao () {
    return WbHome.checkPerm('wb:callrecord:callStatistical')
  }
// 通话
static Hometonghua () {
    return WbHome.checkPerm('wb:callrecord:callTimeStatistical')
  }
}
export default WbHome
