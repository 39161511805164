<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-07-08 20:10:42
 * @LastEditors: Seven
 * @LastEditTime: 2021-12-06 14:21:37
-->
<template>
  <v-autocomplete
    v-model="select"
    :items="items"
    :menu-props="{ bottom: true, offsetY: true }"
    :label="label"
    outlined
    :item-text="itemText"
    :item-value="itemValue"
    :dense="true"
    :height="32"
    :clearable="true"
    clear-icon="$clear"
    hide-details="auto"
    no-data-text="暂无数据"
    multiple
    @change="selectChange"
  >
    <template v-slot:selection="{ item, index }">
      <span v-if="index === 0" class="showtext">{{ item[itemText] }}</span>
      <span
        v-if="index === 1"
        class="grey--text text-caption"
      >
        (+{{ select.length - 1 }})
      </span>
    </template>
    <template v-slot:prepend-item>
      <v-list-item
        ripple
        @click="toggle"
      >
        <v-list-item-action>
          <v-icon :color="select.length > 0 ? 'indigo darken-4' : ''">
            {{ select.length === items.length?"mdi-close-box":(select.length > 0) && !(select.length === items.length)?"mdi-minus-box":"mdi-checkbox-blank-outline" }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            全选
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
    },
    label: {
      type: String,
    },
    itemText: {
      type: String,
    },
    itemValue: {
      type: String,
    },
  },
  data() {
    return {
      select: []
    }
  },
  methods: {
    toggle () {
      this.$nextTick(() => {
        if (this.select.length === this.items.length) {
          this.select = []
        } else {
          this.select = this.items.slice().map(i => i[this.itemValue])
        }
        this.$emit('update:select', this.select);
      })
    },
    selectChange() {
      this.$emit('update:select', this.select);
    },
    init() {
      this.select = []
    }
  }
}
</script>

<style lang="scss" scoped>
  .showtext {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 75%;
  }
  .grey--text {
    width: 5%;
  }
</style>
