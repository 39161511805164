/*
 * @Author: your name
 * @Date: 2020-09-02 15:55:22
 * @LastEditTime: 2021-08-18 11:36:33
 * @LastEditors: in hengqi by mengze
 * @Description: In User Settings Edit
 * @FilePath: \workbench-front\src\api\workbench\business.js
 */
const prefix = `/base-api`
const newPath = "/qw_new_api";
export default {


  // 获取公海分页数据
  getOpenSeaTableData: {
    url: `${prefix}/marketingBiz/customerList/listByPageForCommon`,
    method: "post"
  },
  // 获取公海分页子表数据
  getSubOpenSeaTableData: {
    url: `${prefix}/marketingBiz/follow/listById`,
    method: "post"
  },
  // 领取公海客户
  receiveCustomerByIds: {
    url: `${prefix}/marketingBiz/customer/receiveCustomerBatch`,
    method: "post"
  },
  // 根据主键获取客户基本信息
  getUserBaseinfoById: {
    url: `${prefix}/marketingBiz/customer/getDetail`,
    method: "post"
  },
  // 公海客户跟进 查询上一条下一条客户记录
  getNextAndPrevCustomerId: {
    url: `${prefix}/marketingBiz/customer/getNearCustomer`,
    method: "post"
  },
  // 报名成交后，修改商机状态，停止回收
  updateCustomerStatus: {
    url: `${prefix}/marketingBiz/customer/updateCustomerStatusAfterDeal`,
    method: "post"
  },
  // 获取所有动态分页列表
  getAllDynamicTableData: {
    url: `${prefix}/marketingBiz/customerLog/listByPage`,
    method: "post"
  },
  // 客户所有动态日志 - 完成新增订单，新增升班，转班，退费后调用
  getAllinsert: {
    url: `${prefix}/marketingBiz/customerLog/insert`,
    method: "post"
  },





  // 根据手机号码查询学员信息
  getUserByMobile: {
    url: `/qw_lj/users/getUserInfoByMobile`,
    method: "get",
  },
  // 根据userid来查询学员信息
  getUserByUserId: {
    url: `/qw_lj/users/getUserInfoByUserId`,
    method: "get",
  },
  // 新增学员信息
  getUserByMobileSave: {
    url: `/qw_lj/users/save`,
    method: "post",
  },
  // 修改学习账号
  getusersUpdate: {
    url: `/qw_lj/users/update`,
    method: "post",
  },




  
  // 获取跟进客户分页列表
  getFollowTableData: {
    url: `${prefix}/marketingBiz/follow/listByPageByCustomerId`,
    method: "post"
  },
  // 获取质检记录分页列表
  getQtTableData: {
    url: `${prefix}/marketingBiz/invalidCustomer/listByPageByCustomerId`,
    method: "post"
  },
  // 获取重单客户分页列表
  getDuplicateTableData: {
    url: `${prefix}/marketingBiz/customerRepeat/listByPageByCustomerId`,
    method: "post"
  },
  // 修改跟进记录
  updateFollowCustomerById: {
    url: `${prefix}/marketingBiz/follow/updateFollowInfo`,
    method: "post"
  },
  // 获取我的潜在客户分页列表
  getPotentialTableData: {
    url: `${prefix}/marketingBiz/customerList/listByPageForMyCustomer`,
    method: "post"
  },
  // 录入新客户
  createCustomerRecord: {
    url: `${prefix}/marketingBiz/customer/createForHand`,
    method: "post"
  },
  // 转让客户
  transferCustomer: {
    url: `${prefix}/marketingBiz/customer/transferCustomer`,
    method: "post"
  },
  // 预约客户
  appointmentCustomer: {
    url: `${prefix}/marketingBiz/customerAppointment/appointmentCustomer`,
    method: "post"
  },
  // 根据用户id获取默认校区信息 
  getDefaultSchoolDataByUserId: {
    url: `${prefix}/marketingBiz/defaultSchool/getDefaultSchoolByUserId`,
    method: "post"
  },
  // 退回公海
  returnToOpenSea: {
    url: `${prefix}/marketingBiz/customer/returnToCommonBatch`,
    method: "post"
  },
  // 标记无效客户
  markInvalidCustomer: {
    url: `${prefix}/marketingBiz/invalidCustomer/invalidCustomerBatch`,
    method: "post"
  },
  // 移入/移出留存前判断余额
  getBalanceBeforeReserve: {
    url: `${prefix}/marketingBiz/customerReserve/reserveJudge`,
    method: "post"
  },
  // 移入留存
  addReserve: {
    url: `${prefix}/marketingBiz/customerReserve/addReserve`,
    method: "post"
  },
  // 移出留存
  removeReverse: {
    url: `${prefix}/marketingBiz/customerReserve/removeReserve`,
    method: "post"
  },
  // 修改客户联系方式
  updateCustomerConcat: {
    url: `${prefix}/marketingBiz/customerContact/updateContact`,
    method: "post"
  },
  // 新增客户跟进记录
  createCustomerFollowInfo: {
    url: `${prefix}/marketingBiz/follow/create`,
    method: "post"
  },
  // 保存修改客户基本资料
  updateCustomerBaseInfo: {
    url: `${prefix}/marketingBiz/customer/updateCustomerInfo`,
    method: "post"
  },
  // 我的潜在客户客户跟进 上一页下一页
  getPotentialNextAndPrevId: {
    url: `${prefix}/marketingBiz/customer/getNearCustomerOnPrivate`,
    method: 'post'
  },
  // 预约客户分页列表
  getPurposeCustomerList: {
    url: `${prefix}/marketingBiz/customerAppointment/listByPage`,
    method: "post"
  },
  // 获取预约详情数据
  getAppointmentDetailData: {
    url: `${prefix}/marketingBiz/customerAppointment/getDetail`,
    method: "post"
  },
  // 预约详情更新
  updateAppointmentDetail: {
    url: `${prefix}/marketingBiz/customerAppointment/updateAppointment`,
    method: "post"
  },

  // 成交客户分页列表
  getDealCustomerList: {
    url: `${prefix}/marketingBiz/customerList/listByPageForDeal`,
    method: "post"
  },
  // 所有客户分页列表
  getAllCustomerList: {
    url: `${prefix}/marketingBiz/customerList/listByPage`,
    method: "post"
  },
  // 所有客户-导出客户
  exportAllCustomer: {
    url: `${prefix}/marketingBiz/customerList/exportCustomer`,
    method: "post"
  },
  // 所有客户 上一页下一页
  getAllFollowUpNextOrPrevId: {
    url: `${prefix}/marketingBiz/customer/getNearCustomerOnAll`,
    method: "post"
  },
  // 新增联系方式
  createCustomerContact: {
    url: `${prefix}/marketingBiz/customerContact/create`,
    method: "post"
  },
  // 修改联系方式
  updateCustomerContact: {
    url: `${prefix}/marketingBiz/customerContact/updateContact`,
    method: "post"
  },
  // 删除联系方式
  deleteCustomerContact: {
    url: `${prefix}/marketingBiz/customerContact/deleteContact`,
    method: "post"
  },
  // 检查联系方式是否可更新或删除
  checkCanUpdateOrDeleteContact: {
    url: `${prefix}/marketingBiz/customerContact/checkIsAllowUpdateOrDelete`,
    method: 'post'
  },
  // 根据客户id获取客户全部的联系方式
  getCustomerAllContactById: {
    url: `${prefix}/marketingBiz/customerContact/getContactListByCustomerId`,
    method: 'post'
  },
  // 查询客户标签
  getCustomerLabel: {
    url: `${prefix}/marketingBiz/bizCustomerLabel/getBizCustomerLabelAll`,
    method: "post"
  },
  // 新增客户标签
  createCustomerLabel: {
    url: `${prefix}/marketingBiz/bizCustomerLabel/save`,
    method: "post"
  },
  // 删除客户标签
  deleteCustomerLabel: {
    url: `${prefix}/marketingBiz/bizCustomerLabel/delete`,
    method: "post"
  },
  // 查询用户已绑定的标签
  getUserDefLabelInfo: {
    url: `${prefix}/marketingBiz/bizUserLabel/getUserLabelInfo`,
    method: "post"
  },
  // 删除用户已绑定的标签
  removeUserDefLabelInfo: {
    url: `${prefix}/marketingBiz/bizUserLabel/delete`,
    method: "post"
  },
  // 添加用户的常用标签
  createUserDefLabelInfo: {
    url: `${prefix}/marketingBiz/bizUserLabel/save`,
    method: "post"
  },
  // 根据赛道编码获取系统标签和客户标签列表
  getAllLabelList: {
    url: `${prefix}/marketingSystem/label/defLabel/list`,
    method: "get",
    isUrlParams: true
  },
  // 根据赛道编码查询客户标签列表和分组
  getCustomerLabelListByProject: {
    url: `${prefix}/marketingSystem/label/list`,
    method: "get",
    isUrlParams: true
  },
  // 客户列表-客户标签 我的潜在客户，自定义查询客户标签下拉框
  getCustomerLabelListSelect: {
    url: `/base-api/marketingSystem/label/getAllLabelList`,
    method: "post"
  },
  // ````````报名开始·················
  //根据客户Id获取客户的手机号l列表
  getCustomerPhoneList: {
    url: `${prefix}/marketingBiz/customerContact/getPhoneByCustomerId`,
    method: "post"
  },
  // 订单管理售后查询成交信息
  getCustomerList: {
    url: `${prefix}/marketingBiz/customerAppointment/getAppintInfoForUpClass`,
    method: "post"
  },
  
  // 获取报名页详情招生老师，成交校区
  getTeacherAndSchool: {
    url: `${prefix}/marketingBiz/customerAppointment/getAppointInfo`,
    method: "post"
  },
  // 用全网的projectCode换取网校的businessId
  // switchBusinessByProjectId: {
  //   url: `${prefix}/marketingUser/sysProject/onlineSchoolProjectCodeMap`,
  //   method: "get"
  // },
  // ```````报名结束······················
  // 根据

  // 转介绍客户分页列表
  getRecommandCustomerList: {
    url: `/qi-course/tch/billStudentIntroduce/queryList`,
    method: "get"
  },

  //客户所有动态日志  - 完成新增订单，新增升班，转班，退费后调用
  customerLog:{
    url: `${prefix}/marketingBiz/customerLog/insert`,
    method: "post"
  },
  //网校赛道编码映射
  onlineSchoolProjectCodeMap:{
    url: `${prefix}/marketingUser/sysProject/onlineSchoolProjectCodeMap`,
    method: "get"
  },
  //获取出纳列表
  getCashierVerifiedUser:{
    url: `${prefix}/marketingUser/sysUser/getCashierVerifiedUser`,
    method: "get"
  },
  // 赛道部门联动管理树数据
  getPorjectTree: {
    url: `${prefix}/marketingUser/sysDept/projectTree`,
    method: "get"
  },
  // 赛道部门联动管理转让树数据
  getPorjecttarnTree: {
    url: `${prefix}/marketingUser/sysDept/transferDeptTree`,
    method: "get"
  },
  // 获取新招生老师
  GetDeptUser: {
    url: `${prefix}/marketingUser/sysUser/getDeptUser`,
    method: "get"
  },
  // 获取新招生老师新接口
  GetNcSchoolByProjectCodeDeptUser: {
    url: `${prefix}/marketingUser/sysUser/getNcSchoolByProjectCodeDeptUser`,
    method: "get"
  },
  //根据客户编号请求全网用户姓名
  getDetailByCode:{
    url: `${prefix}/marketingBiz/customer/getDetailByCode`,
    method: "get"
  },
  // 转让客户中跨系统转让（工作台转全网）-->组织机构
  getDeptTreeList: {
    url: `${prefix}/marketingUser/thirdPartyController/getDeptTree`,
    method: "get"
  },
  // 转让客户中跨系统转让（工作台转全网）-->归属老师
  getUserByDeptIdList: {
    url: `${prefix}/marketingUser/thirdPartyController/getUserByDeptId?`,
    method: "get"
  },
  // 转让客户中跨系统转让（工作台转NC）-->获取省份
  getProvinceOrSchoolList: {
    url: `${prefix}/marketingSchool/self-exam/school/get`,
    method: "get"
  },
  // 转让客户中跨系统转让（工作台转NC）-->获取招生老师
  getNcTeacherByList: {
    url: `${prefix}/marketingThirdPart/Nc/getNcTeacher`,
    method: "get"
  },
  // 转让客户中跨系统转让（工作台转NC）-->获取校区
  getNcschoolByList: {
    url: `${prefix}/marketingSchool/self-exam/school/getNcSchool`,
    method: "get"
  },
  // 转让客户中工作台转全网确认按钮
  getTransferClick: {
    url: `${prefix}/marketingBiz/mustangController/transferCustomerToMustang`,
    method: "post"
  },
  // 转让客户中工作台转nc确认按钮
  getTransferClickNC: {
    url: `${prefix}/marketingBiz/ncController/transferCustomerToNc`,
    method: "post"
  },
  // 获取流量合伙人接口
  GetPartner: {
    url: `${prefix}/marketingThirdPart/Nc/requestNcGetPartner`,
    method: "get"
  },
  // 赛道编码转换接口
  getProjectcode: {
    url: `${prefix}/marketingBiz/customer/getCustomerProject`,
    method: "get"
  },
}