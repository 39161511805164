var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "confirmbox-fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible"
          }
        ],
        staticClass: "confirm_wrapper"
      },
      [
        _c(
          "div",
          {
            ref: "confirmBox",
            staticClass: "confirm_box",
            style: { width: _vm.width ? _vm.width + "px" : "450px" }
          },
          [
            _c("p", { staticClass: "confirm_title" }, [
              _c("span", { staticClass: "sign" }),
              _vm._v(" " + _vm._s(_vm.title || "提示") + " ")
            ]),
            _c("i", {
              staticClass: "el-icon-close delete",
              on: { click: _vm.otherClick }
            }),
            _c("div", { staticClass: "modal__body" }, [_vm._t("default")], 2),
            _c("div", { staticClass: "footer_button" }, [_vm._t("footer")], 2)
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }