<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-07-07 10:14:16
 * @LastEditors: Seven
 * @LastEditTime: 2021-12-28 16:55:10
-->
<template>
  <el-card>
    <el-tabs v-model="activeName">
      <el-tab-pane label="跟进记录" name="followList" v-if="followList && followList.length > 0">
        <el-table
          ref="multipleTable"
          :data="followList"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
        >
          <el-table-column :show-overflow-tooltip="true"
            label="跟进部门"
            prop="deptName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="跟进人"
            prop="userName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="跟进时间"
            prop="createTime"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="跟进详情"
            prop="memo"
            min-width="150"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="沟通方式"
            prop="contactTypeName"
            min-width="120"
            
          >
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="下次联系时间"
            prop="nextTime"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="意向状态"
            prop="followStatusName"
            min-width="150"
            
          ></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="预约" name="findList" v-if="findList && findList.length > 0">
        <el-table
          ref="multipleTable"
          :data="findList"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
        >
          <el-table-column :show-overflow-tooltip="true"
            label="预约校区"
            prop="schoolName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="预约时间"
            prop="appointmentTime"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="到访状态"
            prop="visitStatusStr"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="归属老师"
            prop="userName"
            min-width="120"
            
          >
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="预约老师"
            prop="teacherName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="到访时间"
            prop="visitedTime"
            min-width="150"
            
          ></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="流转记录" name="processList" v-if="processList && processList.length > 0">
        <el-table
          ref="multipleTable"
          :data="processList"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
        >
          <el-table-column :show-overflow-tooltip="true"
            label="流程编号"
            prop="processId"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="部门"
            prop="deptName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="归属人"
            prop="userName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="跟进次数"
            prop="followCount"
            min-width="80"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="说明"
            prop="memo"
            min-width="120"
            
          >
          </el-table-column>
          <!-- <el-table-column :show-overflow-tooltip="true"
            label="回收日期"
            prop="expireTime"
            min-width="150"
            
          ></el-table-column> -->
          <el-table-column :show-overflow-tooltip="true"
            label="创建人"
            prop="createName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="创建时间"
            prop="createTime"
            min-width="150"
            
          ></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="重咨记录" name="repeatList" v-if="repeatList && repeatList.length > 0">
        <el-table
          ref="multipleTable"
          :data="repeatList"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
        >
          <el-table-column :show-overflow-tooltip="true"
            label="创建人"
            prop="createName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="创建时间"
            prop="createTime"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="商机来源"
            prop="sourceStr"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="备注"
            prop="memo"
            min-width="150"
            
          ></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="质检记录" name="invalidList" v-if="invalidList && invalidList.length > 0">
        <el-table
          ref="multipleTable"
          :data="invalidList"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
        >
          <el-table-column :show-overflow-tooltip="true"
            label="标记无效时间"
            prop="createTime"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="质检时间"
            prop="bizCreateTime"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="无效原因"
            prop="typeName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="质检详情"
            prop="typeName"
            min-width="150"
            
          >
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="状态"
            prop="statusName"
            min-width="150"
            
          >
            <template slot-scope="scope">
              <div :style="{color: scope.row.status === 0?'#A30014':'#4B7902'}">
                {{ scope.row.statusName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="质检人"
            prop="inspectUserName"
            min-width="150"
          ></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="留存记录" name="reserveList" v-if="reserveList && reserveList.length > 0">
        <el-table
          ref="multipleTable"
          :data="reserveList"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
        >
          <el-table-column :show-overflow-tooltip="true"
            label="流程编号"
            prop="processId"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="部门"
            prop="deptName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="归属销售"
            prop="userName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="留存类型"
            prop="statusStr"
            min-width="150"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="流程记录创建人"
            prop="createName"
            min-width="150"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="留存记录创建时间"
            prop="createTime"
            min-width="150"
            
          ></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="客服对话" name="conversationList" v-if="conversationList && conversationList.length > 0">
        <ul class="ul">
          <template v-for="(item, index) in conversationList">
            <li :key="index" v-if="item.sayFrom === '客服'">
              <div class="circle left"></div>
              <div class="content-box left">
                <p class="time">{{ item.msgTime }}</p>
                <div class="content" v-html="item.message"> 
                </div>
              </div>
            </li>
            <li style="text-align: center" :key="index" v-if="item.sayFrom === '系统消息'">
              <div v-html="item.message"></div>
            </li>
            <li :key="index" v-if="item.sayFrom === '访客'">
              <div class="circle right"></div>
              <div class="content-box right">
                <p class="time">{{item.msgTime}}</p>
                <div class="content-right">
                    {{item.message}}
                </div>
              </div>
            </li>  
          </template>
        </ul>
      </el-tab-pane>
      <el-tab-pane v-if="nominateTalkShow" label="话术推荐" name="nominateTalk">
        <nominate-talk ref="talk" />
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>
<script>
import { 
  getFollowByCustomerId,
  getFindListByCustomerId,
  getreserveByCustomerId,
  getRepeatByCustomerId,
  getProcessByCustomerId,
  getInvalidByCustomerId,
  getCustomerConversation,
} from "api/customer";
import nominateTalk from "./nominateTalk";
import { autoFixValue } from '@/utils';
export default {
  components: { nominateTalk },
  data() {
    return {
      customerId: "",
      listLoading: false,
      activeName: null,
      // 跟进记录列表
      followList: [],
      // 预约列表
      findList: [],
      // 流程
      processList: [],
      // 重单
      repeatList: [],
      // 质检
      invalidList: [],
      // 留存
      reserveList: [],
      
      // 客服对话
      conversationList: [],
      // 话术权限
      nominateTalkShow: false,
    }
  },
  methods: {
    async init(customerId, obj = {}) {
      this.customerId = autoFixValue(customerId)
      if(!(this.$store.state.user.talkConfiguration.split(',').map(Number).indexOf(obj.trackType) === -1) && this.permUtil.WbCusotmer.groupListPage()) {
        this.nominateTalkShow = true
        await this.$nextTick(() => {
          this.$refs.talk.init(obj)
        });  
      } else {
        this.nominateTalkShow = false
      }
      await this.getFollowByCustomerId()
      await this.getFindListByCustomerId()
      await this.getProcessByCustomerId()
      await this.getRepeatByCustomerId()
      await this.getInvalidByCustomerId()
      await this.getreserveByCustomerId()
      await this.getCustomerConversation()
      
      let arr = ['followList', 'findList', 'processList', 'repeatList', 'invalidList', 'reserveList', 'conversationList']
      for (let i = 0; i < arr.length; i++) {
        if(this[arr[i]] && this[arr[i]].length > 0) {
          this.activeName = arr[i]
          break;
        }
      }
    },
    // 获取跟进记录列表
    async getFollowByCustomerId() {
      console.log('this.$store.state.data.detailcustomerId', this.$store.state.data.detailcustomerId);
      // let custId = this.customerId ?this.customerId:this.$store.state.data.detailcustomerId
      let custId = autoFixValue(this.customerId !== undefined ? this.customerId : localStorage.getItem("setdetailcustomerId"), 0)
      const { code, result } = await getFollowByCustomerId(custId)
      if(code === 0) {
        this.followList = result
      }
    },
    // 获取预约
    async getFindListByCustomerId() {
      // let custId = this.customerId ?this.customerId:this.$store.state.data.detailcustomerId
      let custId = autoFixValue(this.customerId !== undefined ? this.customerId : localStorage.getItem("setdetailcustomerId"), 0)

      const { code, result } = await getFindListByCustomerId(custId)
      if(code === 0) {
        this.findList = result
      }
    },
    // 重单
    async getRepeatByCustomerId() {
      // let custId = this.customerId ?this.customerId:this.$store.state.data.detailcustomerId
      let custId = autoFixValue(this.customerId !== undefined ? this.customerId : localStorage.getItem("setdetailcustomerId"), 0)

      const { code, result } = await getRepeatByCustomerId(custId)
      if(code === 0) {
        this.repeatList = result
      }
    },
    async getProcessByCustomerId() {
      // let custId = this.customerId ?this.customerId:this.$store.state.data.detailcustomerId
      let custId = autoFixValue(this.customerId !== undefined ? this.customerId : localStorage.getItem("setdetailcustomerId"), 0)

      const { code, result } = await getProcessByCustomerId(custId)
      if(code === 0) {
        this.processList = result
      }
    },
    // 质检
    async getInvalidByCustomerId() {
      // let custId = this.customerId ?this.customerId:this.$store.state.data.detailcustomerId
      let custId = autoFixValue(this.customerId !== undefined ? this.customerId : localStorage.getItem("setdetailcustomerId"), 0)

      const { code, result } = await getInvalidByCustomerId(custId)
      if(code === 0) {
        this.invalidList = result
      }
    },
    // 获取留存
    async getreserveByCustomerId() {
      // let custId = this.customerId ?this.customerId:this.$store.state.data.detailcustomerId
      let custId = autoFixValue(this.customerId !== undefined ? this.customerId : localStorage.getItem("setdetailcustomerId"), 0)

      const { code, result } = await getreserveByCustomerId(custId)
      if(code === 0) {
        this.reserveList = result
      }
    },
    // 客服对话
    async getCustomerConversation() {
      // let custId = this.customerId ?this.customerId:this.$store.state.data.detailcustomerId
      let custId = autoFixValue(this.customerId !== undefined ? this.customerId : localStorage.getItem("setdetailcustomerId"), 0)

      const { code, result } = await getCustomerConversation(custId)
      if(code === 0) {
        this.conversationList = result.data
      }
    },
  }
}
</script>
<style lang="scss" scoped>
  // 客服对话
  .ul {
    width: 100%;
    min-height: 300px;
    margin: 10px auto;
    padding: 10px;
    background: #f5f5fa;
    >li {
      overflow: hidden;
      font-size: 14px;
      margin-bottom: 10px;
    }
    .left {
      float: left;
    }
    .right {
      float: right;
      margin-left: 10px;
      >p {
        text-align: right;
      }
    }
    .circle {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #999;
      margin-right: 10px;
    }
    .content-box {
      >p {
        margin-bottom: 6px;
      }
      .content {
        width: 280px;
        background: #fff;
        padding: 0 4px;
      }

      .content-right {
        padding: 6px 10px;
        background: #5ec99b;
        border-radius: 4px;
        color: #fff;
      }
    }
  }
</style>
