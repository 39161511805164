<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-04-22 15:03:01
 * @LastEditors: Seven
 * @LastEditTime: 2022-08-19 16:58:42
-->
<template>
  <div class="layout_box">
    <div :style="{height: itemList.children && itemList.children.length && !sidebarShow?'100px':'60px'}"></div>
    <div class="nav-box">
      <div class="nav">
        <div class="logo">
          <img src="@/assets/image/logo.svg" alt="">
        </div>
        <div class="crumb">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-for="item in currentPath" :to="{ path: item.path }" :key="item.name">{{ item.title }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <ul class="tab-list">
          <li
            v-for="(item, index) in tabList"
            :key="item.menuId"
            :class="{'active': activeIndex === index}"
            @click="selectClick(item, index)"
          >
            <router-link :to="item.path" active-class="router-link-active">{{
              item.title
            }}</router-link>
          </li>
        </ul>
        <div class="tool">
          <div>
            <!-- <el-button @click="goCrm" class="go_crm" icon="el-icon-monitor" size="small">管理后台</el-button> -->
            <el-dropdown trigger="click" @command="dropDownChange">
              <span class="el-dropdown-link">
                <el-button class="go_crm" icon="el-icon-monitor" size="small">更多应用</el-button>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="4">招生道具</el-dropdown-item>
                  <el-dropdown-item command="3">管理后台</el-dropdown-item>
                  <el-dropdown-item command="5">教务端</el-dropdown-item>
                  <el-dropdown-item command="6">讲师端</el-dropdown-item>
                  <el-dropdown-item command="7">对账函</el-dropdown-item>
                  <el-dropdown-item command="8">机考税务师</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <el-input 
            class="global-search"
            size="small"
            placeholder="手机/微信/QQ/座机"
            v-model="globalSearch">
          </el-input>
          <div style="margin-left: 1px">
            <el-button style="padding: 9px" size="small" @click="searchBlur">查询</el-button>
          </div>
          <CallCenter />
          <MessageCenter :method="goDetail" />
          <div class="avatar-box">
            <el-dropdown trigger="click" @command="dropDownChange">
              <span class="el-dropdown-link">
                <div class="img-box">
                  <img v-if="avatar" :src="avatar" alt="">
                  <div style="font-size: 12px;line-height: 42px;text-align: left;color: #fff;transform: scale(.8);" v-else>{{ $store.state.user.loginUser.userName }}</div>
                </div>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="1">个人中心</el-dropdown-item>
                  <el-dropdown-item command="2">退出</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </div>
      <el-collapse-transition>
        <Sidebar
          :itemList="itemList"
          v-if="itemList.children && itemList.children.length && !sidebarShow"
        />
      </el-collapse-transition>
    </div>
    <HqModal :visible="selectShow" width="450" @on-cancel="cancel">
      <el-form ref="form" :model="form" :rules="formRules" style="text-align: center">
        <v-select
          style="width: 282px; margin: 18px auto 0"
          v-model="form.trackType"
          :items="trackTypeList"
          :menu-props="{ bottom: true, offsetY: true }"
          label="所属赛道"
          item-text="trackTypeName"
          item-value="trackType"
          outlined
          :dense="true"
          :height="32"
          :clearable="true"
          clear-icon="$clear"
          hide-details="auto"
        ></v-select>
        <div style="color: #f54955; font-size: 12px; line-height: 24px">
          查询的数据存在多个赛道，请选择赛道后继续查询
        </div>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="save" type="primary">确认</el-button>
      </div>
    </HqModal>
    <!-- 详情 -->
    <Detail ref="drawer" />
    <CallPanel />
    <CallPanelzthl />
    <keep-alive :include="['orderList', 'collectionList']">
      <router-view />
    </keep-alive>
  </div>
</template>
<script>
import Sidebar from "./components/sidebar";
import CallPanel from "./components/call-panel";
import CallPanelzthl from "./components/call-panelzthl";
import MessageCenter from "./components/message-center";
import CallCenter from "./components/call-center";
import elCollapseTransition from "element-ui/lib/transitions/collapse-transition";
import Detail from "@/views/customer/components/detail";
import { getSelectByDetail, getSeeaiLink } from "@/api/home";
import { requestLogOut } from "@/api/login/login-api";
import { mapActions, mapGetters } from "vuex";
import { setCurrentPath } from "@/utils/common";
import { getAccessTicket, getAccessTicketshuiwu, loginByAccessTicket,getAccessAutoLogin } from "api/public";
import Cookies from "js-cookie";
import axios from "axios"


export default {
  name: "layout",
  components: {
    Sidebar,
    CallPanel,
    MessageCenter,
    CallCenter,
    elCollapseTransition,
    Detail,
    CallPanelzthl
  },
  data() {
    return {
      tabList: [],
      index: 0,
      activeIndex: null,
      hoverShow: false,
      itemList: {},
      globalSearch: "",
      selectShow: false,
      form: {
        trackType: "",
      },
      oldSearchValue: "",
      formRules: {
        trackType: [
          { require: true, message: "请选择赛道"  }
        ]
      },
      trackTypeList: [],
      sidebarShow: false
    };
  },
  computed: {

    ...mapGetters(["loginUser"]),
    menuList() {
      return this.$store.state.user.menuList
    },
    navHeight() {
      return this.$store.state.app.secondRouter.length !== 0 ? "70px" : "40px";
    },
    avatar() {
      return this.$store.state.user.loginUser.avatar
    },
    currentPath() {
      return this.$store.state.app.currentPath; // 当前面包屑数组
    },
  },
  mounted() {
    if(process.env.NODE_ENV === "testing") {
      Cookies.set("testUserToken", localStorage.getItem("userToken"), { expires: 1, domain: ".hqjy.com" });
    } else if(process.env.NODE_ENV === "production") {
      Cookies.set("proUserToken", localStorage.getItem("userToken"), { expires: 1, domain: ".hqjy.com" });
    }
    console.log(Cookies.get('testUserToken'));
    setCurrentPath(this, this.$route.name);
    console.log(setCurrentPath(this, this.$route.name), "++++++++");
    this.getUserPerm()
    this.getTyUserInfoById();
    this.getTalkConfiguration()
  },
  methods: {
    ...mapActions(["getUserPerm", "getTalkConfiguration"]),
      //根据 tyUserId 获取天翼的用户信息，用于判断是否有钉钉id
      getTyUserInfoById(){
          let tyUserId =  this.loginUser&& this.loginUser.tyUserId;   
          this.$fetch('getTyUserInfoById', {
              id: tyUserId
          }).then( res=>{
            if( res.code == 200 ){
              let data = res.data;
              let tyUserInfo = {
                id: data.id,
                userName: data.userName,
                dingtalkUserId: data.dingtalkUserId,
                nickName: data.nickName || ''
              }
              window.localStorage.setItem("tyUserInfo", JSON.stringify(tyUserInfo));
            }
           
          }).catch( error=>{
              console.log(error)
              window.localStorage.setItem("tyUserInfo", JSON.stringify({}));
          })  
      },
    selectClick(item, index) {
      if(item.children[0] && item.children[0].path) {
        this.$router.push(item.children[0].path)
      } else {
        this.$router.push(item.path)
      }
      this.activeIndex = index;
      this.itemList = item;
    },
    dropDownChange(name) {
      console.log('name', name);
      if (name == 1) {
        this.$router.push("/wbHome/user")
        this.itemList = {}
      } else if (name == 2) {
        // 退出登录
        requestLogOut().then(() => {
          this.$store.commit("logout", this)
          if(process.env.VUE_APP_TITLE === "development") {
            // location.href = `http://crm.dev.hqjy.com/#/login`
          } else if(process.env.NODE_ENV === "testing") {
            Cookies.remove('testUserToken', { domain: ".hqjy.com" })
            location.href = `http://crm.hqbis.com:18080/#/login`
          } else if(process.env.NODE_ENV === "production") {
            Cookies.remove('proUserToken', { domain: ".hqjy.com" })
            location.href = `http://crm.hqjy.com/#/login`
          }
        })
      } else if(name == 3) {
        if(process.env.NODE_ENV === "development") {
          window.open(
            `http://crm.dev.hqjy.com/#/home?token=${localStorage.getItem("userToken")}`
          );
        } else if(process.env.NODE_ENV === "testing") {
          window.open(
            `http://crm.hqbis.com:18080/#/home?token=${localStorage.getItem("userToken")}`
          );
        } else if(process.env.NODE_ENV === "beta") {
          window.open(
            `http://crm.beta.hqjy.com/#/home?token=${localStorage.getItem("userToken")}`
          );
        }  else if(process.env.NODE_ENV === "production") {
          window.open(
            `https://crm.hqjy.com/#/home?token=${localStorage.getItem("userToken")}`
          );
        }
      } else if(name == 4) {
        if(process.env.NODE_ENV === "development") {
        
        } else if(process.env.NODE_ENV === "testing") {
          window.open(
            `https://yxgj.beta.hqjy.com/lm/app-start`
          );
        } else if(process.env.NODE_ENV === "beta") {
          
        }  else if(process.env.NODE_ENV === "production") {
          window.open(
            `https://yxgj.hqjy.com/lm/app-start`
          );
        }
      } else if (name == 5) {
        let params = {
          telephone: this.$store.state.user.loginUser.phone,
          // telephone: '13826196917',
          platformId: 'platform-officer'
        }
        console.log('params', params);
        getSeeaiLink(params).then(res => {
          console.log('resseeai', res);
          if (res.status == 200) {
            let str = ''
            Object.keys(res.data.user).forEach(key=>{
              str+=`${key}=${res.data.user[key]}&`
            })
            Cookies.set("seeAiToken", str, { expires: 1, domain: ".hqjy.com" });
            if(process.env.NODE_ENV === "development") {
              window.open(
                `http://seeai-test.beta.hqjy.com/officer/?cjsd#/login?${str}`
              );
            } else if(process.env.NODE_ENV === "testing") {
              window.open(
                `http://seeai-test.beta.hqjy.com/officer/?cjsd#/p/login?${str}`
              );
            } else if(process.env.NODE_ENV === "beta") {
              
            }  else if(process.env.NODE_ENV === "production") {
              window.open(
                `https://seeai.hqjy.com/officer/?cjsd#/p/login?${str}`
              );
            }
          } else {
            this.$message.error(res.message)
          }
        })
      } else if (name == 6) {
        let params = {
          telephone: this.$store.state.user.loginUser.phone,
          // telephone: '13826196917',
          platformId: 'platform-teacher'
        }
        getSeeaiLink(params).then(res => {
          console.log('resseeai', res);
          if (res.status == 200) {
            let str = ''
            Object.keys(res.data.user).forEach(key=>{
              str+=`${key}=${res.data.user[key]}&`
            })
              Cookies.set("seeAiToken", str, { expires: 1, domain: ".hqjy.com" });
            if(process.env.NODE_ENV === "development") {
              console.log(`http://ky.qicourse.cn/teacher/?cjsd#/login?${str}`);
                // `http://ky.qicourse.cn/teacher/?cjsd=v.20220527110834#/p/login?${str} `
              window.open(
                `http://seeai-test.beta.hqjy.com/teacher/?cjsd#/login?${str}`
              );
            } else if(process.env.NODE_ENV === "testing") {
              window.open(
                `http://seeai-test.beta.hqjy.com/teacher/?cjsd#/login?${str}`
              );
            } else if(process.env.NODE_ENV === "beta") {
              
            }  else if(process.env.NODE_ENV === "production") {
              window.open(
                `https://seeai.hqjy.com/teacher/?cjsd#/login?${str}`
              );
            }
          } else {
            this.$message.error(res.message)
          }
        })
      } else if(name == 7) {
        // 跳转新财务系统
        getAccessTicket({ zhxqSignerId: this.loginUser.userId }).then(res => {
          if(res.code == 200) {
            if(process.env.NODE_ENV === "testing") {
              window.open(
                `http://10.0.99.44:8600/mySignLetter?data=${res.data}&userId=${this.loginUser.userId}`
              );
            } else if(process.env.NODE_ENV === "production") {
              window.open(
                `http://billadmin.hqbis.com/mySignLetter?data=${res.data}&userId=${this.loginUser.userId}&ncPk=${this.loginUser.ncUserPk}`
              );
            }
          } else {
            this.$message.error(res.msg)
          }
        })
      } else if(name == 8) {
        // 跳转机考税务师
        console.log('this.loginUser, ', this.loginUser);
        if (process.env.NODE_ENV === "testing") {
              window.open(
                `http://cjwxplatform.dev.hqjy.com/schoolcj/website/adminApi/autoLogin?zhxqUserName=${this.loginUser.phone}`
              )
            } else if (process.env.NODE_ENV === "production") {
              window.open(
                `https://cjwx.platform.hqjy.com/schoolcj/website/adminApi/autoLogin?zhxqUserName=${this.loginUser.phone}`
              )
            }
        // axios({
        //   url: `http://cjwxplatform.dev.hqjy.com/schoolcj/website/adminApi/autoLogin?zhxqUserName=${this.loginUser.phone}`,
        //   method: 'get',
        // }).then(response => {})
        // getAccessAutoLogin({zhxqUserName: this.loginUser.phone}).then(res => {
        //   console.log('res', res);
        //   if (res.code == 200) {
            
        //     // if(process.env.NODE_ENV === "testing") {
        //     //   window.open(
        //     //     `http://10.0.99.44:8600/mySignLetter?data=${res.data}&userId=${this.loginUser.userId}`
        //     //   );
        //     // } else if(process.env.NODE_ENV === "production") {
        //     //   window.open(
        //     //     `http://billadmin.hqbis.com/mySignLetter?data=${res.data}&userId=${this.loginUser.userId}&ncPk=${this.loginUser.ncUserPk}`
        //     //   );
        //     // }
        //   } else {
        //     this.$message.error(res.msg)
        //   }
        // })
      }
    },
    // 全局查询
    searchBlur() {
      if (this.globalSearch) {
        let params = {
          detail: this.globalSearch,
        }
        getSelectByDetail(params).then(res => {
          if (res.code === 0) {
            if (res.result.length > 1) {
              // 弹窗 选择赛道
              this.trackTypeList = res.result
              this.selectShow = true;
            } else {
              // 跳转商机详情
              this.$refs.drawer && this.$refs.drawer.init(res.result[0])
            }
          } else if (res.code === 1) {
            this.$message.info(res.msg)
          }
        })
      }
    },
    // 跳转商品详情
    save() {
      this.trackTypeList.map(item => {
        if(item.trackType === this.form.trackType) {
          this.goDetail(item)
        }
      })
      this.selectShow = false;
      // this.$router.push()
    },
    goDetail(row) {
      this.$refs.drawer && this.$refs.drawer.init(row)
    },
    cancel() {
      this.selectShow = false;
    },
    // 跳转后台
    goCrm() {
      if(process.env.VUE_APP_TITLE === "development") {
        // location.href = `http://crm.dev.hqjy.com/#/home?token=${localStorage.getItem("userToken")}`
        window.open(
          `http://crm.dev.hqjy.com/#/home?token=${localStorage.getItem("userToken")}`
        );
      } else if(process.env.VUE_APP_TITLE === "testing") {
        // location.href = `http://crm.hqbis.com:18080/#/home?token=${localStorage.getItem("userToken")}`
        window.open(
          `http://crm.hqbis.com:18080/#/home?token=${localStorage.getItem("userToken")}`
        );
      } else if(process.env.VUE_APP_TITLE === "beta") {
        // location.href = `http://crm.hqbis.com:18080/#/home?token=${localStorage.getItem("userToken")}`
        window.open(
          `http://crm.beta.hqjy.com/#/home?token=${localStorage.getItem("userToken")}`
        );
      }  else if(process.env.VUE_APP_TITLE === "production") {
        // location.href = `http://crm.hqjy.com/#/home?token=${localStorage.getItem("userToken")}`
        window.open(
          `http://crm.hqjy.com/#/home?token=${localStorage.getItem("userToken")}`
        );
      }
    }
  },
  watch: {
    menuList: {
      handler(val){
        this.tabList = val
        const menuList = val.filter(item => item.name === this.$route.path.split('/')[1])
        this.itemList = menuList[0] || {}
        this.$forceUpdate();
      }, 
      immediate: true
    },
    $route: {
      handler(to) {
        this.sidebarShow = to.meta.sidebar
        this.$store.commit("setCurrentPageName", to.name);
        setCurrentPath(this, to.name);
        localStorage.currentPageName = to.name;
      },
      immediate: true
    },
  }
};
</script>
<style lang="scss" scoped>
.layout_box {
  background: #fff;

  .zzc {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }

  .el-menu--horizontal {
    border-bottom: 0;
    background-color: #545c64;
  }
  .el-menu-item {
    height: 40px;
    line-height: 40px;
    border-bottom: 0;
    color: #fff;

    &:hover {
      background-color: #545c64;
      color: #fff;
    }

    &:focus {
      background-color: #545c64;
      color: #fff;
    }
  }

  ::v-deep.el-submenu__title {
    height: 40px;
    line-height: 40px;
    border-bottom: 0 !important;
    color: #fff !important;

    &:hover {
      background-color: #545c64;
      color: #fff;
    }

    &:focus {
      background-color: #545c64;
      color: #fff;
    }
  }

  ::v-deep.is-active {
    color: #409eff;

    .el-submenu__title {
      color: #409eff !important;
    }
  }

  .nav-box {
    width: 100%;
    position: fixed;
    height: 60px;
    top: 0;
    left: 0;
    z-index: 1000;
    background: linear-gradient(90deg, #337AFF 0%, #333BFF 100%);
  }

  ::v-deep.el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: #fff !important;
  }

  ::v-deep.el-breadcrumb__separator {
    color: #fff;
  }

  ::v-deep.el-breadcrumb__inner.is-link {
    color: #fff !important;
  }

  .crumb {
    display: inline-block;
    z-index: 100;
    margin-right: 50px;
    min-width: 82px;

    .el-breadcrumb {
      line-height: 60px;
    }
  }

  .nav {
    display: flex;
    line-height: 60px;
    width: 94%;
    margin: 0 auto;
    color: #fff;
    white-space: nowrap;
    height: 60px;
    .logo {
      flex: 1;
      // font-size: 20px;
      width: 156px;
      margin-right: 24px;
      padding: 10px 0;
      >img {
        width: 100%;
        height: 100%;
      }
    }

    .tab-list {
      // width: 300px
      // display: flex;
      flex: 2;

      // .active {
      //   position: relative;

        // &::after {
        //   content: "";
        //   display: block;
        //   position: absolute;
        //   width: 50%;
        //   height: 3px;
        //   background-color: #fff;
        //   bottom: 6px;
        //   left: 20px;
        // }
      // }

      > li {
        // width: 80px;
        position: relative;
        margin: 0 20px;
        cursor: pointer;
        display: inline-block;

        a {
          color: #fff;
          font-size: 14px;
        }
      }

      .router-link-active {
        color: #fff !important;

        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 3px;
          background-color: #fff;
          bottom: 6px;
          // left: 20px;
        }
      }
    }

    .tool {
      flex: 3;
      display: flex;
      justify-content: flex-end;
      // margin-right: 20px;
      font-size: 14px;

      .global-search {
        width: 162px;

        ::v-deep .el-input__inner {
          padding: 0 5px;
        }
      }

      .go_crm {
        padding: 9px 12px;
      }

      > div {
        margin-left: 10px;
      }

      .avatar-box {
        display: flex;
        cursor: pointer;
        align-items: center;

        .img-box {
          width: 42px;
          height: 42px;
          border-radius: 50%;
          border: 1px solid #c0c2c4;
          overflow: hidden;

          > img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
</style>
