/*
 * @Description: 客户管理权限
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-07-15 19:50:13
 * @LastEditors: Seven
 * @LastEditTime: 2021-11-03 10:58:39
 */
import Permissions from "./perm"
class WbCusotmer extends Permissions {
  // 我的新增
  static customerSave () {
    return WbCusotmer.checkPerm('wb:customer:save')
  }
  // 我的无效
  static customerInvalid () {
    return WbCusotmer.checkPerm('wb:invalid:setInvalid')
  }
  // 我的放弃
  static customerReturn () {
    return WbCusotmer.checkPerm('wb:return:returnToCommon')
  }
  // 我的转移
  static customerTransfer () {
    return WbCusotmer.checkPerm('wb:customer:transfer')
  }
  // 查询转移
  static customerTransfersearch () {
    return WbCusotmer.checkPerm('wb:customer:transferSelect')
  }
  // 我的导出
  static customerExport () {
    return WbCusotmer.checkPerm('wb:customer:export')
  }
  // 我的导入
  static customerImport () {
    return WbCusotmer.checkPerm('wb:customer:import')
  }
  // 我的预约
  static customerAppoint () {
    return WbCusotmer.checkPerm('wb:appointment:save')
  }
  // 我的移入/移出
  static customerReserve () {
    return WbCusotmer.checkPerm('wb:customer:addReserve')
  }
  // 我的更新资料
  static customerUpdate () {
    return WbCusotmer.checkPerm('wb:customer:update')
  }
  // 我的新增跟进记录
  static customerFollow () {
    return WbCusotmer.checkPerm('wb:follow:save')
  }
  // 我的修改跟进记录
  static customerFollowUpdate () {
    return WbCusotmer.checkPerm('wb:follow:update')
  }
  // 我的标签
  static customerGetDef () {
    return WbCusotmer.checkPerm('wb:defLable:getDef')
  }
  // 变更归属部门
  static transferChange () {
    return WbCusotmer.checkPerm('wb:customer:change')
  }
  // 查看手机号码
  static customerSeePhone () {
    return WbCusotmer.checkPerm('wb:customer:seePhone')
  }
  // 列表
  static groupListPage () {
    return WbCusotmer.checkPerm('talk:group:list')
  }
  // 公海 -----------------------------
  // 我的标签
  static higseaReceive () {
    return WbCusotmer.checkPerm('wb:highsea:receive')
  }
  // 预约 -----------------------------
  // 编辑
  static appiontUpdate () {
    return WbCusotmer.checkPerm('wb:appointment:update')
  }
  // 转nc
  static appiontTransfer () {
    return WbCusotmer.checkPerm('wb:appointment:transfer')
  }
  // 转nc -----------------------------
  // 编辑
  static transferUpdate () {
    return WbCusotmer.checkPerm('wb:transfer:update')
  }
  // 话务 -----------------------------
  // 导出
  static callExport () {
    return WbCusotmer.checkPerm('wb:callrecord:export')
  }
  // 质检 -----------------------------
  // 导出
  static invalidExport () {
    return WbCusotmer.checkPerm('wb:invalid:export')
  }
  // 质检
  static invalidInspect () {
    return WbCusotmer.checkPerm('wb:invalid:inspect')
  }
  // 冻结 -----------------------------
  // 释放
  static freezeRelease () {
    return WbCusotmer.checkPerm('wb:freeze:release')
  }
  // 导出--外呼通话
  static callrecordBtn () {
    return WbCusotmer.checkPerm('wb:callrecord:export')
  }
  // 批量移入移出留存
  static addBatchReserve(){
    return WbCusotmer.checkPerm('wb:customer:addBatchReserve')
  }
  // 我的客户变更渠道名称
  static saverannles(){
    return WbCusotmer.checkPerm('wb:customer:recruitChannels')
  }
  // 转nc按钮
  static transferselfBtn(){
    return WbCusotmer.checkPerm('wb:appointment:transfer:self')
  }
}

export default WbCusotmer