/*
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-16 10:21:57
 * @LastEditors: Seven
 * @LastEditTime: 2021-06-29 14:14:46
 */
import Vue from "vue";
import ConfirmBox from "./index.vue";
const ConfirmBoxConstructor = Vue.extend(ConfirmBox)

let instance;
const initInstance = (message, title, btnText, messageHtml) => {
  instance = new ConfirmBoxConstructor({
    el: document.createElement("div"),
    data() {
      return {
        title,
        message,
        messageHtml,
        btnText,
      };
    },
  });
};

const showConfirm = (obj) => {
  let { message, title, btnText, messageHtml } = obj;
  return new Promise((reslove, reject) => {
    initInstance(message, title, btnText, messageHtml);
    instance.callback = (action) => {
      if (action === "confirm") {
        reslove()
      } else if (action === "cancel") {
        reject()
      }
    }
    document.body.appendChild(instance.$el)
    Vue.nextTick(() => {
      instance.visible = true;
    });
  });
};

function registryConfirm() {
  Vue.prototype.$hqMessageBox = showConfirm;
}
export default registryConfirm;
