/*
 * @Author: your name
 * @Date: 2020-09-02 17:36:00
 * @LastEditTime: 2022-04-18 10:49:07
 * @LastEditors: Seven
 * @Description: In User Settings Edit
 * @FilePath: \workbench-front\src\api\workbench\common.js
 */
const prefix = '/base-api'
const third = '/qw_third'
export default {
  // 自定义查询配置
  createUserDefConfig: {
    url: `${prefix}/marketingSystem/sysUserDefinedSearch`,
    method: "post",
  },
  // 获取用户保存的自定义查询
  getUserDefConfig: {
    url: `${prefix}/marketingSystem/sysUserDefinedSearch/list`,
    method: "get",
  },

  // 获取所有赛道
  getAllProjectList: {
    url: `${prefix}/marketingUser/sysProject/onlineSchoolProjectCodeMap`,
    method: "get"
  },
  // 根据字典code获取字典项 
  // RECORD_SOURCE 呼叫记录里 接听状态下拉框
  // FOLLOW_STATUS_DISPLAY 公海记录里 跟进状态下拉框
  // SYS_LABEL   公海记录里 客户标签下拉框
  // EDUCATION_TYPE 跟进记录 学历类型下拉框
  // CHINESE_NATION 跟进记录 民族下拉框
  // SEX 跟进记录 性别下拉框
  // COMM_WAY 沟通方式 下拉框
  // INVALID_TYPE 我的潜在客户 标记无效下拉框
  // VISIT_STATUS 预约客户跟进 到访状态下拉框
  // BIZ_STATUS 跟进页 商机状态下拉框 

  getDictByCode: {
    url: `${prefix}/marketingSystem/sysDict/find`,
    method: "get",
    isUrlParams: true
  },
  // 我的潜在客户 组织机构下拉框
  getDeptSelectList: {
    url: `${prefix}/marketingUser/sysDept/deptTree`,
    method: "get"
  },
  // 我的潜在客户 归属老师下拉框 获取系统所有用户
  getSystemAllUsers: {
    url: `${prefix}/marketingUser/sysUser/listAll`,
    method: "get"
  },
  // 根据招生校区获取NC老师 我的潜在客户 招生老师接口
  getNcTeacherBySchoolId: {
    url: `${prefix}/marketingThirdPart/Nc/getNcTeacher`,
    method: "get"
  },
   // 获取nc接口
   getNcsaleMen: {
    url: `${prefix}/marketingThirdPart/Nc/getSelfTestSaleMan`,
    method: "get"
  },
  // 获取省份或校区接口
  getProvinceOrSchool: {
    url: `${prefix}/marketingSchool/self-exam/school/get`,
    method: "get"
  },
  // 渠道名称或渠道来源下拉列表  
  // type 0: 渠道名称 1: 渠道来源
  getChannelsSelectList: {
    url: `${prefix}/marketingSystem/bizChannels/pullDown`,
    method: 'get',
    isUrlParams: true
  },
  // 录入新客户 根据赛道编码查询对应的组织机构
  getDeptListByProjectCode: {
    url: `${prefix}/marketingUser/sysDept/projectTree`,
    method: "get"
  },
  // 转让客户-本系统转让
  getoutsideDeptId: {
    url: `${prefix}/marketingUser/sysUser/outsideGetUserByDeptId`,
    method: "get"
  },
  // 第三方 模糊
  getThirdOrderList: {
    url: `${third}/servlet/~hq/nc.impl.hq.webservice.student.GetThirdpartyOrderDataServlet`,
    method: "post"
  },
  // 第三方流水编辑 http://10.0.14.64:8899/servlet/~hq/nc.impl.hq.webservice.student.GetOneThirdpartyOrderDataServlet?platformordernum=P176495738944643
  // getOneThirdpartyOrderDataServlet: {
  //   url: `${third}/servlet/~hq/nc.impl.hq.webservice.student.GetOneThirdpartyOrderDataServlet`,
  //   method: "get"
  // },
  // 第三方 具体
  GetOneThirdpartyOrderDataServlet: {
    url: `${third}/servlet/~hq/nc.impl.hq.webservice.student.GetOneThirdpartyOrderDataServlet`,
    method: "get"
  },
  // pos机已使用情况
  GetUseThirdpartyOrderServlet: {
    url: `${third}/servlet/~hq/nc.impl.hq.webservice.student.GetUseThirdpartyOrderServlet`,
    method: "get"
  },
  // pos机是否激活
  getPOSMchidServlet: {
    url: `${third}/servlet/~hq/nc.impl.hq.webservice.student.GetPOSMchidServlet`,
    method: "get"
  },
  // pos机列表
  getPOSOrderDataServlet: {
    url: `${third}/servlet/~hq/nc.impl.hq.webservice.student.GetPOSOrderDataServlet`,
    method: "get"
  }
}
