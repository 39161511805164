<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-05-20 15:09:54
 * @LastEditors: Seven
 * @LastEditTime: 2022-03-03 11:00:53
-->
<template>
  <div v-drag :class="['orange-panel', isShowzthl?'show positionAbsolute':'']" :style="{left: isShowzthl?callWidth:'-320px'}">
    <!-- <Button @click="dianji">点击</Button> -->
    <i class="el-icon-phone-outline" style="line-height: 90px;margin-right: 20px;font-size: 30px;color: green" size="30" color="#19be6b"></i>
    <div style="margin-top: 14px;">
      <div style="font-weight: bolder; font-size: 16px;">
        {{getTinetCallStatuszthl}}
        <!-- <span style="color: green;">{{statusList[$store.state.orangeCall.orangeStatus]}}: </span> -->
        <span style="color: #2d79ff" v-if="getTinetCallStatuszthl === 'ring'"
          >响铃:</span
        >
        <span style="color: green" v-if="getTinetCallStatuszthl === 'answer'"
          >通话中：</span
        >
        <span style="color: #ff1f31" v-if="getTinetCallStatuszthl === 'hangup'"
          >挂断：</span
        >
        <span style="color: #ff8905" v-if="getTinetCallStatuszthl === 'offline'"
          >离线：</span
        >
        <span style="color: #ff8905" v-if="getTinetCallStatuszthl === 'idle'"
          >空闲：</span
        >
        <span v-if="getTinetCallTel">{{ $getTel(getTinetCallTel) }}</span>
      </div>
      <!-- <el-tag size="small" effect="dark" style="margin-right: 4px">{{ CallTimes }}</el-tag> -->
      <!-- <el-tag size="small" type="success" effect="dark">{{ getTinetCallAttribution }}</el-tag> -->
    </div>
    <i @click="actionTinetDoUnLinkclick" class="stop-call el-icon-phone" size="30" color="#19be6b"></i>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import {
  reqLoginhangup
} from "api/call/tinet-api";
export default {
  data() {
    return {
      isShowzthl: false,
      statusList: {
        0: '空闲', 1: '通话中', 4: '未注册', 8: '振铃', 11: '示忙中'
      },
      timer: '',
      CallTimes: ''
    }
  },
  computed: {
    ...mapGetters(["getTinetCallStatuszthl","getTinetCallTel", "getCallTimes", "getTinetCallAttribution"]),
    callWidth() {
      return document.body.clientWidth - 340 + 'px'
    }
    // isShowzthl() {
    //   return this.$store.state.orangeCall.orangeStatus !== 0
    // }
  },
  methods: {
    ...mapActions([ "countTime", "ClearOrangetime", "actionTinetDoUnLink"]),
    dianji() {
      this.isShowzthl = true
    },
    actionTinetDoUnLinkclick() {
      this.isShowzthl = false
      let uuid = localStorage.getItem('uuid')
      reqLoginhangup(uuid).then(res => {
        if (res.code == 0) {
          this.$store.commit('setTinetCallStatuszthl', 'hangup')
          this.$store.commit('setTinetLoginStatus', {
            status: 'idle',
            type: 'success',
            show: '空闲'
          })
          this.$message.success('已挂断!')
          this.ClearOrangetime()
          return
        }
      })
    },
    // 开始计时
    getTimestrt (startTime) {
      let start_time = new Date(startTime);
      this.timer = setInterval(() => {
          let millisecond = new Date() - start_time;
          let h = Math.floor(millisecond / (60 * 60 * 1000));
          h = h < 10 ? "0" + h : h;
          let min = Math.floor((millisecond % (60 * 60 * 1000)) / (60 * 1000));
          min = min < 10 ? "0" + min : min;
          let sec = Math.floor(
              ((millisecond % (60 * 60 * 1000)) % (60 * 1000)) / 1000
          );
          sec = sec < 10 ? "0" + sec : sec;
          this.CallTimes = h + ":" + min + ":" + sec;
          console.log('进入了计时', this.CallTimes);
      }, 1000);
    },
    // 结束计时
    getTimeend() {
      clearInterval(this.CallTimes)
      this.CallTimes = '00:00:00'
      console.log('触发结束定时器', this.CallTimes);
    }
  },
  directives: {
    // 可拖动指令
    drag(el) {
      el.onmousedown = function (e) {
        let disx = e.pageX - el.offsetLeft;
        let disy = e.pageY - el.offsetTop;
        document.onmousemove = function (e) {
          el.style.left = e.pageX - disx + "px";
          el.style.top = e.pageY - disy + "px";
        };
        document.onmouseup = function () {
          document.onmousemove = document.onmouseup = null;
        };
      };
    },
  },
  watch: {
    // val值为5的时候为回呼电话，这时候通话工具条不会自动隐藏，需要手动关闭才能隐藏
    // 这里添加了一个backCall属性，来判断回呼后通话工具栏是否关闭，没有关闭的话是不能进行外呼操作的，回呼的话这里是控制不了的
    getTinetCallStatuszthl(val) {
      console.log('val唤醒',val);
      if (val == 'hangup' || val == 'idle' || val == 'offline') {
        this.isShowzthl = false
        this.getTimeend()
        } else {
          let startTime =  new Date()
          // this.getTimestrt(startTime)
          this.isShowzthl = true;
      }
      console.log('isShowzthl------------------', this.isShowzthl);
    },
  },
}
</script>
<style lang="scss" scoped>
  .orange-panel {
    width: 304px;
    height: 100px;
    box-shadow: 0 0 2px #999;
    position: fixed;
    padding: 0 10px;
    // top: 80px;
    z-index: 6000;
    background-color: #fff;
    // transition: all .3s;
    display: flex;
  }

  .show {
    right: 20px;
  }

  .hidden {
    right: -320px;
  }

  .stop-call {
    line-height: 90px;
    margin-left: 20px;
    font-size: 30px;
    color: red;
    cursor: pointer;
  }
  .positionAbsolute {
    position: absolute;
    right: 20px;
    bottom: 50px;
  }
</style>
