var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _vm.followList && _vm.followList.length > 0
            ? _c(
                "el-tab-pane",
                { attrs: { label: "跟进记录", name: "followList" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading"
                        }
                      ],
                      ref: "multipleTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.followList,
                        "tooltip-effect": "dark",
                        border: "",
                        stripe: "",
                        size: "mini",
                        "element-loading-text": "拼命加载中"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "跟进部门",
                          prop: "deptName",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "跟进人",
                          prop: "userName",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "跟进时间",
                          prop: "createTime",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "跟进详情",
                          prop: "memo",
                          "min-width": "150"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "沟通方式",
                          prop: "contactTypeName",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "下次联系时间",
                          prop: "nextTime",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "意向状态",
                          prop: "followStatusName",
                          "min-width": "150"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.findList && _vm.findList.length > 0
            ? _c(
                "el-tab-pane",
                { attrs: { label: "预约", name: "findList" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading"
                        }
                      ],
                      ref: "multipleTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.findList,
                        "tooltip-effect": "dark",
                        border: "",
                        stripe: "",
                        size: "mini",
                        "element-loading-text": "拼命加载中"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "预约校区",
                          prop: "schoolName",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "预约时间",
                          prop: "appointmentTime",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "到访状态",
                          prop: "visitStatusStr",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "归属老师",
                          prop: "userName",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "预约老师",
                          prop: "teacherName",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "到访时间",
                          prop: "visitedTime",
                          "min-width": "150"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.processList && _vm.processList.length > 0
            ? _c(
                "el-tab-pane",
                { attrs: { label: "流转记录", name: "processList" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading"
                        }
                      ],
                      ref: "multipleTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.processList,
                        "tooltip-effect": "dark",
                        border: "",
                        stripe: "",
                        size: "mini",
                        "element-loading-text": "拼命加载中"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "流程编号",
                          prop: "processId",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "部门",
                          prop: "deptName",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "归属人",
                          prop: "userName",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "跟进次数",
                          prop: "followCount",
                          "min-width": "80"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "说明",
                          prop: "memo",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "创建人",
                          prop: "createName",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "创建时间",
                          prop: "createTime",
                          "min-width": "150"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.repeatList && _vm.repeatList.length > 0
            ? _c(
                "el-tab-pane",
                { attrs: { label: "重咨记录", name: "repeatList" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading"
                        }
                      ],
                      ref: "multipleTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.repeatList,
                        "tooltip-effect": "dark",
                        border: "",
                        stripe: "",
                        size: "mini",
                        "element-loading-text": "拼命加载中"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "创建人",
                          prop: "createName",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "创建时间",
                          prop: "createTime",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "商机来源",
                          prop: "sourceStr",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "备注",
                          prop: "memo",
                          "min-width": "150"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.invalidList && _vm.invalidList.length > 0
            ? _c(
                "el-tab-pane",
                { attrs: { label: "质检记录", name: "invalidList" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading"
                        }
                      ],
                      ref: "multipleTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.invalidList,
                        "tooltip-effect": "dark",
                        border: "",
                        stripe: "",
                        size: "mini",
                        "element-loading-text": "拼命加载中"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "标记无效时间",
                          prop: "createTime",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "质检时间",
                          prop: "bizCreateTime",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "无效原因",
                          prop: "typeName",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "质检详情",
                          prop: "typeName",
                          "min-width": "150"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "状态",
                          prop: "statusName",
                          "min-width": "150"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      style: {
                                        color:
                                          scope.row.status === 0
                                            ? "#A30014"
                                            : "#4B7902"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(scope.row.statusName) + " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3980994196
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "质检人",
                          prop: "inspectUserName",
                          "min-width": "150"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.reserveList && _vm.reserveList.length > 0
            ? _c(
                "el-tab-pane",
                { attrs: { label: "留存记录", name: "reserveList" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading"
                        }
                      ],
                      ref: "multipleTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.reserveList,
                        "tooltip-effect": "dark",
                        border: "",
                        stripe: "",
                        size: "mini",
                        "element-loading-text": "拼命加载中"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "流程编号",
                          prop: "processId",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "部门",
                          prop: "deptName",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "归属销售",
                          prop: "userName",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "留存类型",
                          prop: "statusStr",
                          "min-width": "150"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "流程记录创建人",
                          prop: "createName",
                          "min-width": "150"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "留存记录创建时间",
                          prop: "createTime",
                          "min-width": "150"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.conversationList && _vm.conversationList.length > 0
            ? _c(
                "el-tab-pane",
                { attrs: { label: "客服对话", name: "conversationList" } },
                [
                  _c(
                    "ul",
                    { staticClass: "ul" },
                    [
                      _vm._l(_vm.conversationList, function(item, index) {
                        return [
                          item.sayFrom === "客服"
                            ? _c("li", { key: index }, [
                                _c("div", { staticClass: "circle left" }),
                                _c("div", { staticClass: "content-box left" }, [
                                  _c("p", { staticClass: "time" }, [
                                    _vm._v(_vm._s(item.msgTime))
                                  ]),
                                  _c("div", {
                                    staticClass: "content",
                                    domProps: {
                                      innerHTML: _vm._s(item.message)
                                    }
                                  })
                                ])
                              ])
                            : _vm._e(),
                          item.sayFrom === "系统消息"
                            ? _c(
                                "li",
                                {
                                  key: index,
                                  staticStyle: { "text-align": "center" }
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(item.message)
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          item.sayFrom === "访客"
                            ? _c("li", { key: index }, [
                                _c("div", { staticClass: "circle right" }),
                                _c(
                                  "div",
                                  { staticClass: "content-box right" },
                                  [
                                    _c("p", { staticClass: "time" }, [
                                      _vm._v(_vm._s(item.msgTime))
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "content-right" },
                                      [_vm._v(" " + _vm._s(item.message) + " ")]
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                ]
              )
            : _vm._e(),
          _vm.nominateTalkShow
            ? _c(
                "el-tab-pane",
                { attrs: { label: "话术推荐", name: "nominateTalk" } },
                [_c("nominate-talk", { ref: "talk" })],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }