/*
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-18 15:33:58
 * @LastEditors: Seven
 * @LastEditTime: 2021-07-26 17:54:16
 */
import axios from "axios";

const baseUrl = "/qw_api";

/**
 * 字典
 */
// 获取字典项
export const reqDictByCode = (code) => {
  return axios.get(`${baseUrl}/sys/dict/find/` + code).then((res) => res.data);
}
