/*
 * @Author: 刘锦
 * @Date: 2020-10-10 10:08:32
 * @LastEditTime: 2021-08-25 17:05:31
 * @LastEditors: in hengqi by mengze
 * @Description: 
 * @FilePath: \workbench-front\src\store\modules\workbench.js
 */
import $fetch from '@/utils/fetch'
export default {
  state: {
    projectList: [],
    goodsRank: [],
    goodsTypes: [],
    dropOutOrderId: ''
  },
  mutations: {
    setProjectList(state, value) {
      state.projectList = value
    },
    setGoodsRank(state, value) {
      state.goodsRank = value
    },
    setGoodsTypes(state, value) {
      state.goodsTypes = value
    },
    // 设置订单售后 退费持久化的orderid
    setDropOutOrderId(state, value) {
      state.dropOutOrderId = value
    }
  },
  actions: {
    // 获取产品线下拉框
    getProjectList({ commit }, schoolId) {
      let params = {}
      params["statusSchool"] = 1;
      // eslint-disable-next-line no-async-promise-executor
      return new Promise( async (resolve, ) => {
        const { data } = await $fetch("thrid_getAllProduct", params
        )
        commit("setProjectList", data)
        resolve()
      })
    },

    // 获取商品等级下拉框
    getGoodsRank({ commit }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        const { data } = await $fetch("getGoodsRankSelect")
        const result = []
        Object.keys(data[0]).forEach(key => {
          result.push({
            value: key,
            label: data[0][key]
          })
        })
        commit("setGoodsRank", result)
        resolve()
      })
    },
    // 获取商品类型下拉框
    getGoodsTypes({ commit }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        const { data } = await $fetch("getGoodsCategoriesSelect")
        commit('setGoodsTypes', data)
        resolve()
      })
    },
  }
}