<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-11-02 14:08:58
 * @LastEditors: Seven
 * @LastEditTime: 2021-12-27 09:36:53
-->
<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :close-on-click="false"
    :return-value.sync="year"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :disabled="disabled"
        :value="time"
        label="下次联系时间"
        hide-details="auto"
        persistent-hint
        outlined
        readonly
        :dense="true"
        :height="32"
        :clearable="true"
        v-bind="attrs"
        v-on="on"
        @click:clear="timeClear"
      ></v-text-field>
    </template>
    <div>
      <v-spacer></v-spacer>
      <div class="time-input">
          <el-input v-model="year" placeholder="日期" style="width: 130px;margin-right: 10px" />
          <el-time-picker
              format='HH:mm:ss'
              style="width: 130px"
              v-model="hour"
              :picker-options="{
                selectableRange: '00:00:00 - 23:59:59'
            }"
            placeholder="时间">
        </el-time-picker>
      </div>
    </div>
    <v-date-picker v-model="year" no-title locale="zh-cn">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">取消</v-btn>
      <v-btn
        text
        color="primary"
        @click="change"
      >
        确认
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
import { formatTimeHour } from "@/utils/timeDate";
export default {
  props: {
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      menu: false,
      hour: new Date(),
      year: "",
      time: ""
    }
  },
  methods: {
    change() {
      if(this.year) {
        if(this.hour) {
          this.time = this.year + ' ' + formatTimeHour(this.hour)
        } else {
          this.time = this.year + ' ' + formatTimeHour(new Date(2021, 12, 27))
        }
      } else {
        this.time = ""
      }
      this.$emit('update:select', this.time);
      this.$refs.menu.save(this.year)
    },
    timeClear() {
      this.clear()
      this.$emit('update:select', "");
    },
    clear() {
      this.time = ""
      this.year = ""
      this.hour = ""
    }
  }
}
</script>
<style lang="scss" scoped>
  .time-input {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    border-bottom: 1px solid #d2d8e5;
    background: #fff;
  }
</style>
