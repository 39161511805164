<!--
 * @Description: 话术推荐
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-11-03 09:40:59
 * @LastEditors: Seven
 * @LastEditTime: 2021-12-20 17:56:09
-->
<template>
  <el-row>
    <el-col :xs="24" :sm="10">
      <el-card>
        <p slot="header">标签选择</p>
        <div class="main_label">
          <div class="main_box" v-if="tabContents.length">
            <div
              class="li"
              v-for="(item, index) in navList"
              :key="index"
              :class="{ active: index == num }"
              @click="tabClick(index)"
            >
              {{ item }}
            </div>
          </div>
          <div class="tabCon" v-if="tabContents.length">
            <!-- 全部 -->
            <div
              v-show="0 == num"
              v-for="(i, index) in tabContents[0]"
              :key="index"
            >
              <li class="tab_com_li">{{ i.groupName }}</li>
              <div class="tab_com_tag" v-if="i.labelList">
                <template v-for="v in i.labelList">
                  <span
                    :key="v.sysLabelId"
                    class="tag_list"
                    :style="{
                      backgroundColor: `${v.used ? '#19be6b' : ''}`,
                      color: `${v.used ? '#fff' : ''}`,
                    }"
                    @click="tagListClick(v)"
                    >{{ v.labelName }}</span
                  >
                </template>
              </div>
              <div
                style="text-align: center; line-height: 40px"
                v-if="!i.labelList"
              >
                暂无标签
              </div>
            </div>
            <!-- 非全部 -->
            <div
              v-show="0 != num"
              v-for="(item, index) in tabContents[num]"
              :key="index + item.groupName"
            >
              <li class="tab_com_li">{{ item.groupName }}</li>
              <div class="tab_com_tag" v-if="item.labelList">
                <template v-for="v in item.labelList">
                  <span
                    class="tag_list"
                    :key="v.labelId"
                    :style="{
                      backgroundColor: `${v.used ? '#19be6b' : ''}`,
                      color: `${v.used ? '#fff' : ''}`,
                    }"
                    @click="tagListClick(v)"
                    >{{ v.labelName }}</span
                  >
                </template>
              </div>
              <div
                style="text-align: center; line-height: 40px"
                v-if="!item.labelList"
              >
                暂无标签
              </div>
            </div>
          </div>
          <div
            v-if="tabContents.length === 0"
            style="width: 100%; line-height: 300px; text-align: center"
          >
            暂无数据
          </div>
        </div>
      </el-card>
    </el-col>
    <el-col :xs="24" :sm="14">
      <el-card>
        <p slot="header">话术推荐</p>
        <span>选中标签：</span>
        <el-tag
          v-for="item in selectLabelList"
          :key="item.id + item.name"
          ref="Tag"
          size="small"
          color="#47cb89"
          closable
          @close="handleClose(item)"
          style="color: #fff; margin: 0 3px 6px 0"
        >
          {{ item.name }}
        </el-tag>
        <div style="text-align: right; margin-bottom: 6px">
          <el-button size="small" type="primary" @click="searchContent"
            >查询</el-button
          >
          <el-button size="small" type="danger" @click="reset">重置</el-button>
        </div>
        <div v-if="contentList.length" style="height: 455px; overflow-y: auto">
          <el-card
            class="content_box"
            v-for="item in contentList"
            :key="item.markId"
          >
            <el-tag v-for="(v, index) in item.labelList" :key="index">{{
              v
            }}</el-tag>
            <div class="content" v-html="item.verbalTrickContent"></div>
          </el-card>
        </div>
        <el-card
          v-if="!contentList.length"
          style="height: 455px; line-height: 200px; text-align: center"
        >
          暂无数据
        </el-card>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import {
  getgroupListPage,
  getVerbalTrickListPage,
  getGroupLabel,
} from "api/customer";

export default {
  name: "nominateTalk",
  data() {
    return {
      queryParam: {
        select: "",
      },
      // 已选标签
      selectLabelList: [],
      // 内容列表
      contentList: [],
      navList: ["全部"],
      // 分组
      tabContents: [],
      num: 0,
      trackType: null,
      url: "",
    };
  },
  methods: {
    init(value) {
      this.trackType = value.trackType;
      this.url = value.url;
      this.getgroupListPage();
    },
    async getgroupListPage() {
      let res = null;
      if (this.url) {
        let params = {
          url: this.url
        }
        res = await getGroupLabel(params);
      }
      let data = await getgroupListPage(this.trackType);
      let { code, result } = data;
      if (code === 0) {
        this.tabContents = [];
        this.navList = ["全部"];
        this.tabContents.push(result);
        let selectLabelList = [],
          selectIdList = [];
        result.map((item) => {
          if (item.labelList) {
            item.labelList.map((i) => {
              // debugger
              if (res && res.result && res.result.indexOf(i.labelId) !== -1) {
                i.click = true;
                i.used = true;
              } else {
                i.click = false;
                i.used = false;
              }
              if (i.used) {
                selectLabelList.push({
                  name: item.groupName + "：" + i.labelName,
                  id: i.labelId,
                });
                selectIdList.push(i.labelId);
              }
            });
          }
          this.navList.push(item.groupName);
          this.tabContents.push([item]);
        });
        this.selectLabelList = selectLabelList;
        this.selectIdList = selectIdList;
        if (this.selectLabelList.length !== 0) {
          this.searchContent();
        }
      }
    },
    tabClick(index) {
      this.num = index;
    },
    // 选择事件
    async tagListClick(node) {
      let selectLabelList = [];
      let selectIdList = [];
      this.tabContents[0].map((item) => {
        item.labelList &&
          item.labelList.map((v) => {
            if (node.labelId === v.labelId) {
              v.used = !v.used;
            }
            if (v.used) {
              selectLabelList.push({
                name: item.groupName + "：" + v.labelName,
                id: v.labelId,
              });
              selectIdList.push(v.labelId);
            }
          });
      });
      this.selectLabelList = selectLabelList;
      this.selectIdList = selectIdList;
      if (this.selectLabelList.length === 0) {
        this.contentList = [];
      }
      this.$forceUpdate();
    },
    searchContent() {
      if (this.selectIdList.length === 0) return false;
      getVerbalTrickListPage({ labelIds: this.selectIdList }).then((data) => {
        let { code, msg, result } = data;
        if (code === 0) {
          this.contentList = result;
        } else {
          this.$Message.error(msg);
        }
      });
    },
    // 重置
    reset() {
      this.selectLabelList = [];
      this.selectIdList = [];
      this.contentList = [];
      this.getgroupListPage();
    },
    handleClose(value) {
      this.selectLabelList = this.selectLabelList.filter(
        (item) => item.id !== value.id
      );
      this.selectIdList = this.selectIdList.filter((item) => item !== value.id);
      this.tabContents.map((item) => {
        item.map((i) => {
          i.labelList &&
            i.labelList.map((v) => {
              if (v.labelId === value.id) {
                v.used = false;
              }
            });
        });
      });
      if (this.selectLabelList.length === 0) {
        this.contentList = [];
      }
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
.tab_com_li {
  margin: 10px 0 0 10px;
  font-weight: 600;
}
.tab_com_tag {
  margin: 10px 0 0 40px;

  .tag_list {
    display: inline-block;
    line-height: 24px;
    font-size: 12px;
    padding: 0 6px;
    text-align: center;
    border: 1px solid #ebeef5;
    cursor: pointer;
    margin-bottom: 14px;
    min-width: 62px;
  }
}
.content_box {
  margin-bottom: 6px;
}
.content {
  margin-top: 10px;
}

.main_label {
  display: flex;
}

.main_box {
  flex: 2;
  border-right: 1px solid #ebeef5;
  margin-top: 10px;
  height: 455px;
  overflow-y: auto;

  .li {
    list-style: none;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    white-space: nowrap;
    padding-right: 4px;
  }
  .active {
    background-color: #ccf3fd;
  }
}
.tabCon {
  flex: 5;
  height: 516px;
  overflow-y: auto;

  .tab_con_clue {
    line-height: 22px;
    transform: scale(0.9);
    margin-left: 8px;
  }

  .tab_com_li {
    margin: 10px 0 0 10px;
    font-weight: 600;
  }
  .tab_com_tag {
    margin: 10px 0 0 40px;

    .tag_list {
      display: inline-block;
      line-height: 24px;
      font-size: 12px;
      padding: 0 6px;
      text-align: center;
      border: 1px solid #ebeef5;
      cursor: pointer;
      margin-bottom: 14px;
      min-width: 62px;
    }
  }
}
</style>

