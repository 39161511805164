/**
 * 数据类型校验类
 */
class Checker {
    static isObject(value){
        return Object.prototype.toString.call(value).slice(8, -1) === 'Object'
    }
    static isArray(value){
        return Object.prototype.toString.call(value).slice(8, -1) === 'Array'
    }
    static isNull(value){
        return Object.prototype.toString.call(value).slice(8, -1) === 'Null'
    }
    static isNumber(value){
        return isNaN(value) ? false : Object.prototype.toString.call(value).slice(8, -1) === 'Number'
    }
    static isBoolean(value){
        return Object.prototype.toString.call(value).slice(8, -1) === 'Boolean'
    }
    static isString(value){
        return Object.prototype.toString.call(value).slice(8, -1) === 'String'
    }
    static isUndefined(value){
        return Object.prototype.toString.call(value).slice(8, -1) === 'Undefined'
    }
    static isMap(value){
        return Object.prototype.toString.call(value).slice(8, -1) === 'Map'
    }
    static isSet(value){
        return Object.prototype.toString.call(value).slice(8, -1) === 'Set'
    }
    static isFormData(value){
        return Object.prototype.toString.call(value).slice(8, -1) === 'FormData'
    }
}
export default Checker