var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.list.length > 0
    ? _c("div", { staticClass: "check-box" }, [
        _vm.label
          ? _c("span", { staticClass: "tag-name" }, [_vm._v(_vm._s(_vm.label))])
          : _vm._e(),
        _vm.list.length
          ? _c(
              "div",
              { staticClass: "label-list" },
              _vm._l(_vm.list, function(item, index) {
                return _c(
                  "span",
                  {
                    key: item.value,
                    class: ["label", _vm.active == item.value ? "active" : ""],
                    style: {
                      marginLeft: _vm.list.length === 1 ? "0" : "10px",
                      padding: _vm.list.length === 1 ? "0 36px" : "0 16px"
                    },
                    on: {
                      click: function($event) {
                        return _vm.labelClick(item, index)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.label))]
                )
              }),
              0
            )
          : !_vm.list.length
          ? _c(
              "span",
              {
                class: ["label", _vm.select == 1 ? "active" : ""],
                staticStyle: { padding: "0 36px", "margin-left": "0" },
                on: {
                  click: function($event) {
                    return _vm.labelClick(_vm.list[0])
                  }
                }
              },
              [_vm._v(_vm._s(_vm.list[0].label))]
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }