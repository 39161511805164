/*
 * @Descripttion:
 * @version:
 * @Author: 刘锦
 * @Date: 2020-08-18 09:41:52
 * @LastEditors: in hengqi by mengze
 * @LastEditTime: 2021-07-21 15:50:32
 */
// import axios from 'axios'
// import userApi from '@/api/home/user'
const tokenKey = 'token'
const userKey = 'gzt_user'
const onlineSchoolTokenKey = 'onlineSchoolToken'
const auth = {
  getToken() {
    try {
      return localStorage.getItem(tokenKey) || null
    }
    catch (e) {
      this.removeToken()
    }
  },
  setToken(token) {
    return localStorage.setItem(tokenKey, token)
  },
  removeToken() {
    return localStorage.removeItem(tokenKey)
  },
  getOnlineSchoolToken() {
    try {
      return localStorage.getItem(onlineSchoolTokenKey) || null
    }
    catch (e) {
      this.removeToken()
    }
  },
  setOnlineSchoolToken(token) {
    return localStorage.setItem(onlineSchoolTokenKey, token)
  },
  removeOnlineSchoolToken() {
    return localStorage.removeItem(onlineSchoolTokenKey)
  },
  getUserInfo() {
    try {
      return JSON.parse(localStorage.getItem(userKey) || {})
    }
    catch (e) {
      this.removeUserInfo()
    }
  },
  setUserInfo(userinfo) {
    return localStorage.setItem(userKey, JSON.stringify(userinfo))
  },
  removeUserInfo() {
    return window.localStorage.removeItem(userKey)
  },
  getItem(key) {
    try {
      return JSON.parse(localStorage.getItem(key))
    } catch (e) {
      localStorage.removeItem(key)
    }

  },
  setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  /**
   * 检查token
   * @param {Function} callback 回调 
   * @param {Boolean} redirectLogin 跳转登录页
   * @returns {Promise}
   */
  checkToken( /*callback= undefined, redirectLogin= false*/){
  //   const cb = typeof callback === 'function'
  //   //token状态
  //   const tokenStatus = {
  //     Invalid: 0,//无效
  //     Valid: 1,//有效
  //     Unknow: 2,//未知 有可能是网络因素等状况
  //   }
  //   const errResult = {tokenStatus, status: tokenStatus.Invalid}
  //   const unknowResult = {tokenStatus, status: tokenStatus.Unknow}
  //   return new Promise(resolve =>{
  //     // if(!localStorage.getItem(tokenKey)){
  //     //   redirectLogin && window.vue.$router.replace({path: '/login', query: {redirect_url: location.href}})
  //     //   cb && callback(errResult)
  //     //   resolve(errResult)
  //     // }
  //     axios.get(userApi.home_getUserBaseInfo.url, {
  //       headers: {
  //         'hqjy-share-token': localStorage.getItem(tokenKey)
  //       }
  //     })
  //     .then(res => {
  //       const { code } = res.data
  //       let result = {tokenStatus, status: res.status !== 200 ? tokenStatus.Unknow :  code === 200 ? tokenStatus.Valid : tokenStatus.Invalid}
  //       cb && callback(result)
  //       resolve(result)
  //     })
  //     .catch(err =>{
  //       cb && callback(unknowResult)
  //       resolve(unknowResult)
  //     })
      
  //   })
  }
}
export default auth