class ValidateType {
  // 校验手机
  static validatePhone (rule, value, callback) {
    if (value === '') {
      if (!rule.required) {
        callback()
      } else {
        callback(new Error('请输入手机号码'))
      }
    } else {
      let reg = /^1[3-9]\d{9}$/
      // let reg = /^$|^((13[0-9])|(14[0-9])|(15[^4,\D])|(16[0-9])|(17[0-9])|(18[0-9])|(19[0-9]))\d{8}$/
      let texterr = '手机号码格式不正确，数字1开头，第二位为3～9的长度为纯11位数字'
      if (!reg.test(value)) {
        callback(texterr)
      }
      callback()
    }
  }
  // 校验邮箱
  static validateEmail (rule, value, callback) {
    if (value === '') {
      callback(new Error('请输入邮箱'))
    } else {
      let reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/
      let texterr = '邮箱格式错误，请重新输入'
      if (!reg.test(value)) {
        callback(texterr)
      }
      callback()
    }
  }
  // 校验座机
  static validateTel (rule, value, callback) {
    if (value === '') {
      if(!rule.required) {
        callback()
      } else {
        callback(new Error('请输入座机'))
      }
    } else {
      // let reg = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/
      // let reg1 = /^(([0\+]\d{2,3}-)?(0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/.test(value);//带区号的固定电话
      let reg1 = /^(?:(0\d{2,3}-)?\d{7,8}|1[3-9]\d{9})$/.test(value);//带区号的固定电话
      let reg2 = /^(0\d{2,3}-)?\d{7,8}$/.test(value);//不带区号的固定电话
      // let reg2 = /^(\d{7,8})(-(\d{3,}))?$/.test(value);//不带区号的固定电话

      // let reg = /^\d{7,13}$/
      let texterr = '座机号填写错误，请按 0xx-xxxxxxxx 或 0xxx-xxxxxxx 填写，或直接填写7位或8位数字'
      // let texterr = '系统校验座机可同时支持手机号码和座机号码正则表达式'
      if (!reg1 && !reg2) {
        callback(texterr)
      }
      callback()
    }
  }
  // 校验qq
  static validateQQ (rule, value, callback) {
    if (value === '') {
      if(!rule.required) {
        callback()
      } else {
        callback(new Error('请输入QQ'))
      }
    } else {
      // let reg = /^[1-9][0-9]{4,11}$/gim
      // let texterr = 'QQ填写错误，请填写5到12位的纯数字'
      let reg = /^[1-9][0-9]{4,12}$/gim
      let texterr = 'QQ填写错误，第一位不能是0，请填写5到12位的纯数字'
      if (!reg.test(value)) {
        callback(texterr)
      }
      callback()
    }
  }
  // 校验微信
  static validateweiXin (rule, value, callback) {
    if (value === '') {
      if(!rule.required) {
        callback()
      } else {
        callback(new Error('请输入微信'))
      }
    } else {
      // let reg = /^[a-zA-Z0-9]([-_a-zA-Z0-9]{5,19})+$/
      // let texterr = '微信格式不正确'
      // if (!reg.test(value)) {
      //   callback(texterr)
      // }
      let reg = /^[a-zA-Z][a-zA-Z\d_-]{5,19}$/
      let reg1 = /^1[3-9]\d{9}$/
      let reg2 = /^[1-9][0-9]{4,12}$/

      // let reg = /^[a-zA-Z0-9]([-_a-zA-Z0-9]{5,19})+$/
      let texterr = '必须以字母开头，使用6-20个字母、数字、下划线和减号。可以是QQ号和手机号'
      // let texterr1 = '手机号码格式不正确，数字1开头，第二位为3～9的长度为纯11位数字'
      // let texterr2 = 'QQ填写错误，第一位不能是0，请填写5到12位的纯数字'
      // console.log('1',reg.test(value));
      // console.log('2',reg1.test(value));
      // console.log('3',reg2.test(value));
      // let texterr = '微信格式不正确'
      if (reg.test(value) || reg1.test(value) || reg2.test(value)) {
        callback()
      }
      callback(texterr)
    }
  }
  // 校验密码长度为8-20位,字母区分大小写,
  static validatePassWord (rule, value, callback) {
    if (value === '') {
      callback(new Error('请输入密码'))
    } else {
      let reg = /^\S*([a-zA-Z]+\S*[0-9]+|[0-9]+\S*[a-zA-Z]+)\S*$/
      let texterr = '密码长度为8-20位,字母区分大小写'
      if (value.length < 8) {
        callback(texterr)
      }
      if (!reg.test(value)) {
        callback(texterr)
      }
      callback()
    }
  }
  // 手机或者座机
  // 校验手机
  static validatePhoneOrTel (rule, value, callback) {
    if (value === '') {
      callback(new Error('请输入手机号码'))
    } else {
      let reg = /(^(\d{3,4}-)?\d{7,8})$|(^0?(13[0-9]|15[0-9]|18[0-9]|16[0-9]|14[57]|17[0-9]|19[0-9])[0-9]{8})$/
      let texterr = '手机号码或座机格式不正确'
      if (!reg.test(value)) {
        callback(texterr)
      }
      callback()
    }
  }
  // 校验qq邮箱
  // 校验邮箱
  static validateQQEmail (rule, value, callback) {
    if (value === '') {
      callback(new Error('请输入QQ邮箱'))
    } else {
      let reg = /^\d+@qq\.com$/
      let texterr = 'QQ邮箱格式不正确'
      if (!reg.test(value)) {
        callback(texterr)
      }
      callback()
    }
  }
  static idCard(rule, val, cb) {
    if(!val) {
      return cb(new Error('请输入身份证号码'))
    }
    // const re = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
    // if (re.test(val)) {
    //   return cb()
    // }
    window.vue.$fetch("doIdCardValidate", {}, {idCard: val}).then(res => {
      if(res.code == 200) {
        return cb()
      }
    }).catch(res => {
      if(res.code == 500) {
        cb(new Error('身份证号码格式不对'))    
      }
    })
  }
  static NotNecessaryIdCard(rule, val, cb) {
    if(!val){
      return cb()
    }
    window.vue.$fetch("doIdCardValidate", {}, {idCard: val}).then(res => {
      if(res.code == 200) {
        return cb()
      }
    }).catch(res => {
      if(res.code == 500) {
        cb(new Error('身份证号码格式不对'))    
      }
    })
  }
  static validateName(rule, val, cb) {
    if(!val) {
      return cb(new Error('请输入学员姓名'))
    }
    var re = /^[\u4e00-\u9fa5]([\u4e00-\u9fa5•·]){0,13}[\u4e00-\u9fa5]$/
    // var re = /^[\u4e00-\u9fa5]{1,6}([•|·][\u4e00-\u9fa5]{1,6}){0,2}$/;
    if (re.test(val)) {
      return cb()
    }
    cb(new Error('学员姓名格式不正确'))
  }
  static digital(rule, val, cb) {
    if(!val) {
      return cb(new Error('此字段必填！'))
    }
    const re = /^\d+$/
    if (re.test(val)) {
      if(val.length === 11) {
        return cb()
      } else {
        cb(new Error("必须输入11位数"))
      }
    }
    cb(new Error("必须为数字"))
  }
  // 就业协议 大专以下
  static underCollege(rule, val, cb) {
    if(val === '') {
      return cb(new Error('此字段必填！'))
    }
    
    const re1 = /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/
    if (!re1.test(val)) {
      cb(new Error("输入格式不对"))
    } else if(val > 6000) {
      cb(new Error('输入金额不能大于6000'))
    }

    return cb()

  }
  // 就业协议 大专以上
  static upCollege(rule, val, cb) {
    if(val === '') {
      return cb(new Error('此字段必填！'))
    }
    
    const re1 = /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/
    if (!re1.test(val)) {
      cb(new Error("输入格式不对"))
    } else if(val > 8000) {
      cb(new Error('输入金额不能大于8000'))
    }

    return cb()

  }
}
export default ValidateType
