var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "message" },
    [
      _c(
        "el-popover",
        {
          attrs: { placement: "bottom-end", width: 340, trigger: "click" },
          scopedSlots: _vm._u([
            {
              key: "reference",
              fn: function() {
                return [
                  _c(
                    "el-badge",
                    {
                      staticClass: "badge",
                      attrs: { value: _vm.value, type: "danger", max: 99 }
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-bell",
                        staticStyle: { "font-size": "18px" },
                        on: { click: _vm.messageClick }
                      })
                    ]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "div",
            { staticClass: "content hq-tabs" },
            [
              _c(
                "el-tabs",
                {
                  attrs: { stretch: true },
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.msgTabs,
                    callback: function($$v) {
                      _vm.msgTabs = $$v
                    },
                    expression: "msgTabs"
                  }
                },
                _vm._l(_vm.messageList, function(item, i) {
                  return _c(
                    "el-tab-pane",
                    { key: i, attrs: { name: item.name } },
                    [
                      _c(
                        "el-badge",
                        {
                          attrs: {
                            slot: "label",
                            value: item.value > 0 ? item.value : "",
                            type: "danger",
                            max: 99
                          },
                          slot: "label"
                        },
                        [_c("span", [_vm._v(_vm._s(item.label))])]
                      ),
                      _vm.msgList.length > 0
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "msg-alert",
                                  staticStyle: {
                                    "max-height": "450px",
                                    "overflow-y": "auto"
                                  }
                                },
                                [
                                  _vm._l(_vm.msgList, function(item, index) {
                                    return _c(
                                      "el-alert",
                                      {
                                        key: index,
                                        staticStyle: {
                                          "margin-bottom": "4px",
                                          cursor: "pointer"
                                        },
                                        attrs: {
                                          "show-icon": "",
                                          type:
                                            item.readFlag === 0
                                              ? "success"
                                              : "info",
                                          closable: false
                                        }
                                      },
                                      [
                                        _c("i", {
                                          attrs: {
                                            slot: "icon",
                                            type:
                                              item.readFlag === 0
                                                ? "android-mail"
                                                : "android-drafts",
                                            size: "20"
                                          },
                                          slot: "icon"
                                        }),
                                        _c(
                                          "template",
                                          { slot: "title" },
                                          [
                                            _c(
                                              "el-row",
                                              {
                                                staticStyle: { width: "270px" },
                                                attrs: {
                                                  justify: "center",
                                                  align: "middle",
                                                  gutter: 8
                                                }
                                              },
                                              [
                                                _c(
                                                  "el-col",
                                                  {
                                                    attrs: {
                                                      xs: 16,
                                                      sm: 16,
                                                      md: 16,
                                                      lg: 16
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.jumpCustomer(
                                                              item
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "msg-title"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.title)
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "msg-content"
                                                          },
                                                          [
                                                            _c("a", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.content
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    attrs: {
                                                      xs: 8,
                                                      sm: 8,
                                                      md: 8,
                                                      lg: 8
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "el-row",
                                                      {
                                                        attrs: {
                                                          type: "flex",
                                                          justify: "end"
                                                        }
                                                      },
                                                      [
                                                        _c("el-col", {
                                                          attrs: { span: 9 }
                                                        }),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 6 }
                                                          },
                                                          [
                                                            _c(
                                                              "el-tooltip",
                                                              {
                                                                attrs: {
                                                                  content:
                                                                    "已读",
                                                                  placement:
                                                                    "top",
                                                                  delay: 500
                                                                }
                                                              },
                                                              [
                                                                item.readFlag ===
                                                                0
                                                                  ? _c("i", {
                                                                      staticClass:
                                                                        "el-icon-open",
                                                                      attrs: {
                                                                        size:
                                                                          "18",
                                                                        color:
                                                                          "#47cb89"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.readMsg(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    })
                                                                  : _vm._e()
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c("el-col", {
                                                          attrs: { span: 3 }
                                                        }),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 6 }
                                                          },
                                                          [
                                                            _c(
                                                              "el-tooltip",
                                                              {
                                                                attrs: {
                                                                  content:
                                                                    "删除",
                                                                  placement:
                                                                    "top",
                                                                  delay: 500
                                                                }
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-close",
                                                                  attrs: {
                                                                    size: "18",
                                                                    color: "red"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.deleteMsg(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                })
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-row",
                                                      [
                                                        _c("el-col", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "msg-content",
                                                              staticStyle: {
                                                                "text-align":
                                                                  "right"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-tooltip",
                                                                {
                                                                  attrs: {
                                                                    content:
                                                                      item.createTime,
                                                                    placement:
                                                                      "top",
                                                                    "open-delay": 500
                                                                  }
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.timeago(
                                                                          item.createTime
                                                                        )
                                                                      )
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      2
                                    )
                                  }),
                                  _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _vm.loadMoreBtn
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: { click: _vm.loadMore }
                                            },
                                            [_vm._v("加载更多")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                2
                              ),
                              _c("br"),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { size: "small", type: "success" },
                                  on: {
                                    click: function($event) {
                                      return _vm.cleanMessage(item.name)
                                    }
                                  }
                                },
                                [_vm._v("清空消息")]
                              )
                            ],
                            1
                          )
                        : _c("div", [_vm._v("暂无消息")])
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }