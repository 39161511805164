<!--
 * @Description: 无效
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-18 14:20:02
 * @LastEditors: Seven
 * @LastEditTime: 2021-09-06 09:42:46
-->
<template>
  <span>
    <el-button
      :disabled="currCol.status === 1"
      type="success"
      class="hq-button"
      icon="el-icon-delete"
      @click="invalidShow = true"
      >无效</el-button
    >
    <HqModal :visible="invalidShow" title="无效" width="400" @on-cancel="cancel">
      <el-form ref="form" :model="form" :rules="invalidRules">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item prop="type">
              <dict-select code="INVALID_TYPE">
                <v-select
                  v-model="form.type"
                  slot-scope="props"
                  :items="props.dictData"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="类型"
                  item-text="label"
                  item-value="value"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                ></v-select>
              </dict-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="memo">
              <v-textarea
                v-model="form.memo"
                outlined
                :height="80"
                name="input-7-4"
                label="备注"
                value=""
              ></v-textarea>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="save" type="primary">确认</el-button>
      </div>
    </HqModal>
  </span>
</template>
<script>
import dictSelect from "components/dictSelect";
import { doSetCustomerInvalid } from "api/customer";
export default {
  name: "invalid",
  props: {
    currCol: {
      type: Object,
    },
    method: {
      type: Function,
    },
    detailInit: {
      type: Function
    },
  },
  components: {
    dictSelect,
  },
  data() {
    return {
      invalidShow: false,
      form: {
        type: "",
        memo: "",
        customerId: "",
        successStatus: null
      },
      invalidRules: {
        type: [{ required: true, message: "请选择类型" }],
        memo: [{ required: true, message: "请填写备注", trigger: "blur" }]
      },
    };
  },
  methods: {
    // 保存
    async save() {
      this.$refs['form'].validate(async (valid) => {
        if(valid) {
          this.form.customerId = this.currCol.customerId
          this.form.successStatus = this.currCol.status
          const { code, msg } = await doSetCustomerInvalid(this.form)
          if(code === 0) {
            this.$message.success(msg)
            this.cancel()      
            this.method && this.method()
            this.detailInit && this.detailInit(this.currCol)
          } else {
            this.$message.error(msg)
            this.cancel()
          }
        } 
      })
    },
    // 取消
    cancel() {
      this.invalidShow = false;
      this.form = {
        type: "",
        memo: "",
        customerId: "",
        successStatus: null
      }
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
