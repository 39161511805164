/*
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-22 17:22:25
 * @LastEditors: Seven
 * @LastEditTime: 2021-11-10 17:29:40
 */
import axios from "axios";

const baseUrl = "/qw_api";
const baseseeaiUrl = "/seeai_api"

// 上传头像
export const doUserAvatar = (file) => {
  return axios({
    method: "post",
    url: `${baseUrl}/workbench/user/avatar`, 
    data: file,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }).then((res) => res.data);
}
// 修改密码
export const doUpdatePassword = (data) => {
  return axios.post(`${baseUrl}/workbench/user/updatePassword`, data).then((res) => res.data);
}
// 全局查询
export const getSelectByDetail = (data) => {
  return axios.post(`${baseUrl}/workbench/biz/selectByDetail`, data).then((res) => res.data);
}

// 首页-我的地盘
export const getHomestatisticsBiz = (data) => {
  return axios.get(`${baseUrl}/workbench/biz/statisticsBiz`, data).then((res) => res.data)
}

// 首页-联系情况分析饼状图
export const getHomefllowStatistical = (data) => {
  return axios.get(`${baseUrl}/workbench/biz/fllowStatistical`, data).then((res) => res.data)
}

// 首页-呼叫-动态分析折线图
// export const getHomecallStatistical = (params) => {
//   console.log('动态分析折线图', params);
//   return axios.get(`${baseUrl}/workbench/biz/call/record/callStatistical`, params).then((res) => res.data)
// }
export const getHomecallStatistical = (params) => {
  return axios({
    url: `${baseUrl}/workbench/biz/call/record/callStatistical`,
    method: 'get',
    params: params
  }).then(res => res.data)
}


// 首页-通时折线图
export const getHomecallTimeStatistical = (params) => {
  return axios({
    url: `${baseUrl}/workbench/biz/call/record/callTimeStatistical`,
    method: 'get',
    params: params
  }).then(res => res.data)
}

//勤奋排行榜
export const getHomediligent = (data) => {
  return axios.post(`${baseUrl}/workbench/ranking/diligent`, data).then((res) => res.data);
}

// 动态榜排行
export const getHomedynamic = (data) => {
  return axios.post(`${baseUrl}/workbench/ranking/dynamic`, data).then((res) => res.data);
}

// 订单管理--商机来源渠道类型下拉列表
export const GetspinnerList = () => {
  return axios.get(`${baseUrl}/workbench/biz/channel/type/spinner`).then((res) => res.data);
}
// 个人通话首页统计
export const reqPersonStatis = (type) => {
  return axios.get(`${baseUrl}/call/statis/person/` + type).then(res => res.data)
}

// 零一裂变下拉接口
// export const getLingyiUserList = () => {
//   return axios.post(`${baseUrl}/lingyi/user/reList`).then(res => res.data)
// }
export const getLingyiUserList = (params, name) => {
  return axios.post(`${baseUrl}/lingyi/user/pageList?name=${name}`, params).then(res => res.data)
}

// 绑定零一裂变账号
export const getDisLingyiUser = (data) => {
  if (data !== null) {
    return axios.get(`${baseUrl}/workbench/user/bingdingLingyiUser?lingyiUserId=${data}`).then(res => res.data)
  } else {
    return axios.get(`${baseUrl}/workbench/user/bingdingLingyiUser`).then(res => res.data)
  }
}

// 是否提醒绑定零一裂变
export const getLingyiwarn = () => {
  return axios.get(`${baseUrl}/sys/user/lingyiWarn`).then(res => res.data)
}

// 不再提醒
export const getDislingyiWarn = () => {
  return axios.get(`${baseUrl}/sys/user/dislingyiWarn`).then(res => res.data)
}

// seeai跳转
export const getSeeaiLink = (params) => {
  return axios.post(`${baseseeaiUrl}/sys/user/noPassLogin?telephone=${params.telephone}&orgCode=cjsd&platformId=${params.platformId}`).then(res => res.data)
}

// 绑定呼叫中心列表
export const getcenterList = (params) => {
  return axios.post(`${baseUrl}/call/center/cno/list`, params).then(res => res.data)
}
// 确定绑定
export const getcenterListadd = (params) => {
  console.log('22params',params);
  return axios.post(`${baseUrl}/call/center/user`, params).then(res => res.data)
}
// 解除绑定
export const getcenterListupda = (params) => {
  console.log('22params',params);
  return axios.post(`${baseUrl}/call/center/user/update`, params).then(res => res.data)
}

// 绑定双呼模式
export const getsxbind = (params) => {
  return axios.post(`${baseUrl}/call/center/bind`, params).then(res => res.data)
}

//发送验证码 
export const getVerCode = (params) => {
  return axios.post(`${baseUrl}/call/center/clickCall/getVerCode/${params}`).then(res => res.data)
}

// 解除绑定
export const getdelSeatNum = (params) => {
  return axios.post(`${baseUrl}/call/center/clickCall/delSeatNum/${params}`).then(res => res.data)
}

// 双呼模式更换绑定
export const getrplacebing = (params) => {
  return axios.post(`${baseUrl}/call/center/clickCall/changeSeatNum/`, params).then(res => res.data)
}