var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "clickOut",
          rawName: "v-clickOut",
          value: _vm.hideDrap,
          expression: "hideDrap"
        }
      ],
      class: _vm.showhometree ? "gogonghome" : "gogong",
      staticStyle: { position: "relative" }
    },
    [
      _c("v-text-field", {
        attrs: {
          label: _vm.labelName,
          "hide-details": "auto",
          outlined: "",
          dense: true,
          height: 32,
          clearable: true
        },
        on: { mousedown: _vm.focus },
        model: {
          value: _vm.select,
          callback: function($$v) {
            _vm.select = $$v
          },
          expression: "select"
        }
      }),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.IsShowTree,
                expression: "IsShowTree"
              }
            ],
            staticStyle: {
              "background-color": "#fff",
              position: "absolute",
              "max-width": "400px",
              "min-width": "200px",
              "z-index": "111",
              border: "1px solid #eee",
              height: "350px",
              "overflow-y": "auto"
            }
          },
          [
            _c("el-tree", {
              ref: "dept",
              attrs: {
                "highlight-current": "",
                "default-expand-all": "",
                "expand-on-click-node": false,
                "node-key": _vm.value,
                props: _vm.defaultProps,
                "filter-node-method": _vm.filterNode,
                data: _vm.data
              },
              on: { "current-change": _vm.currentChange }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }