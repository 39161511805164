// 获取每周周一的日期
export const getWeekFirst = (i) => {
  var now = new Date()
  var firstDay = new Date(now - (now.getDay() - 1) * 86400000)
  firstDay.setDate(firstDay.getDate() + i)
  var mon = Number(firstDay.getMonth()) + 1 < 10 ? '0' + (Number(firstDay.getMonth()) + 1) : (Number(firstDay.getMonth()) + 1)
  var dd = firstDay.getDate() < 10 ? '0' + firstDay.getDate() : firstDay.getDate()
  return `${firstDay.getFullYear()}-${mon}-${dd}`
}
// 获取当前时间前多少或者后多少天
export const getLastNextWeek = (index, nowtime) => {
  var date = new Date(nowtime) // 当前日期
  var newDate = new Date()
  newDate.setDate(date.getDate() + index)// 官方文档上虽然说setDate参数是1-31, 其实是可以设置负数的
  return newDate.getFullYear() + '-' + (newDate.getMonth() + 1) + '-' + newDate.getDate()
}
export const formatTimes = (time, type) => {
  // let date = new Date(time)
  // let date = type === 2 ? new Date(Number(time)) : new Date(time);
  let date = new Date(time)
  // console.log(date);
  let Y = date.getFullYear();
  let M = date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let H = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  let m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  let S = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  // console.log(Y + "-" + M + "-" + D + " " + H + ":" + m + ":" + S);
  let formDate = type === 2? Y + "-" + M + "-" + D : Y + "-" + M + "-" + D + " " + H + ":" + m + ":" + S;
  return formDate
}
// 时间戳>字符串
export const formatDate = (date, fmt) => {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length))
    }
  }
  return fmt
  // 用法 formatDate(new Date(time * 1000), 'yyyy-MM-dd hh:mm');
}

/**
 * @class WeekLastNext
 *  获取每下一周和上一周 周一的日期 yyyy-MM-dd
 *  @param {date} 当前时间  new Date()
 *  @return  function  { lastWeek , nextWeek}
 *  by wang
 */
class WeekLastNext {
  constructor (date) {
    this.currentFirstDate = null
    this.setDate(date)
  }
  formatDate (date) {
    var year = date.getFullYear()
    var month = (date.getMonth() + 1)
    var day = date.getDate()
    return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day)
  }
  addDate (date, n) {
    date.setDate(date.getDate() + n)
    return date
  }
  setDate (date) {
    var week = date.getDay() - 1
    date = this.addDate(date, week * -1)
    this.currentFirstDate = new Date(date)
    return this.formatDate(date)
  }
  lastWeek () {
    return this.setDate(this.addDate(this.currentFirstDate, -7))
  }
  nextWeek () {
    return this.setDate(this.addDate(this.currentFirstDate, 7))
  }
  initWeek (date) {
    this.currentFirstDate = new Date(date)
  }
}

export const weekLastNext = WeekLastNext
// add by zhang
// 格式时间
// export const formatDate = (dateArg) => {
//   const date = new Date(dateArg);
//     const year = date.getFullYear();
//     const month = date.getMonth() + 1;
//     const day = date.getDate();
//     const formatMonth = month < 10 ? `0${month}` : month;
//     const formatDay = day < 10 ? `0${day}` : day;

//     return `${year}-${formatMonth}-${formatDay}`
// }
// 本周日期（周一为第一天，周日为最后一天）
// export const weekDate = () => {
//   const date = new Date();
//   const year = date.getFullYear();
//   const month = date.getMonth();
//   const nowDate = date.getDate();
//   const day = date.getDay();
//   let beginDate = new Date(year, month, nowDate - day + 1);
//   let endDate = new Date(year, month, nowDate);

// }
// 获取某月天数
// function getDaysInOneMonth(year, month) {
//   month = parseInt(month, 10);
//   var d = new Date(year, month, 0);
//   return d.getDate();
// }
// 本月日期
// function monthDate() {
//   var date = new Date();
//   var year = date.getFullYear();
//   var month = date.getMonth() + 1;
//   if (month < 10) {
//       month = "0" + month;
//   }
//   var day = getDaysInOneMonth(year, month);
//   var beginDate = year + "-" + month + "-01";
//   var endDate = year + "-" + month + "-" + day;
//   console.log("beginDate："+beginDate);
//   console.log("endDate ："+endDate );
// }

// yyyy-mm-dd 00:00:00
export const formatTimesDay = (time) => {
  let date = new Date(time)
  let Y = date.getFullYear()
  let M = date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
  let D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  let H = '00'
  let m = '00'
  let S = '00'
  return Y + '-' + (M) + '-' + D + ' ' + H + ':' + m + ':' + S
}

// yyyy-mm-dd
export const formatTimesHour = (time) => {
  let date = new Date(time)
  let Y = date.getFullYear()
  let M = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
  let D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  // let H = '23'
  // let m = '59'
  // let S = '59'
  return Y + '-' + (M) + '-' + D
}

// yyyy
export const formatTimesYear = (time) => {
  let date = new Date(time)
  let Y = date.getFullYear()
  return Y
}

// yyyy - mm
export const formatTimeMonth = (time) => {
  let date = new Date(time)
  let Y = date.getFullYear()
  let M = date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
  return Y + '-' + M
}

// h-m-s
export const formatTimeHour = (time) => {
  let date = new Date(time)
  let H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  let M = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  let S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return H + ':' + M + ':' + S
}

export const formatTimesFileName = (time) => {
  let date = new Date(time)
  // console.log(date);
  let Y = date.getFullYear();
  let M = date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let H = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  let m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  let S = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  // console.log(Y + "-" + M + "-" + D + " " + H + ":" + m + ":" + S);
  let formDate = Y + "-" + M + "-" + D + "-" + H + "-" + m + "-" + S;
  return formDate
}