/*
 * @Description: 统计
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-05-25 18:01:45
 * @LastEditors: Seven
 * @LastEditTime: 2022-03-24 16:53:27
 */
import Layout from "@/layout";
const count = [
  {
    path: "/count",
    name: "count",
    title: "统计分析",
    // redirect: "/count/schoolFollow",
    component: Layout,
    children: [
      {
        path: "schoolFollow",
        name: "schoolFollow",
        title: "校区客户跟进仪表盘",
        meta: { requireAuth: true },
        component: () => import("@/views/count/school-follow"), 
      },
      {
        path: "recruitTeacherReport",
        name: "recruitTeacherReport",
        title: "招生业绩",
        meta: { requireAuth: true },
        component: () => import("@/views/count/recruitTeacherReport"), 
      },
      {
        path:'funnelModel',
        name:'funnelModel',
        title: "销售漏斗",
        meta: { requireAuth: true },
        component: () => import("@/views/count/funnelModel"), 
      }
    ],
  },
];

export default count;
