/*
 * @Description: 字典表
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-18 15:24:06
 * @LastEditors: Seven
 * @LastEditTime: 2022-04-27 14:42:38
 */
/**
 * 数据字典CODE
 */
// 数据类型
export const DATA_TYPE = "DATA_TYPE";
// 商机类型
export const BIZ_TYPE = "BIZ_TYPE"; // 业务类型
export const BIZ_SOURCE = "BIZ_SOURCE";
export const BIZ_STATUS = "BIZ_STATUS";
export const COMM_WAY = "COMM_WAY"; // 沟通方式
export const INTENTION_LEVEL = "INTENTION_LEVEL";
// 无效原因
export const INVALID_TYPE = "INVALID_TYPE";
export const FOLLOW_STATUS = "FOLLOW_STATUS";
export const VISIT_STATUS = "VISIT_STATUS";
export const INSPECT_STATUS = "INSPECT_STATUS";
// 性别
export const SEX = "SEX";
// 销售类型
export const SALE_TYPE = "SALE_TYPE";
// 意向度
export const INTENTIONALITY = "INTENTIONALITY";
// 小能子账号
export const XN_CHILD_ACCOUNT = "XN_CHILD_ACCOUNT";
// 推广平台
export const PLATFORM_STATUS = "PLATFORM_STATUS";
export const PLATFORM_ACTION = "PLATFORM_ACTION";
// 推广账号
export const EX_ACCOUNT_STATUS = "EX_ACCOUNT_STATUS";
// 推广广告效果
export const EX_CHANNEL = "EX_CHANNEL";
export const EX_EQUIP = "EX_EQUIP";
// 客服团队
export const KEFU = "KEFU";
// 转NC类型
export const TRANSFER_TYPE = "TRANSFER_TYPE";
export const EDUCATION_TYPE = "EDUCATION_TYPE";
export const AUDIT_STATUS = "AUDIT_STATUS";
export const BLEND_STATUS = "BLEND_STATUS";
export const SIGN_UP_STATE = "SIGN_UP_STATE";
// 审核状态（坐席新增）
export const AUDIT_CUSTOMER_STATUS = "AUDIT_CUSTOMER_STATUS";
// 商机类型
export const BIZ_ADD_TYPE = "BIZ_ADD_TYPE";

export const FOLLOW_STATUS_SELF_EXAM = "FOLLOW_STATUS_SELF_EXAM";
export const FOLLOW_STATUS_ACCOUNT = "FOLLOW_STATUS_ACCOUNT";

// 接听状态
export const RECORD_SOURCE = "RECORD_SOURCE";

// 螳螂机器人管理-分配方式
export const MANTIS_MODE_OF_DISTRIBUTION = "MANTIS_MODE_OF_DISTRIBUTION";

// 推广类型
export const EXTENSION_TYPE = "EXTENSION_TYPE";

// 部门属性
export const DEPT_PROPERTY = "DEPT_PROPERTY";
// 分配方式
export const ALLOC_METHOD = "ALLOC_METHOD";
// 报名状态
export const SIGN_UP_STATUS = "SIGN_UP_STATUS";

// 区域
export const BASE_ARERS_TYPE = "BASE_ARERS_TYPE";
// 职业状态
export const BASE_CAREER_TYPE = "BASE_CAREER_TYPE";
// 客户需求
export const BASE_DEMAND_TYPE = "BASE_DEMAND_TYPE";
// 呼出状态
export const BASE_CALLOUT_TYPE = "BASE_CALLOUT_TYPE";

// 小型公开课备案类型
export const SM_COURSE_TYPE = "SM_COURSE_TYPE";
// 备案老师类型
export const RECOMMEND_TEACHER_TYPE = "RECOMMEND_TEACHER_TYPE";
// 协议状态
export const AGREEMENT_STATUS = "AGREEMENT_STATUS";
// 学历班型
export const IS_CLASS_TYPE = "IS_CLASS_TYPE";
// 报名单类型
export const NC_ORDER_TYPE = "NC_ORDER_TYPE";
// 订单来源
export const SCHOOL_ID = "SCHOOL_ID";

// 零元订单列表
export const PROCESS_STATUS = "PROCESS_STATUS";
