var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar" }, [
    _c(
      "div",
      { staticClass: "sidebar-tab" },
      _vm._l(_vm.itemList.children, function(item, index) {
        return _c(
          "div",
          {
            key: item.name,
            class: [
              "sidebar-item",
              { activeName: _vm.activeName === item.path },
              { active: _vm.active === index }
            ],
            on: {
              mouseenter: function($event) {
                return _vm.selectStyle(index)
              },
              mouseleave: _vm.outStyle,
              click: function($event) {
                return _vm.sideClick(item)
              }
            }
          },
          [
            _c(
              "router-link",
              {
                class: { "sidebar-active": _vm.activeName === item.path },
                attrs: { exact: "", to: item.path }
              },
              [_vm._v(" " + _vm._s(item.title) + " ")]
            ),
            _c("i")
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }