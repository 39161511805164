/*
 * @Description:
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-12 16:15:43
 * @LastEditors: Seven
 * @LastEditTime: 2021-07-26 17:54:42
 */
import axios from "axios";
const baseUrl = "/qw_api";
// 获取websocket地址
export const webSocketUrl = () => {
  return axios
    .get(`${baseUrl}/sys/message/websocketUrl`)
    .then((res) => res.data);
};
// 查询消息数量信息
export const reqMessageCountInfo = () => {
  return axios
    .get(`${baseUrl}/sys/message/user/countInfo`)
    .then((res) => res.data);
};
// 加载消息中心列表数据
export const reqMessageList = (params) => {
  return axios
    .get(`${baseUrl}/sys/message/user/list`, { params: params })
    .then((res) => res.data);
};
// 删除一条消息
export const reqMessageDelete = (msgId) => {
  return axios
    .delete(`${baseUrl}/sys/message/user/` + msgId)
    .then((res) => res.data);
};
// 阅读一条消息
export const reqMessageRead = (msgId) => {
  return axios
    .put(`${baseUrl}/sys/message/user/` + msgId)
    .then((res) => res.data);
};
// 清空类型消息
export const reqMessageClean = (type) => {
  return axios
    .delete(`${baseUrl}/sys/message/user/` + type + "/all")
    .then((res) => res.data);
};
