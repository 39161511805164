/*
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-22 11:45:22
 * @LastEditors: Seven
 * @LastEditTime: 2022-10-11 11:31:37
 */
import axios from "axios";
import CryptoJS from "crypto-js";
import Crypto from "@/utils/crypto";
import Strhide from "@/utils/strhide"

const baseUrl = "/qw_api";
const newPath = "/qw_new_api";
const prefix = '/qw_school'

// 根据编码获取自定义数据
export const getUserCustomQuery = (code) => {
  return axios.get(`${baseUrl}/user/custom/query?moduleCode=${code}`).then((res) => res.data);
}
// 根据编码自定义数据
export const doUserCustom = (data) => {
  return axios.post(`${baseUrl}/user/custom/query`, data).then((res) => res.data);
}
// 获取部门
export const getDeptList = () => {
  return axios.get(`${baseUrl}/workbench/dept/scope/list`).then((res) => res.data);
}
export const reqDeptTree = () => {
  return axios.get(`${baseUrl}/sys/dept/tree`).then(res => res.data)
}
// 首页部门树结构
export const reqworkbenchDeptTree = () => {
  return axios.get(`${baseUrl}/workbench/dept/tree`).then(res => res.data)
}
// 获取权限范围内的用户
export const getUserList = (params) => {
  // return axios.get(`${baseUrl}/workbench/user/scope/list`).then((res) => res.data);
  return axios({
    url: `${baseUrl}/workbench/user/scope/list`,
    method: "get",
    params
  }).then(res => res.data)
}

/**
 * 查询部门的人员
 * @param {*} params 
 * @returns 
 */
export const getUserListByDept = (id) => {
  // return axios.get(`${baseUrl}/workbench/user/scope/list`).then((res) => res.data);
  return axios({
    url: `/qw_api/sys/user/findUserByDept`,
    method: "post",
    data:[id]
  }).then(res => res.data)
}
export const getUserListBynewDept = (id) => {
  // return axios.get(`${baseUrl}/workbench/user/scope/list`).then((res) => res.data);
  return axios({
    url: `/qw_api/workbench/user/scope/list`,
    method: "get",
    params: id
  }).then(res => res.data)
}
// 根据客户Id获取客户的手机联系方式
export const getCustomerPhone = (customerId) => {
  let str = customerId.toString()
  return axios.get(`${baseUrl}/workbench/biz/customer/contact/getPhone?customerOpenId=${Crypto.encrypt(Strhide.getKeyHide,Strhide.getIvHide, str)}`).then((res) => res.data);
}

// 获取所有省份
export const getProvinceList = (params) => {
  return axios({
    url: `${baseUrl}/self-exam/school/get`,
    method: 'get',
    params: params
  }).then(res => res.data)
}

// 获取指定部门有人员映射的用户
export const getAppointmentTeacher = (params) => {
  return axios({
    url: `${baseUrl}/workbench/user/appointment/list`,
    method: 'get',
    params: params
  }).then(res => res.data)
}

// 根据招生校区获取NC招生老师
export const getNcTeacher = (params) => {
  return axios({
    url: `${baseUrl}/api/nc/getNcTeacher/`,
    method: 'get',
    params: params
  }).then(res => res.data)
}

// 转校变更招生老师
export const getNcTeacherList = (params) => {
  return axios({
    url: `${baseUrl}/api/nc/getNcTeacherList`,
    method: 'post',
    data: params
  }).then(res => res.data)
}


// 代理网下拉列表
export const getProxySpinner = () => {
  return axios.get(`${baseUrl}/workbench/biz/channel/proxy/spinner`).then((res) => res.data);
}

// 招生渠道下拉列表
export const getRecruitSpinner = () => {
  return axios.get(`${baseUrl}/workbench/biz/channel/recruit/spinner`).then((res) => res.data);
  // return axios.get(`${baseUrl}/workbench/biz/channel/recruit/select`).then((res) => res.data);
}
// 招生渠道下拉列表
export const getRecruitSpinnernew = () => {
  // return axios.get(`${baseUrl}/workbench/biz/channel/recruit/spinner`).then((res) => res.data);
  return axios.get(`${baseUrl}/workbench/biz/channel/recruit/select`).then((res) => res.data);
}

// 渠道类型下拉列表
export const getTypeSpinner = () => {
  return axios.get(`${baseUrl}/workbench/biz/channel/type/spinner`).then((res) => res.data);
}
// 赛道获取部门
export const getDeptByTrackType = (params) => {
  return axios.get(`${baseUrl}/sys/dept/trackTree`, { params }).then((res) => res.data);
} 

// 获取自考赛道的渠道名称
export const getZikaoTrackType = (params) => {
  return axios.get(`${baseUrl}/workbench/biz/channel/recruit/selectTrackType?trackType=${params}`).then((res) => res.data);
} 

// 获取当前部门节点的用户
export const getUserByDeptId = (params) => {
  return axios({
    url: `${baseUrl}/sys/user/getUserByDeptId`,
    method: 'get',
    params: params
  }).then(res => res.data)
}

// 根据NC合伙人信息
export const getflowPartnerList = () => {
  return axios.get(`${baseUrl}/api/nc/getflowPartner`).then(res => res.data)
}

// 根据赛到获取区域
export const getTrackAreaTree = (params) => {
  return axios.get(`${baseUrl}/cust/area/getTrackAreaTree`, {params}).then(res => res.data)
}

// 获取用户默认预约字段
export const getDefaultSchool = (userId) => {
  return axios.get(`${baseUrl}/workbench/biz/default/school/getDefaultSchool?userId=${userId}`).then(res => res.data)
}

/**
   * 客户轨迹--根据联系方式查询客户
   */
export const reqTrackInfoByContact = (type, detail) => {
  return axios.get(`${baseUrl}/biz/customer/tarck`, {params: {type, ...detail}}).then(res => res.data)
  // return axios.get(`http://10.0.98.74:8666/biz/customer/tarck`, {params: params}).then(res => res.data)
}

// 拨打频次校验
export const reqCheckCall = (params) => {
  return axios.post(`${baseUrl}/call/checkCall?customerId=${params.customerId}&phone=${params.phone}`).then(res => res.data)
}

/**
 * 检测客户是否是当前用户的当前跟进人
 */
 export const checkHandle = (customerId) => {
  return axios.get(`${baseUrl}/biz/customer/checkHandle/` + customerId).then(res => res.data)
}

/**
 * 公海和我的客户查询条件部门
 */
 export const getDeptUserTree = () => {
  return axios.get(`${baseUrl}/workbench/dept/user/tree`).then(res => res.data)
}

/**
 * 所有用户或指定赛道用户
 */
 export const getTrackTypeList = (trackType) => {
  return axios.get(`${baseUrl}/workbench/user/track_type/list?trackType=${trackType}`).then(res => res.data)
}
export const getTrackTypeListTwo = (params) => {
  console.log('params', params);
  // return axios.get(`${baseUrl}/workbench/user/trackTypeUser/list`,{data}).then(res => res.data)
  return axios({
    url: `${baseUrl}/workbench/user/trackTypeUser/list`,
    method: "get",
    params
  })
}
// 客户列表转移获取部门
export const transferDeptTree = (params) => {
  return axios({
    url: `${baseUrl}/sys/dept/transferDeptTree`,
    method: 'get',
    params: params
  }).then(res => res.data)
}

// 客户列表转移获取部门 二
export const trackTypeDeptTree = (params) => {
  return axios({
    url: `${baseUrl}/sys/dept/trackTypeDeptTree`,
    method: 'get',
    params: params
  }).then(res => res.data)
}
// 新增预约单做判断所选校区未开启全网订单模块的校区
export const addAppointJudgeOrderSchool = (schoolName) => {
  return axios.post(`${baseUrl}/workbench/biz/customer/appointment/addAppointJudgeOrderSchool?schoolName=${schoolName}`).then(res => res.data)
}

// 新增预约单判断
// export const doAddAppoint = (params) => {
//   return axios.post(`${baseUrl}/workbench/biz/customer/appointment/addAppoint`, params).then(res => res.data)
// }
// 新增预约单判断
export const doAddAppoint = (params) => {
  return axios.post(`${newPath}/order/ty/order/appointment/addOrderAppoint`, params).then(res => res.data)
}
// 客户详情报名单按钮权限
export const doRegistauthority = (params) => {
  return axios.post(`${newPath}/order/ty/order/appointment/registauthority`, params).then(res => res.data)
}
// 备案老师
export const getRecommentUserList = (params) => {
  // return axios.get(`${newPath}/order/biz/recommend/customer/user`, params).then(res => res.data)
  return axios({
    url: `${newPath}/order/biz/recommend/customer/user`,
    method: "get",
    params
  })
}
/**
 * 获取中国所有省份
 */
 export const getAllProvinceList = () => {
  return axios.get(`${baseUrl}/chinaPCity/getAllProvince`).then(res => res.data)
}

/**
 * 根据省份获取城市
 */
 export const getCitiesByProvinceCode = (code) => {
  return axios.get(`${baseUrl}/chinaPCity/getCitiesByProvinceCode?code=${code}`).then(res => res.data)
}
// 根据城市获取校区
export const getDeptparams = (params) => {
  return axios.get(`${baseUrl}/chinaPCity/getDeptsByCityId`, { params }).then(res => res.data)
}
// 根据校区类型获取校区
export const getVirtualschool = (type) => {
  return axios.get(`${baseUrl}/sys/dept/getVirtualschool?type=${type}`).then(res => res.data)
}
// 获取全网用户下拉框
export const getResTeachList = () => {
  return axios.get(`${newPath}/order/sys/order/getSysUserList`).then((res) => res.data);
}
// 获取螳螂客服用户
export const getTyUsertList = () => {
  return axios.get(`${baseUrl}/ty/user/select/list`).then(res => res.data)
}
// 获取NC所有人员信息 -------------------------------------------
export const getNcUserList = (data) => {
  return axios.post(`${baseUrl}/workbench/biz/customer/ncpersonnel/findUserList`, data).then((res) => res.data)
}
// 获取NncId不为空的所有人员映射,天翼订单变更销售使用 -------------------------------------------
export const getFindUserExtendAll = () => {
  return axios.get(`${baseUrl}/wb/biz/userExtend/findUserExtendAll`).then((res) => res.data)
}

// 商机招生渠道信息
export const getRecruitName = (data) => {
  // return axios.put(`${baseUrl}/workbench/biz/customer/myCustomer/recruitChannels`, data).then((res) => res.data)
  return axios.get(`${baseUrl}/workbench/biz/customer/myCustomer/recruitChannels/${data}`).then((res) => res.data)
}

// 变更渠道保存
export const getRecruitSave = (data) => {
  return axios.put(`${baseUrl}/workbench/biz/customer/myCustomer/recruitChannels`, data).then((res) => res.data)
  // return axios.get(`${baseUrl}/workbench/biz/customer/myCustomer/recruitChannels/${data}`).then((res) => res.data)
}

// 订单渠道信息
export const getorderName = (data) => {
  // return axios.put(`${baseUrl}/workbench/biz/customer/myCustomer/recruitChannels`, data).then((res) => res.data)
  return axios.get(`${newPath}/order/ty/order/channels/${data}`).then((res) => res.data)
}

// 变更渠道保存订单模块
export const getOrderSave = (data) => {
  return axios.put(`${newPath}/order/ty/order/channels`, data).then((res) => res.data)
  // return axios.get(`${baseUrl}/workbench/biz/customer/myCustomer/recruitChannels/${data}`).then((res) => res.data)
}

// 获取系统所有的用户
export const sysUserList = (data) => {
  return axios.get(`${baseUrl}/sys/user/listAll`, data).then((res) => res.data)
  // return axios.get(`${baseUrl}/workbench/biz/customer/myCustomer/recruitChannels/${data}`).then((res) => res.data)
}

// 获取变更用户
export const GetuserExtend = (params) => {
  return axios.post(`${baseUrl}/wb/biz/userExtend/getTeacherList`, params).then(res => res.data)
}

// nc订单列表招生老师
export const GetNcteachTree = (params) => {
  return axios.post(`${baseUrl}/nc/user/findUserList`, params).then(res => res.data)
}
// 获取验证码
export const sendVerifyCode = (params) => {
  return axios.post(`${prefix}/schoolcj/order/api/aliSms/sendVerifyCode`, params).then(res => res.data)
}
// 验证码是否正确
export const getverifyCode = (params) => {
  console.log('params', params);
  return axios.get(`${prefix}/schoolcj/order/api/aliSms/verifyCode`, {params}).then(res => res.data)
}

// 更新订单学习账号
export const updateLearningPhone = (params) => {
  return axios.post(`${newPath}/order/biz/order/updateLearningPhone`, params).then(res => res.data)
}
// 令牌获取接口
export const getAccessTicket = (data) => {
  return axios.get(`/pre/getAccessTicket?zhxqSignerId=${data.zhxqSignerId}&ncOrgPk=${data.ncOrgPk}`, data).then((res) => res.data)
}

// 获取招生老师,NC订单列表使用
export const getTeacherListToNc = (params) => {
  return axios.post(`${baseUrl}/wb/biz/userExtend/getTeacherListToNc`, params).then(res => res.data)
}

// 更新NC订单招生老师
export const doUpdateTeacher = (params) => {
  return axios.post(`${newPath}/order/nc/updateTeacher`, params).then(res => res.data)
}

// 税务师令牌接口
export const getAccessTicketshuiwu = (data) => {
  return axios.get(`${prefix}/getAccessTicket?zhxqUserName=${data.zhxqUserName}`,data).then((res) => res.data)
}

// 通过令牌绑定
export const loginByAccessTicket = (data) => {
  return axios.get(`${prefix}/loginByAccessTicket?zhxqUserName=${data.zhxqUserName}&accessTicket=${data.accessTicket}`,data).then((res) => res.data)
}

// 税务师跳转接口
export const getAccessAutoLogin = (data) => {
  return axios.get(`${prefix}/autoLogin?zhxqUserName=${data.zhxqUserName}`,data).then((res) => res.data)
}