/*
 * @Descripttion:
 * @version:
 * @Author: 刘锦
 * @Date: 2020-09-01 18:59:59
 * @LastEditors: in hengqi by mengze
 * @LastEditTime: 2021-08-11 17:13:41
 */
const prefix = '/base-api'
const auto_prefix = '/qw_school_auth'

export default {


  // 获取网校token
  getOnlineSchoolToken: {
    url: `${auto_prefix}/api/token/getToken`,
    method: "post",
    isQueryPost: true,
  },
  // 订单列表-根据手机和姓名查询商机id
  getIdsByCustomerPhoneOrName: {
    url: `${prefix}/marketingBiz/customer/getIdsByPhoneAndUserName`,
    method: 'post'
  },
  // 转介绍管理
  // 列表
  getqkcduceList: {
    url: `${prefix}/marketingThirdPart/qkc/studentIntroduceBills`,
    method: "post",
    noEmptyString: true
  },
  

}