<!--
 * @Description: 预约操作
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-07-02 14:36:51
 * @LastEditors: Seven
 * @LastEditTime: 2022-05-21 17:16:09
-->
<template>
  <div>
    <HqModal
      :visible="appointShow"
      title="预约商机"
      @on-cancel="cancel"
    >
      <el-form ref="form" :model="form" :rules="appointRules">
        <el-row>
          <el-col :span="24">
            <el-form-item>
              <v-autocomplete
                v-model="form.type"
                :items="[{name: '财经校区', id: 1}, {name: '虚拟校区', id: 2}, {name: '自考招生点', id: 3}, {name: '学历外部团队', id: 4}]"
                :menu-props="{ bottom: true, offsetY: true }"
                label="校区类型"
                item-text="name"
                item-value="id"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
                clear-icon="$clear"
                hide-details="auto"
                no-data-text="暂无数据"
                @change="typeChange"
              ></v-autocomplete>
            </el-form-item>
          </el-col>
          <div v-if="form.type === 1">
            <el-col :span="24">
              <el-form-item prop="provinceCode">
                <v-autocomplete
                  v-model="form.provinceCode"
                  :items="provinceList"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="省份"
                  item-text="name"
                  item-value="code"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                  @change="changeProvince"
                  no-data-text="暂无数据"
                ></v-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="provinceCodecity">
                  <v-autocomplete
                    v-model="form.provinceCodecity"
                    :items="cityList"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="城市"
                    item-text="name"
                    item-value="code"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    @change="changeCity"
                    no-data-text="暂无数据"
                  ></v-autocomplete>
              </el-form-item>
            </el-col>
          </div>
          <el-col :span="24">
            <el-form-item prop="schoolNcId" style="margin-bottom: 8px">
              <v-autocomplete
                v-model="form.schoolNcId"
                :items="schoolList"
                :menu-props="{ bottom: true, offsetY: true }"
                label="校区"
                item-text="deptName"
                item-value="ncSchoolId"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
                :disabled="!form.provinceCode && form.type == 1"
                clear-icon="$clear"
                hide-details="auto"
                @change="changeSchool"
                no-data-text="暂无数据"
              ></v-autocomplete>
            </el-form-item>
            <!-- <p style="font-size: 12px;color: #999">{{isCanTransferNc?'该校区暂未开启工作台订单模块，需转NC才可进行做单！':'该校区已开启工作台订单模块，可直接在工作台做单，无需进行转NC操作！'}}</p>
            <p v-if="!isCanTransferNc" style="font-size: 12px;color: #999">预约单有效期为1天</p> -->
          </el-col>
          <!-- <div v-if="isCanTransferNc">
            <span style="margin-right: 10px">是否转至NC</span>
            <el-switch
              v-model="group">
            </el-switch>
            <span style="margin-left: 10px;color: #7B532B;">转至NC的预约单有效期为1天</span>
            <p style="color: #AAAAAA;margin-bottom: 4px;margin-left: 88px">转至NC将会在NC系统产生“转商机申请单”</p>
            <p style="color: #AAAAAA;margin-left: 88px">招生老师即可根据“转商机申请单”进行报名</p>
          </div> -->
          <el-col :span="24">
            <el-form-item prop="teacherUserId">
              <v-autocomplete
                v-model="form.teacherUserId"
                :items="teacherList"
                :menu-props="{ bottom: true, offsetY: true }"
                label="招生老师"
                item-text="username"
                item-value="userId"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
                :disabled="!form.schoolNcId"
                clear-icon="$clear"
                hide-details="auto"
                @change="changeTeacher"
                no-data-text="暂无数据"
              ></v-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="appointmentTime">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="year"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="year"
                    label="预约时间"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="year" no-title locale="zh-cn">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">取消</v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(year)"
                  >
                    确认
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-time-select
              style="width: 100%"
              size="small"
              v-model="hour"
              :picker-options="{
                start: '00:00',
                step: '00:01',
                end: '23:59'
              }"
              placeholder="选择时间">
            </el-time-select>
          </el-col>
          <el-col :span="24" v-if="group">
            <el-form-item prop="phone">
              <v-select
                v-model="form.phone"
                :items="phoneList"
                :menu-props="{ bottom: true, offsetY: true }"
                label="成交号码"
                item-text="name"
                item-value="code"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
                clear-icon="$clear"
                hide-details="auto"
                no-data-text="暂无数据"
              ></v-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button
          size="small"
          type="primary"
          @click="saveReserve('form')"
          v-loading="loading"
        >保存</el-button>
      </div>
    </HqModal>
  </div>
</template>
<script>
import { 
  doAppointAndTransferToNc, 
  getPhoneList, 
  doReserveCustomer 
} from "api/customer";
import { 
  getNcTeacher, 
  getAppointmentTeacher,
  getDefaultSchool, 
  getAllProvinceList, 
  getCitiesByProvinceCode,
  getDeptparams,
  addAppointJudgeOrderSchool, 
  doAddAppoint,
  getVirtualschool,
} from "api/public";
export default {
  props: {
    method: {
      type: Function,
    },
    detailInit: {
      type: Function,
    }
  },
  data() {
    let validateTime = (rule, value, callback) => {
      if (this.year === "") {
        callback(new Error("请选择预约时间"));
      } else {
        let ye = ""
        if(this.year.toString().indexOf('-')) {
          let nyr = new Date(this.year)
          let n = nyr.getFullYear()
          let y = nyr.getMonth() + 1
          let r = this.hour > 23 ?nyr.getDate() + 1 : nyr.getDate()
          ye = `${n}-${y}-${r}`
        } else {
          ye = this.year
        }
        let date = new Date(`${ye} ${this.hour}`);
        if (date.valueOf() - Date.now() < 0) {
          callback(
            new Error("预约同时转商机时间不能小于当前时间，请重新选择")
          );
        }
        callback();
      }
    };
    return {
      appointShow: false,
      menu: false,
      loading: false,
      form: {
        customerId: "",
        teacherUserId: "",
        schoolNcId: "",
        teacherName: "",
        transferType: "",
        phone: "",
        appointmentTime: "",
        type: "",
        provinceCodecity: null,
        // memo: ""
      },
      provinceList: [],
      schoolList: [],
      phoneList: [],
      teacherList: [],
      group: true,
      time: "00:00",
      defaultSelect: {},
      year: '',
      hour: "",
      addloading: false,
      appointRules: {
        // provinceCode: [
        //   { required: true, message: "请选择省份", trigger: "change" }
        // ],
        // provinceCodecity: [
        //   { required: true, message: "请选择城市", trigger: "change" }
        // ],
        schoolNcId: [
          { required: true, message: "请选择校区", trigger: "change" }
        ],
        teacherUserId: [
          { required: true, message: "请选择老师", trigger: "change" }
        ],
        appointmentTime: [{ required: true, type: 'date', validator: validateTime }],
        phone: [
          {
            required: true,
            message: "请选择成交号码",
            trigger: "change"
          }
        ]
      },
      list: {},
      isCanTransferNc: false,
      // 校区类型
      schoolType: "",
      cityList: []
    }
  },
  methods: {
    init(row) {
      this.list = row
      this.appointShow = true
      this.form = {...this.form, ...row}
      this.invaildClick()
    },
    invaildClick() {
      let nowTime = new Date();
      let hour = nowTime.getHours() + 1 < 10? '0' + (nowTime.getHours() + 1):nowTime.getHours() + 1;
      let min = nowTime.getMinutes() < 10? '0' + nowTime.getMinutes():nowTime.getMinutes();
      let year = nowTime.getFullYear()
      let mon = nowTime.getMonth()
      // 默认是当前时候往后推一个小时
      let day = hour > 23? nowTime.getDate() + 1 : nowTime.getDate()
      hour = Number(hour) > 23? '00' : hour
      this.hour = `${hour}:${min}`;
      this.year = `${year}-${mon + 1}-${day}`
      
      getAllProvinceList().then(data => {
        this.provinceList = data.result;
      });
      getDefaultSchool(this.$store.state.user.loginUser.userId).then(res => {
        let { code, result } = res
        if (code === 0) {
          if(result) {
            this.form.type = result.type
            this.form.provinceCode = result.provinceCode
            this.form.provinceCodecity = result.cityCode
            this.form.schoolNcId = result.schoolNcId
            this.form.schoolName = result.schoolName
            this.form.teacherUserId = result.teacherUserId
            // this.form = result
            this.form.customerId = this.list.customerId
            this.defaultSelect = result
            if(result.type === 1) {
              // 财经校区才会执行这步， 1代表是自动触发的事件
              this.changeProvince(result.provinceCode, 1) 
            } else {
              this.typeChange(result.type, 1)
              if(result.schoolNcId) {
                this.changeSchool(result.schoolNcId, 1)
              }
            }
          }
        }
      })

      this.form.customerId && getPhoneList(this.form.customerId).then(data => {
        let { code, msg, result } = data;
        if (code === 0) {
          this.phoneList = result;
          this.form.phone = result[0]
        } else {
          this.$message.error(msg);
        }
      });
    },
    // 校区类型联调校区
    async typeChange(value, type) {
      if(type != 1) {
        this.form.provinceCodecity = null
        this.form.provinceCode = ""
        this.form.teacherUserId = "";
        this.form.teacherName = "";
        this.form.schoolNcId = "";
        this.cityList = []  
      }
      if(value) {
        if(value !== 1) {
          const {code, result } = await getVirtualschool(value)
          if(code === 0) {
            this.schoolList = result
          }
        } else {
          this.schoolList = []
        } 
      }
    },
    // 省份联动城市
    changeProvince(schoolId, type) {
      if(type != 1) {
        this.defaultSelect = {}
        this.form.schoolNcId = ""
        this.form.teacherUserId = ""
        this.form.provinceCodecity = null;
      }
      this.provinceList.map(item => {
        if(schoolId === item.code) {
          this.form.provinceName = item.name
        }
      })
      if (schoolId) {
        getCitiesByProvinceCode(schoolId).then(data => {
          this.cityList = data.result
          this.form.teacherName = "";
          this.form.schoolName = "";
          this.form.teacherUserId = "";
          this.form.schoolNcId = "";
          this.provinceList.map((item) => {
            if (schoolId == item.schoolId) {
              this.form.provinceName = item.name;
              this.form.schoolName = "";
              this.form.teacherName = "";
            }
          });
          if(this.form.provinceCodecity) {
            this.changeCity(this.form.provinceCodecity, 1) 
          }
        })
      }
    },
    // 城市联动校区
    changeCity(value, type) {
      if(type != 1) {
        this.defaultSelect = {}
        this.form.teacherUserId = ""
        this.form.teacherName = "";
      }
      let id = ''
      this.cityList.map(item => {
        if(item.code == value) {
          this.form.cityCode = item.code
          this.form.cityName = item.name
          id = item.id
        }
      })
      getDeptparams({ id }).then(data => {
        this.schoolList = data.result;
        if(this.defaultSelect.schoolNcId) {
          this.form.schoolNcId = this.defaultSelect.schoolNcId
          this.changeSchool(this.defaultSelect.schoolNcId, type)
        }
      });
    },
    changeSchool(schoolId, type) {
      if(type != 1) {
        this.defaultSelect = {}
        this.form.teacherUserId = ""
        this.form.teacherName = "";
      }
      if (schoolId) {
        this.schoolList.map(v => {
          if(v.ncSchoolId == schoolId) {
            this.form.ncuserPk = v.teacherPk
            this.form.schoolDeptId = v.deptId
          }
        })
        getAppointmentTeacher({ deptId: this.form.schoolDeptId}).then(data => {
          this.teacherList = data.result
          if(this.defaultSelect.teacherUserId) {
            this.teacherList.map(item => {
              if(item.userId === this.defaultSelect.teacherUserId) {
                this.form.teacherUserId = this.defaultSelect.teacherUserId
                this.form.teacherName = this.defaultSelect.teacherName
                this.form.teacherPk = item.ncUserPk
              }
            })
          }
        });
      }
      this.schoolList.map((item) => {
        if (schoolId == item.ncSchoolId) {
          this.form.schoolName = item.deptName;
          // this.form.teacherName = "";
        }
      });
      // addAppointJudgeOrderSchool(this.form.schoolName).then(res => {
      //   if(res.result) {
      //     this.group = false
      //     this.isCanTransferNc = false
      //   } else {
      //     this.isCanTransferNc = true
      //   }
      // })
    },
    changeTeacher(key) {
      this.teacherList.map((item) => {
        if (key == item.userId) {
          this.form.teacherName = item.username;
          this.form.teacherPk = item.ncUserPk
        }
      });
    },
    saveReserve() {
      if (this.addloading) {
        return false
      }
      if(this.appointmentTime !== "") {
        this.form.appointmentTime = this.year + ' ' + this.hour + ':00'
      }
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.addloading = true;
          if(this.form.type !== 1) {
            this.form.provinceCode = "";
            this.form.provinceName = ""
            this.form.cityName = ""
            this.form.cityCode = ""
            this.form.provinceCodecity = null
          } 
          // if(this.group) {
          //   this.form.transferType = 1
          //   doAppointAndTransferToNc(this.form)
          //   .then(data => {
          //     let { code, msg } = data;
          //     if (code === 0) {
          //       this.appointShow = false;
          //       this.addloading = false;
          //       this.method();
          //       this.detailInit(this.list)
          //       this.$message.success("预约同时转商机成功，可直接去NC做单");
          //     } else {
          //       this.addloading = false;
          //       this.$message.info({
          //         message: msg,
          //         duration: 0
          //       });
          //     }
          //   })
          //   .catch(() => {
          //     this.addloading = false;
          //   });
          // } else {
            // 新增预约单判断
            doAddAppoint(this.form).then(res => {
              if(res.code === 0) {
                this.form.transferType = ""
                doReserveCustomer(this.form)
                .then(data => {
                  let { code, msg } = data;
                  if (code === 0) {
                    this.appointShow = false;
                    this.addloading = false;
                    this.$message.success("预约成功");
                    this.detailInit(this.list)
                    this.method();
                  } else {
                    this.addloading = false;
                    this.$message.error(msg);
                  }
                })
                .catch(() => {
                  this.addloading = false;
                });
              } else {
                this.appointShow = false;
                this.addloading = false;
                this.$message.error(res.msg)
                // this.method();
              }
            })
          // }
        }
      })
    },
    cancel() {
      this.appointShow = false
    }
  }
}
</script>
<style lang="scss" scoped>
  v-deep .el-date-editor {
    width: 100%!important;
  }
</style>