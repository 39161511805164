<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-16 10:21:01
 * @LastEditors: Seven
 * @LastEditTime: 2021-07-06 15:53:54
-->
<template>
  <transition name="confirmbox-fade">
    <div class="confirm_wrapper" v-show="visible" @click="otherClick">
      <div class="confirm_box" ref="confirmBox">
        <p class="confirm_title">
          <span class="sign"></span>
          {{ title || "提示" }}
        </p>
        <p class="content_text" v-if="message">
          {{ message }}
        </p>
        <p class="content_text" v-if="messageHtml" v-html="messageHtml">
        </p>
        <div class="footer_button">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="define">
            {{ btnText || "确定" }}
          </el-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Confirm",
  data() {
    return {
      title: "",
      // 提示文本
      message: "",
      messageHtml: "",
      // 显示或隐藏
      visible: false,
      // 暂时有两种类型吧，export 导出，clue 一般提示
      btnText: "",
    };
  },
  methods: {
    otherClick(e) {
      let confirmBox = this.$refs.confirmBox;
      if (e.target.contains(confirmBox)) {
        this.cancel();
      }
    },
    close() {
      this.visible = false;
      setTimeout(() => {
        this.$el.parentNode.removeChild(this.$el);
      }, 300);
    },
    define() {
      if (this.type !== "export") {
        this.close();
      }
      this.callback("confirm");
    },
    cancel() {
      this.close();
      this.callback("cancel");
    },
  },
};
</script>


<style lang="scss" scoped>
.confirm_wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3333;
  .confirm_box {
    width: 450px;
    height: 208px;
    vertical-align: middle;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ebeef5;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    text-align: left;
    overflow: hidden;
    backface-visibility: hidden;
    .confirm_title {
      display: flex;
      align-items: center;
      height: 56px;
      line-height: 56px;
      padding-left: 18px;
      font-size: 18px;
      color: #333;
      font-weight: bold;
      border-bottom: 1px solid #e1e4eb;
      .sign {
        height: 15px;
        width: 4px;
        border-radius: 4px;
        margin-right: 8px;
      }
    }
    .content_text {
      text-align: center;
      margin-top: 30px;
      color: #333;
      padding: 0 26px;
      font-size: 14px;
      line-height: 20px;
    }
    .footer_button {
      display: flex;
      justify-content: center;
      padding: 20px 0;
      // .button {
      //   line-height: 1;
      //   cursor: pointer;
      //   background: #fff;
      //   border: 1px solid #dcdfe6;
      //   color: #606266;
      //   text-align: center;
      //   box-sizing: border-box;
      //   transition: 0.1s;
      //   padding: 10px 30px;
      //   font-size: 14px;
      //   border-radius: 4px;
        // &.define {
        //   margin-left: 12px;
        //   color: #fff;
        //   background-color: #337AFF;
        //   border-color: #337AFF;
        // }
      // }
    }
  }
}
</style>
<style lang="scss" scoped>
.confirmbox-fade-enter-active {
  -webkit-animation: confirmbox-fade-in 0.3s;
  animation: confirmbox-fade-in 0.3s;
}
.confirmbox-fade-leave-active {
  -webkit-animation: confirmbox-fade-out 0.3s;
  animation: confirmbox-fade-out 0.3s;
}
@keyframes confirmbox-fade-in {
  0% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes confirmbox-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
}
</style>