/*
 * @Description: 
 * @Version: 1.0
 * @Autor: in hengqi by mengze 
 * @Date: 2021-07-21 15:21:41
 * @LastEditors: in hengqi by mengze
 * @LastEditTime: 2021-07-22 09:18:05
 */

import business from "./workbence/business"
import order from "./workbence/order"
import track from "./workbence/track"
import common from "./workbence/common"
import thrid from "./workbence/thrid"
export default {
  ...business,
  ...order,
  ...track,
  ...common,
  ...thrid
}
