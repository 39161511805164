<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-07-01 19:19:48
 * @LastEditors: Seven
 * @LastEditTime: 2021-12-13 14:55:17
-->
<template>
  <span style="margin-left: 20px" class="customer-label-box">
    <span v-if="items.length > 0" style="line-height: 0">
      <div class="label_box" 
        v-for="(i, index) in items" 
        :key="i.labelId"
        @mouseenter="selectStyle(index)"
        @mouseleave="mouseLeave(index)" 
        :style="{width: `${i.width}px`}"
        >
        <el-tag
          ref="Tag"
          class="add_el_tag"
          :closable="i.close"
          size="small"
          @close="tagClose(i)"
          color="#CCE6FF"
          style="color: #337AFF"
          type="plain">
          {{ i.labelName }}
        </el-tag>
      </div>
    </span>
      <el-button v-if="permUtil.WbCusotmer.customerGetDef() && perm" @click="addLabelClick" class="hq-button add-label-btn" type="primary"
                  >标签</el-button>
    <!-- 添加标签 -->
    <HqModal :visible="labelVisible" width="550" @on-cancel="cancel">
      <div class="main_label_add">
        <div class="main_label_box">
          <div class="li" v-for="(item,index) in navList" :key="index" :class="{active:index ==num}" @click="tab(index)">
            {{item}}
          </div>
        </div>
        <div class="tabCon">
          <!-- 全部 -->
          <div v-show="0 == num" v-for="(i, indexOne) in tabContents" :key="i.groupId">
            <li class="tab_com_li">{{ i.geoupName }}</li>
            <div class="tab_com_tag" v-if="i.bizCustomerLabels">
              <template v-for="(v, indexTwo) in i.bizCustomerLabels">
                <span 
                  v-if="selectLabelIds.indexOf(v.labelId) !== -1"
                  :key="v.labelId"
                  class="tag_list"
                  :style="{backgroundColor: `${v.click?'#19be6b':''}`, color: `${v.click?'#fff':''}`}"
                  @click="tagListClick(indexOne, indexTwo)">{{v.name}}</span>
              </template>
            </div>
            <div v-else style="margin-left: 40px;line-height: 40px;color: blue">暂无标签</div>
          </div>
          <!-- 非全部 -->
          <div v-for="(item, indexOne) in tabContents" :key="indexOne + item.geoupName" v-show="(indexOne + 1) == num">
            <li class="tab_com_li">{{ item.geoupName }}</li>
            <div class="tab_com_tag" v-if="item.bizCustomerLabels">
              <template v-for="(v, indexTwo) in item.bizCustomerLabels">
                <span 
                v-if="selectLabelIds.indexOf(v.labelId) !== -1"
                class="tag_list" 
                :key="v.labelId"
                :style="{backgroundColor: `${v.click?'#19be6b':''}`, color: `${v.click?'#fff':''}`}"
                @click="tagListClick(indexOne, indexTwo)">{{v.name}}</span> 
              </template>
            </div>
            <div v-else style="margin-left: 40px;line-height: 40px;color: blue">暂无标签</div>
          </div>
        </div>
      </div>
      
      <div slot="footer">
         <el-checkbox style="margin-right: 220px" @change="checkChange" v-model="single">仅查看已选标签</el-checkbox>
         <el-button size="small" @click="cancel">取消</el-button>
        <el-button size="small" type="primary" @click="saveClick" :loading="addloading">确定</el-button>
      </div>
    </HqModal>
  </span>
</template>
<script>
import { 
  getCustomerLabelList, 
  doCustomerLabelDel,
  getLabelByTrackTypeList,
  doCustomerLabelSave
} from "api/customer";
export default {
  props: {
    perm: {
      type: Boolean,
    },
    method: {
      type: Function
    }
  },
  data() {
    return {
      labelVisible: false,
      items: [],
      navList:['全部'],
      tabContents:[],
      num: 0,
      // 是否查看选中的标签
      single: false,
      // 保存按钮
      addloading: false,
      trackType: '',
      customerId: '',
      labelIds: [],
      allLabelIds: [],
      selectLabelIds: []
    }
  },
  methods: {
    init(customerId, trackType) {
      this.trackType = trackType
      this.customerId = customerId
      customerId && this.getCustomerLabelList(customerId)
    },
    // 获取商机绑定标签
    getCustomerLabelList(customerId) {
      let labelIds = []
      getCustomerLabelList(customerId).then(data => {
        let { code, result } = data
        if(code === 0) {
          this.items = result
          this.items.map((item, index) => {
            this.$nextTick(() => {
              this.$set(item, "width", this.$refs.Tag[index].$el.clientWidth + 20)   
            })
            this.$set(item, 'close', false)
            labelIds.push(item.labelId)
          })
          this.labelIds = labelIds
        }
      })
    },
    // 移入标签
    selectStyle(index) {
      this.$set(this.items[index], 'close', true)
    },
    // 移出标签
    mouseLeave(index) {
      this.$set(this.items[index], 'close', false)
    },
    // 关闭标签
    tagClose(item) {
      this.$hqMessageBox({
        title: '提示',
        message: '确定删除该标签, 是否继续?',
      })
      .then(() => {
        doCustomerLabelDel(this.customerId, item.usedId).then(data => {
          let {code, msg} = data
          if (code === 0) {
            this.$message.success('删除成功！')
            this.getCustomerLabelList(this.customerId)
          } else {
            this.$Message.warning(msg)
          }
        })
      }).catch(() => {
      })
    },
    // 打开添加绑定标签框
    async addLabelClick() {
      const data = await getLabelByTrackTypeList(this.trackType)
      let { code, result } = data
      let allLabelIds = []
      this.navList = ['全部']
      this.tabContents = []
      if(code === 0) {
        result.map(item => {
          item.bizCustomerLabels && item.bizCustomerLabels.map(i => {
            // 判断哪些是已经绑定了的 click:true 已绑定，false 未绑定
            if(this.labelIds.indexOf(i.labelId) !== -1) {
              i.click = true
            } else {
              i.click = false
            }
            allLabelIds.push(i.labelId)
          })
          this.navList.push(item.geoupName)
          this.tabContents.push(item)
        })
        this.allLabelIds = allLabelIds
        this.selectLabelIds = allLabelIds
        this.labelVisible = true
      }
    },
    tab (index){
      this.num = index
    },
    // 取消
    cancel () {
      this.single = false
      this.labelVisible = false
    },
    // 保存添加
    async saveClick() {
      let param = [];
      this.tabContents.map(item => {
        item.bizCustomerLabels && item.bizCustomerLabels.map(i => {
          if(i.click) {
            param.push(i.labelId)
          }
        })
      })
      const data = await doCustomerLabelSave(this.customerId, param.toString())
      if(data.code === 0) {
        this.$message.success(data.msg)
        this.getCustomerLabelList(this.customerId)
        this.method && this.method()
      } else {
        this.$Message.error(data.msg)
      }
      this.single = false
      this.labelVisible = false
    },
    // 选择事件
    tagListClick(indexOne, indexTwo) {
      this.$set(this.tabContents[indexOne].bizCustomerLabels[indexTwo], 'click', !this.tabContents[indexOne].bizCustomerLabels[indexTwo].click)
    },
    // 查看已选标签
    checkChange(value) {
      // 需要查看已绑定标签
      this.selectLabelIds = value ? this.labelIds : this.allLabelIds
    }
  }
}
</script>
<style lang="scss" scoped>
  .customer-label-box {
    display: inline-block;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  span {
    font-weight: normal!important;
  }
  .add-label-btn {
    position: absolute;
    right: 18px;
    top: 50%;
    margin-top: -12px!important;
  }
  .modal__body {
    text-align: left!important;
  }
  .ivu-modal-body {
    min-height: 150px;
  }
    .add_label {
      float: right;
      line-height: 40px;
      cursor: pointer;
      color: #47cb89;
      margin-right: 10px;
    }
    .label_box {
      display: inline-block;
      line-height: 1.1;

      .add_el_tag {
        border: 1px solid #fff!important;
        // margin-bottom: 6px;

        .el-icon-close {
          color: #ffffff;
          width: 12px;
        }
      }
    }
  .main_label_add {
      display: flex;

      .main_label_box {
        width: 120px;
        margin-top: 10px;
        flex: 2;
        border-right: 1px solid #ebeef5;
        .li{
          list-style:none;
          height:30px;
          line-height:30px;
          cursor: pointer;
          text-align:center;
        }
        .active {
          background-color: #ccf3fd;
        }
      }
      .tabCon {
        display: inline-block;
        flex: 5;
      }

      .tab_com_li {
        margin: 10px 0 0 10px;
      }
      .tab_com_tag {
        margin: 10px 0 0 40px;

        .tag_list {
          display: inline-block;
          line-height: 24px;
          font-size: 12px;
          padding: 0 6px;
          text-align: center;
          border: 1px solid #ebeef5;
          cursor: pointer;
          margin-bottom: 14px;
          min-width: 62px;
        }
      }
  }

  .el-button--primary {
    span {
      color: #fff!important;
    }
  }
  
</style>