/*
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-17 14:24:23
 * @LastEditors: Seven
 * @LastEditTime: 2021-09-10 17:21:20
 */
export const isPC = (() => {
  // 是否为PC端
  var userAgentInfo = navigator.userAgent;
  var Agents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod",
  ]
  var flag = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
})()

// 加密手机号码
export const getTel = (tel) => {
  const reg = /^(\d{3})\d{4}(\d{4})$/
  return tel.replace(reg, '$1****$2')
}

// 加密手机号码,qq,微信，座机
export const getInputreset = (tel) => {
  let reg
  if (tel.length == 11) {
    reg = /^(\d{3})\d{4}(\d{4})$/
    return tel.replace(reg, '$1****$2')
  } else {
    return tel = '********'
  }
}

// 检查权限，遍历用户当前权限
export const checkAuth = (access, name) => {
  for (let auth of access) {
    if (auth === name) {
      return true
    }
  }
  return false
}

export const initMenu = (menuList) => {
  menuList.map(item => {
    if(item.children.length > 0) {
      item.path = '/' + item.name
      item.children.map(i => {
        i.path = '/' + item.name + '/' + i.name
      })
    } else {
      item.path = '/' + item.name
    }
  })
  return menuList
}

// 去掉空格
export const strReplace = (params) => {
  if (!params) return
  return params.replace(/\s*/g, '')
}

export const setCurrentPath = (vm, name) => {
  let currentPathArr = []
  if (name === 'wbHome') {
    currentPathArr = []
  } else if (name === 'user') {
    currentPathArr = [
      {
        title: "首页",
        path: '/wbHome',
        name: 'wbHome'
      },
      {
        title: '个人中心',
        path: '',
        name: name
      }
    ]
  } else {
    let currentPathObj = vm.$store.state.app.routers.filter(item => {
      if (item.children && item.children.length <= 1) {
        return item.children[0].name === name
      } else {
        let i = 0
        let childArr = item.children
        let len = childArr.length
        while (i < len) {
          if (childArr[i].name === name) {
            return true
          }
          i++
        }
        return false
      }
    })[0]
    if (currentPathObj.children.length <= 1 && currentPathObj.name === 'wbHome') {
      currentPathArr = [
        {
          title: '首页',
          path: '',
          name: 'wbHome'
        }
      ]
    } else {
      let childObj = currentPathObj.children.filter((child) => {
        return child.name === name
      })[0]
      currentPathArr = [
        {
          title: currentPathObj.title,
          path: currentPathObj.path,
          name: currentPathObj.name
        },
        {
          title: childObj.title,
          path: "",
          name: name
        }
      ]
    }
  }
  vm.$store.commit('setCurrentPath', currentPathArr)

  return currentPathArr
}
