var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "el-notification-fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible"
          }
        ],
        class: ["el-notification", _vm.customClass, _vm.horizontalClass],
        style: _vm.positionStyle,
        attrs: { role: "alert" },
        on: {
          mouseenter: function($event) {
            return _vm.clearTimer()
          },
          mouseleave: function($event) {
            return _vm.startTimer()
          },
          click: _vm.click
        }
      },
      [
        _vm.type || _vm.iconClass
          ? _c("i", {
              staticClass: "el-notification__icon",
              class: [_vm.typeClass, _vm.iconClass]
            })
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "el-notification__group",
            class: { "is-with-icon": _vm.typeClass || _vm.iconClass },
            staticStyle: { width: "100%" }
          },
          [
            _c("div", { staticClass: "a-notice" }, [
              _vm.position == "top-right"
                ? _c("span", [
                    _c("i", {
                      staticClass: "iconfont iconbellfill",
                      staticStyle: { color: "#337AFF", "margin-right": "6px" }
                    }),
                    _c("span", [_vm._v("一条新的消息")])
                  ])
                : _c("span", {
                    staticClass: "el-notification__title",
                    domProps: { textContent: _vm._s(_vm.title) }
                  })
            ]),
            _vm.position == "top-right"
              ? _c("span", [
                  _c("i", {
                    class: [
                      "iconfont",
                      _vm.title.indexOf("失败") === -1
                        ? "iconcheck"
                        : "iconbiaoqianguanbi-shubiaotingliu"
                    ],
                    style: {
                      color:
                        _vm.title.indexOf("失败") === -1
                          ? "#16B861"
                          : "#F54955",
                      marginRight: "6px"
                    }
                  }),
                  _c("span", {
                    staticClass: "el-notification__title",
                    domProps: { textContent: _vm._s(_vm.title) }
                  })
                ])
              : _c("span", [
                  _c("i", {
                    class: [
                      "iconfont",
                      _vm.title.indexOf("失败") === -1
                        ? "iconcheck"
                        : "iconbiaoqianguanbi-shubiaotingliu"
                    ],
                    style: {
                      color:
                        _vm.title.indexOf("失败") === -1
                          ? "#16B861"
                          : "#F54955",
                      marginRight: "6px"
                    }
                  }),
                  _c("span", [_vm._v("推送内容")])
                ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.message,
                    expression: "message"
                  }
                ],
                staticClass: "el-notification__content",
                staticStyle: { "margin-top": "6px" }
              },
              [
                _vm._t("default", function() {
                  return [
                    !_vm.dangerouslyUseHTMLString
                      ? _c("p", [_vm._v(_vm._s(_vm.message))])
                      : _c("p", {
                          domProps: { innerHTML: _vm._s(_vm.message) }
                        })
                  ]
                })
              ],
              2
            ),
            _vm.showClose
              ? _c("div", {
                  staticClass: "el-notification__closeBtn el-icon-close",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.close.apply(null, arguments)
                    }
                  }
                })
              : _vm._e(),
            _vm.position == "bottom-right"
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "center",
                      "margin-top": "10px"
                    }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.close.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("朕知道了")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.click.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("去跟进")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }