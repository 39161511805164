<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-05-07 14:25:05
 * @LastEditors: Seven
 * @LastEditTime: 2021-12-30 12:07:05
-->
<template>
  <div class="message">
    <el-popover placement="bottom-end" :width="340" trigger="click">
      <template #reference>
        <el-badge :value="value" type="danger" :max="99" class="badge">
          <i class="el-icon-bell" style="font-size: 18px" @click="messageClick"></i>
        </el-badge>
      </template>
      <div class="content hq-tabs">
        <el-tabs :stretch="true" v-model="msgTabs" @tab-click="handleClick">
          <el-tab-pane v-for="(item, i) in messageList" :name="item.name" :key="i">
            <el-badge slot="label" :value="item.value>0?item.value:''" type="danger" :max="99">
              <span>{{ item.label }}</span>
            </el-badge>
            <div v-if="msgList.length > 0">
              <div class="msg-alert" style="max-height: 450px;overflow-y: auto">
                <el-alert
                  show-icon
                  v-for="(item, index) in msgList"
                  :key="index"
                  :type="item.readFlag === 0 ? 'success' : 'info'"
                  :closable="false"
                  style="margin-bottom: 4px;cursor: pointer"
                >
                  <i
                    :type="
                      item.readFlag === 0 ? 'android-mail' : 'android-drafts'
                    "
                    slot="icon"
                    size="20"
                  ></i>
                  <template slot="title">
                    <el-row justify="center" align="middle" :gutter="8" style="width: 270px">
                        <el-col :xs="16" :sm="16" :md="16" :lg="16">
                          <div @click="jumpCustomer(item)">
                            <div class="msg-title">{{ item.title }}</div>
                            <div class="msg-content">
                              <a>{{ item.content }}</a>
                            </div>
                          </div>
                        </el-col>
                        <el-col :xs="8" :sm="8" :md="8" :lg="8">
                          <el-row type="flex" justify="end">
                            <el-col :span="9"> </el-col>
                            <el-col :span="6">
                              <el-tooltip
                                content="已读"
                                placement="top"
                                :delay="500"
                              >
                                <i
                                  class="el-icon-open"
                                  @click="readMsg(item)"
                                  size="18"
                                  color="#47cb89"
                                  v-if="item.readFlag === 0"
                                ></i>
                              </el-tooltip>
                            </el-col>
                            <el-col :span="3"></el-col>
                            <el-col :span="6">
                              <el-tooltip
                                content="删除"
                                placement="top"
                                :delay="500"
                              >
                                <i
                                  class="el-icon-close"
                                  @click="deleteMsg(item)"
                                  size="18"
                                  color="red"
                                ></i>
                              </el-tooltip>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col>
                              <div class="msg-content" style="text-align: right">
                                <el-tooltip
                                  :content="item.createTime"
                                  placement="top"
                                  :open-delay="500"
                                >
                                  <span>{{ timeago(item.createTime) }}</span>
                                </el-tooltip>
                              </div>
                            </el-col>
                          </el-row>
                        </el-col>
                    </el-row>
                  </template>
                </el-alert>
                <div style="text-align: center">
                  <el-button type="text" v-if="loadMoreBtn" @click="loadMore"
                    >加载更多</el-button>
                </div>
              </div>
              <br />
              <el-button size="small" type="success" style="width: 100%" @click="cleanMessage(item.name)"
                >清空消息</el-button
              >
            </div>
            <div v-else>暂无消息</div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-popover>
  </div>
</template>
<script>
import Socket from "@/utils/webSocket";
// import $HqNotification from "components/notification";
import {
  webSocketUrl,
  reqMessageCountInfo,
  reqMessageList,
  reqMessageDelete,
  reqMessageRead,
  reqMessageClean,
} from "api/message/message-api";
import { requestLogOut } from "@/api/login/login-api";

export default {
  name: "message",
  props: {
    method: {
      type: Function,
    }
  },
  data() {
    return {
      loadMoreBtn: true,
      visible: false,
      messageList: [
        { name: "2001", label: "消息", value: 0 },
        { name: "2007", label: "审批提醒", value: 0 },
        { name: "2005", label: "回呼", value: 0 },
        { name: "2000", label: "通知", value: 0 }
      ],
      msgTabs: "2001",
      msgCount: 0,
      value: 0,
      // 消息分页查询参数
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        queryKey: "type",
        queryValue: 2001,
      },
      // 消息列表数据
      msgList: [],
      // 通知模态框
      noticeModalVib: false,
      noticeModel: {},
    };
  },
  mounted() {
    this.socket();
    this.loadCountInfo()
    this.loadMessage()
  },
  methods: {
    messageClick() {
      this.msgTabs = "2001"
      this.loadMessage()
    },
    handleClick() {
      this.loadMessage();
    },
    // 通知详情-点击跳转
    detailTo(val) {
      if (val.type == 2000) {
        this.noticeModalVib = false;
        this.$router.push("/spread/survey");
      }
    },
    // 加载消息数量
    loadCountInfo() {
      reqMessageCountInfo().then((data) => {
        let { code, msg, result } = data;
        if (code === 0) {
          this.messageList[0].value = result.message;
          this.messageList[2].value = result.callBack;
          this.messageList[3].value = result.notice;
          this.messageList[1].value = result.collectApprove
          this.value = result.total
          // this.$store.commit("setMessageCount", result.total);
          // this.$store.commit("setSysCount", result.systemNotice);
          // this.$store.commit("setMsgCount", result.interactNotice);
          // this.value = result.total
        } else {
          this.$Message.info(msg);
        }
      });
    },
    // 加载消息数据列表
    loadMessage(flag) {
      this.pageParam.queryValue = this.msgTabs;
      let size = this.pageParam.pageSize;
      reqMessageList(this.pageParam).then((data) => {
        let { code, msg, result } = data;
        if (code === 0) {
          this.msgList = result.list;
          if(result.list.length === this.pageParam.pageSize) {
            this.loadMoreBtn = true;  
          } else {
            this.loadMoreBtn = false;
          } 
          
          if (flag && size > result.size) {
            this.pageParam.pageSize = result.size;
          }
        } else {
          this.$Message.info(msg);
        }
      });
      // 刷新消息数量
      this.loadCountInfo();
    },
    // 加载更多
    loadMore() {
      this.pageParam.pageSize = this.pageParam.pageSize + 10;
      this.loadMessage(true);
    },
    // 跳转客户详情或者跟进页面
    jumpCustomer(val) {
      if (val.data) {
        let data = JSON.parse(val.data);
        if (data.customerId) {
          // 根据客户编号，跳转道不同的商机详情页面，用于弹屏和消息中心的跳转
          // this.gotoCustomerInfo(data.customerId);
          this.method(data)
          this.readMsg(val);
        }

        if(data.processInstanceId) {
          this.readMsg(val);
          this.$router.push(`/afterSale/collection-detail?id=${data.processInstanceId}`)
        }
      }
    },
    // 弹出通知详情
    alertNotice(val) {
      this.noticeModalVib = !this.noticeModalVib;
      this.noticeModel = val;
      this.readMsg(val);
    },
    // 删除消息
    deleteMsg(val) {
      reqMessageDelete(val.msgId).then((data) => {
        let { code, msg } = data;
        if (code === 0) {
          this.loadMessage();
        } else {
          this.$Message.info(msg);
        }
      });
    },
    // 标记已读
    readMsg(val) {
      if (val.readFlag === 0) {
        reqMessageRead(val.msgId).then((data) => {
          let { code, msg } = data;
          if (code === 0) {
            this.loadMessage();
          } else {
            this.$Message.info(msg);
          }
        });
      }
    },
    // 清空消息
    cleanMessage(type) {
      reqMessageClean(type).then((data) => {
        let { code, msg } = data;
        if (code === 0) {
          this.loadMessage();
        } else {
          this.$Message.info(msg);
        }
      });
    },
    // 计算多久之前
    timeago(date) {
      let dateTimeStamp = new Date(date.replace(/-/g, "/")).getTime();
      // dateTimeStamp是一个时间毫秒，注意时间戳是秒的形式，在这个毫秒的基础上除以1000，就是十位数的时间戳。13位数的都是时间毫秒。
      // 把分，时，天，周，半个月，一个月用毫秒表示
      let minute = 1000 * 60;
      let hour = minute * 60;
      let day = hour * 24;
      let week = day * 7;
      let month = day * 30;
      // 获取当前时间毫秒
      let now = new Date().getTime();
      // 时间差
      let diffValue = now - dateTimeStamp;

      let result = date;
      if (diffValue < 0) {
        return result;
      }
      // 计算时间差的分，时，天，周，月
      let minC = diffValue / minute;
      let hourC = diffValue / hour;
      let dayC = diffValue / day;
      let weekC = diffValue / week;
      let monthC = diffValue / month;

      if (monthC >= 1 && monthC <= 3) {
        result = " " + parseInt(monthC) + "月前";
      } else if (weekC >= 1 && weekC <= 4) {
        result = " " + parseInt(weekC) + "周前";
      } else if (dayC >= 1 && dayC <= 7) {
        result = " " + parseInt(dayC) + "天前";
      } else if (hourC >= 1 && hourC <= 23) {
        result = " " + parseInt(hourC) + "小时前";
      } else if (minC >= 1 && minC <= 59) {
        result = " " + parseInt(minC) + "分钟前";
      } else if (diffValue >= 0 && diffValue <= minute) {
        result = "刚刚";
      }
      return result;
    },
    // websocket
    socket() {
      const _this = this;
      webSocketUrl().then((data) => {
        const { code, msg, result } = data;
        // this._debounce = debounce(this.loadMessage, 300)
        if (code === 0) {
          new Socket({
            // soket服务地址
            socketUrl: result,
            token: localStorage.getItem("userToken"),
            // 回调data
            soketCallback: (data) => {
              _this.soketCallback(data);
            },
          });
        } else {
          this.$Message.error(msg);
        }
      });
    },
    // soket回调
    soketCallback(data) {
      let { type, msg, result, msgId = new Date().getTime() } = data;
      console.log(msgId)
      switch (type) {
        case 1002:
          // 连接成功
          this.$message({
            type: "success",
            message: msg,
            duration: 1000,
            offset: 120
          });
          break;
        case 2001: // 消息
          if (!this.visible) {
            if (result.title == "转移商机" && this.isSoundOpen) {
              // 转移商机才给提示音
              // Video.getInstance().play();
            }
            // 首咨跟二咨弹窗不需要自动关闭
            if (
              result.title === "首次咨询" ||
              result.title === "二次咨询"
            ){
              this.$HqNotification({
                title: result.title,
                message: result.content,
                position: 'bottom-right',
                duration: 0,
                onClick: () => {
                  if (
                    result.title === "首次咨询" ||
                    result.title === "二次咨询"
                  ) {
                    this.method(result)
                  }
                }
              })

              // 0-不提醒;1-铃铛提醒;2-窗口提醒;3-铃铛+窗口提醒
            } else {
              this.$HqNotification({
                title: result.title,
                message: result.content,
                duration: 3000
              })
            }
          }
          this.loadCountInfo();
          break;
        case 2000: // 通知
          if (!this.visible) {
            this.$HqNotification({
              title: result.title,
              message: result.content,
              duration: 3000
            })
          }
          this.loadCountInfo();
          break;
        case 2005: // 回呼
          if (!this.visible) {
            this.$HqNotification({
              title: result.title,
              message: result.content,
              duration: 3000
            })
          }
          this.loadCountInfo();
          break;
        case 2003: // 互动通知
          if (!this.visible) {
            this.$HqNotification({
              title: result.title,
              message: result.content,
              duration: 3000
            })
          }
          this.loadCountInfo();
          break;
        case 2004: // 系统通知
          if (!this.visible) {
            this.$HqNotification({
              title: result.title,
              message: result.content,
              duration: 3000
            })
          }
          this.loadCountInfo();
          break;
        case 2002: // 待办
          break;
        case 2006: // 禁用账号通知
          if (!this.visible) {
            this.$HqNotification({
              type: "error",
              title: "温馨提示",
              message: result.content,
              duration: 0,
              name: "authc_error"
            });
            // 退出登录
            requestLogOut().then(() => {
              this.$store.commit("logout", this)
              if(process.env.VUE_APP_TITLE === "development") {
                location.href = `http://crm.dev.hqjy.com/#/login`
              } else if(process.env.VUE_APP_TITLE === "testing") {
                location.href = `http://crm.hqbis.com:18080/#/login`
              } else if(process.env.VUE_APP_TITLE === "production") {
                location.href = `http://crm.hqjy.com/#/login`
              }
            })
          }
          this.loadCountInfo();
          break;
          case 2007: // 凭证审核消息
            if (!this.visible) {
              this.$HqNotification({
                title: result.title,
                message: result.content,
                duration: 3000
              })
            }
            this.loadCountInfo()
            break;
        default:
          console.log("无法处理的websocket消息:", data);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .is-fixed {
  right: 22px !important;
}
.message {
  .msg-box {
    max-height: 400px;
    overflow-y: auto;
  }
  .badge {
    line-height: 32px;
  }
  .msg-alert {
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    .msg-title {
      margin-right: 80px;
      font-size: 15px;
    }
    .msg-content {
      font-size: 10px;
      white-space: normal;
      text-align: right;
    }
  }

  .msg-alert .ivu-alert-with-desc.ivu-alert-with-icon {
    padding: 5px 5px 3px 45px;
  }

  .msg-alert .ivu-alert-with-desc {
    margin-bottom: 5px;
  }

  .msg-alert .ivu-alert-with-desc .ivu-alert-icon {
    left: 16px;
  }

  .demo-spin-icon-load {
    animation: ani-demo-spin 1s linear infinite;
  }

  .message_box {
    position: relative;
    .message_voice {
      position: absolute;
      right: 0;
      top: 4px;
    }
  }
}
.msg-content {
  font-size: 10px;
  white-space: normal;
}
</style>
