/*
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-25 08:57:35
 * @LastEditors: Seven
 * @LastEditTime: 2022-04-18 11:04:14
 */
import axios from "axios";
import CryptoJS from "crypto-js";
import Crypto from "@/utils/crypto";

const baseUrl = "/qw_api";
const newPath = '/qw_new_api'

/**
 * 通过商机 customerId 获取成交信息
 */
export const getTransactionInfo = ( data )=>{
  return axios
  .post(`${newPath}/order/biz/order/getOrderDetail`, {
     ...data
  })
  .then((res) => res.data);
}

/**
 * 通过订单 orderId 获取成交信息
 */
export const getTransactionInfoByOrderId = ( data )=>{
  return axios.post(`${newPath}/order/ty/order/getOrderDetail?orderId=${data.orderId}`, data)
  .then((res) => res.data);
}

/**
 * 通过部门 获取成交信息
 */
 export const getDeptId = ( data )=>{
  // return axios.get(`${baseUrl}/sys/dept/${data}`)
  // .then((res) => res.data);
  return axios.get(`${baseUrl}/api/dept/getSchoolPropertiesByDeptId?deptId=${data}`)
  .then((res) => res.data);
}

/**
 * 通过部门 判断身份证是否必填
 */
export const checkIdCard = ( data )=>{
  // return axios.get(`${baseUrl}/sys/dept/${data}`)
  // .then((res) => res.data);
  return axios.get(`/qw_school/schoolcj/order/api/util/idCard/necessity?crmDeptId=${data}`)
  .then((res) => res.data);
}

/**
 * 转校获取成交信息
 */
 export const getTransferDetail = ( data )=>{
  return axios.post(`${newPath}/order/api/open/v1/ty/order/query/transferDetail`, data)
  .then((res) => res.data);
}


// 我的列表
export const getcustomerList = (data, url) => {
  return axios
  .post(`${baseUrl}/workbench/biz/customer/myCustomer/${url}`, data)
  .then((res) => res.data);
}
// 新增客户
export const doAddCustomer = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/myCustomer/save`, data)
    .then((res) => res.data);
}
// 我的客户标签下拉框数据
export const getDefLableListLabel = () => {
  return axios.get(`${baseUrl}/defLable/listLabel`).then(res => res.data)
}
// 移入移出留存
export const doAddReserve = (customerId, sign) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/myCustomer/addReserve/${customerId}/${sign}`)
    .then((res) => res.data);
}
// 移入移出留存判断余额
export const getReserveJudge = (customerId, sign) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/myCustomer/reserveJudge/${customerId}/${sign}`)
    .then((res) => res.data);
}
/**批量移入移出留存 */
export function receiveBatch(data){
  return axios.post(`${baseUrl}/workbench/biz/customer/myCustomer/reserveBatch`,data).then(res=>res.data)
}
// 无效操作
export const doSetCustomerInvalid = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/myCustomer/setCustomerInvalid`, data)
    .then((res) => res.data);
}
// 客户转移
export const doTransferCustomer = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/myCustomer/transferCustomer`, data)
    .then((res) => res.data);
}
// 客户查询转移
export const doTransferCustomersearch = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/myCustomer/transferCustomerBySelect`, data)
    .then((res) => res.data);
}
// 导出客户
export const doExportCustomer = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/myCustomer/exportCustomer`, data)
    .then((res) => res.data);
}
// 导入客户
export const doImportCustomer = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/myCustomer/importCustomer`, data)
    .then((res) => res.data);
}
// 放弃
export const doReturnToCommon = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/myCustomer/returnToCommon`, data)
    .then((res) => res.data);
}
// 客户预约同时转商机
export const doAppointAndTransferToNc = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/myCustomer/appointAndTransferToNc`, data)
    .then((res) => res.data);
}
// 客户预约
export const doReserveCustomer = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/myCustomer/reserveCustomer`, data)
    .then((res) => res.data);
}
// 判断是否可查看电话号码
export const getJudgetLookContack = () => {
  return axios.post(`${baseUrl}/workbench/biz/operation/judgeLookContact`).then(res => res.data)
}
// 记录用户操作记录
export const doOperationSave = (data) => {
  return axios.post(`${baseUrl}/workbench/biz/operation/save`, data).then(res => res.data)
}
// 针对联系方式:手机号或座机号是否允许更改或删除
export const doIsAllowUpdateOrDelete = (data) => {
  return axios.post(`${baseUrl}/workbench/biz/customer/contact/isAllowUpdateOrDelete`, data).then(res => res.data)
}
// 编辑联系方式
export const doUpdateDetail = (data) => {
  return axios.post(`${baseUrl}/workbench/biz/customer/contact/updateDetail`, data).then(res => res.data)
}
// 删除联系方式
export const doDeleteDetail = (data) => {
  return axios.post(`${baseUrl}/workbench/biz/customer/contact/deleteDetail`, data).then(res => res.data)
}
// 列表查看联系方式
export const doSeePhone = (customerId,contactId) => {
  if (contactId) {
    return axios.get(`${baseUrl}/workbench/biz/customer/myCustomer/seePhone?customerId=${customerId}&contactId=${contactId}`).then(res => res.data)
  } else {
    return axios.get(`${baseUrl}/workbench/biz/customer/myCustomer/seePhone?customerId=${customerId}`).then(res => res.data)
  }
}
// 加密
export const doJiamiSeePhone = (customerId,contactId) => {
  if (contactId) {
    return axios.get(`${baseUrl}/workbench/biz/customer/myCustomer/see/phone?customerOpenId=${customerId}&contactOpenId=${contactId}`).then(res => res.data)
  } else {
    return axios.get(`${baseUrl}/workbench/biz/customer/myCustomer/see/phone?customerOpenId=${customerId}`).then(res => res.data)
  }
}
// 订单列表查看联系方式
export const doSeeorderPhone = (customerId,orderId) => {
  if (orderId) {
    return axios.get(`${newPath}/order/ty/order/tySeePhone?customerId=${customerId}&orderId=${orderId}`).then(res => res.data)
  } else {
    return axios.get(`${newPath}/order/ty/order/tySeePhone?customerId=${customerId}`).then(res => res.data)
  }
}
// 修改跟进记录
export const doFollowUpdate = (data) => {
  return axios.post(`${baseUrl}/workbench/biz/customer/follow/updateNewest`, data).then(res => res.data)
}
// 动态详情
export const getNewestDetail = (customerId) => {
  return axios.get(`${baseUrl}/workbench/biz/customer/follow/newestDetail/${customerId}`).then(res => res.data)
}
// 默认标签列表
export const getDefLableGetDef = () => {
  return axios.get(`${baseUrl}/workbench/biz/defLable/getDef`).then(res => res.data)
}
// 查询标签列表
export const getDefLableList = () => {
  return axios.get(`${baseUrl}/workbench/biz/defLable/list`).then(res => res.data)
}
// 添加默认标签
export const doDefLableSave = (labelId, sysLabelId) => {
  return axios.post(`${baseUrl}/workbench/biz/defLable/save?labelId=${labelId}&sysLabelId=${sysLabelId}`).then(res => res.data)
}
// 判断商机是否在当前用户数据权限内
export const getBizInCurrentUserPermissions = (customerId) => {
  return axios.post(`${baseUrl}/workbench/biz/customer/myCustomer/bizInCurrentUserPermissions?customerId=${customerId}`).then(res => res.data)
}
// 话术标签组列表
export const getgroupListPage = (trackType) => {
  return axios.get(`${baseUrl}/talk/group/listPage?trackType=${trackType}`).then(res => res.data)
}
// 话术列表
export const getVerbalTrickListPage = (data) => {
  return axios.post(`${baseUrl}/talk/verbalTrick/list`, data).then(res => res.data)
}
// 话术
export const getGroupLabel = (data) => {
  return axios.post(`${baseUrl}/talk/groupLabel/findBYurlList`, data).then(res => res.data)
}
// 公海列表
export const getHigseaList = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/common/list`, data)
    .then((res) => res.data);
}
// 详情领取
export const doDetailReceive = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/myCustomer/receive`, data)
    .then((res) => res.data);
}
// 公海领取
export const doHigseaReceive = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/common/receive`, data)
    .then((res) => res.data);
}
// 公海客户标签
export const getHigseaLabel = () => {
  return axios
    .get(`${baseUrl}/workbench/biz/customer/common/label`)
    .then((res) => res.data);
}
// 话务列表
export const getCallRecordList = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/call/record/list`, data)
    .then((res) => res.data);
}
// 获取录音文件地址
export const getCallRecordFile = (id, type) => {
  return axios
    .get(`${baseUrl}/workbench/biz/call/record/file/${id}/${type}`)
    .then((res) => res.data);
}

// 预约列表
export const getAppointmentList = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/appointment/list`, data)
    .then((res) => res.data);
}
// 预约修改
export const doAppointmentUpdate = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/appointment/update`, data)
    .then((res) => res.data);
}
// 预约转NC
export const doAppointmentTransferNc = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/appointment/transfer/nc`, data)
    .then((res) => res.data);
}
// 预约单修改
export const doAppointmentTeacherUpdate = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/appointment/appointmentTeacherUpdate`, data)
    .then((res) => res.data);
}
// 预约单修改
export const doAppointmentExport = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/appointment/export`, data)
    .then((res) => res.data);
}
// 转NC列表
export const getTransferNcList = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/transfer/nc/list`, data)
    .then((res) => res.data);
}
// 转NC修改
export const getTransferNcUpdate = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/transfer/nc/update`, data)
    .then((res) => res.data);
}
// 冻结列表
export const getFreezeList = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/freeze/list`, data)
    .then((res) => res.data);
}
// 冻结释放
export const doFreezeRelease = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/freeze/release`, data)
    .then((res) => res.data);
}

// 质检列表
export const getInvalidList = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/invalid/list`, data)
    .then((res) => res.data);
}
// 质检列表质检
export const doInvalidInspect = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/invalid/inspect`, data)
    .then((res) => res.data);
}
// 质检列表导出
export const doInvalidExport = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/invalid/export`, data)
    .then((res) => res.data);
}
// 动态列表
export const getFollowList = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/follow/list`, data)
    .then((res) => res.data);
}

// 根据客户ID获取跟进列表
export const getFollowByCustomerId = (customerId) => {
  return axios
    .get(`${baseUrl}/workbench/biz/customer/follow/findListByCustomerId/${customerId}`)
    .then((res) => res.data);
}
// 根据客户ID获取客户详情
export const getCustomerDetail = (customerId) => {
  return axios
    .get(`${baseUrl}/workbench/biz/customer/myCustomer/getCustomerDetail?customerId=${customerId}`)
    .then((res) => res.data);
}

// 根据客户ID获取基本资料
export const getCustomerData = (customerId) => {
  return axios
    .get(`${baseUrl}/workbench/biz/customer/myCustomer/getCustomerData?customerId=${customerId}`)
    .then((res) => res.data);
}


// 根据客户ID获取基本资料加密
export const getJiamiCustomerData = (customerId) => {
  const key = CryptoJS.enc.Utf8.parse("uB3h7Nj6gO4yZfW2");
  const iv = CryptoJS.enc.Utf8.parse("uB3h7Nj6gO4yZfW2");
  let str = customerId.toString()
  console.log('str', str);
  return axios
    .get(`${baseUrl}/workbench/biz/customer/myCustomer/baseInfo?customerOpenId=${Crypto.encrypt(key,iv, str)}`)
    .then((res) => res.data);
}

// 客户成交汇总
export const getDealSummary = (customerId) => {
  return axios
    // .get(`${baseUrl}/workbench/biz/customer/deal/summary/${customerId}`)
    .get(`${newPath}/order/ty/order/settlement/summary/${customerId}`)
    .then((res) => res.data);
}
// 修改基本资料
export const doMyCustomerUpdate = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/myCustomer/update`, data)
    .then((res) => res.data);
}
// 根据客户ID获取预约
export const getFindListByCustomerId = (customerId) => {
  return axios
    .get(`${baseUrl}/workbench/biz/customer/appointment/findListByCustomerId/${customerId}`)
    .then((res) => res.data);
}
// 根据客户ID获取留存
export const getreserveByCustomerId = (customerId) => {
  return axios
    .get(`${baseUrl}/workbench/biz/customer/reserve/findListByCustomerId/${customerId}`)
    .then((res) => res.data);
}
// 根据客户ID获取重单
export const getRepeatByCustomerId = (customerId) => {
  return axios
    .get(`${baseUrl}/workbench/biz/customer/repeat/findListByCustomerId/${customerId}`)
    .then((res) => res.data);
}
// 根据客户ID获取流程
export const getProcessByCustomerId = (customerId) => {
  return axios
    .get(`${baseUrl}/workbench/biz/customer/process/findListByCustomerId/${customerId}`)
    .then((res) => res.data);
}
// 根据客户ID获取质检
export const getInvalidByCustomerId = (customerId) => {
  return axios
    .get(`${baseUrl}/workbench/biz/customer/invalid/findListByCustomerId/${customerId}`)
    .then((res) => res.data);
}
// 根据客户ID获取客服对话
export const getCustomerConversation = (customerId) => {
  return axios
    .get(`${baseUrl}/workbench/biz/conversation/getCustomerConversation?customerId=${customerId}`)
    .then((res) => res.data);
}
// 新增跟进记录
export const doAddFollow = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/follow/save`, data)
    .then((res) => res.data);
}
// 客户查询已绑定标签
export const getCustomerLabelList = (customerId) => {
  return axios.get(`${baseUrl}/defLable/customerLabel/usedList?customerId=${customerId}`).then(res => res.data)
}
// 删除标签
export const doCustomerLabelDel = (customerId, usedId) => {
  return axios.post(`${baseUrl}/defLable/customerLabel/del?customerId=${customerId}&usedId=${usedId}`).then(res => res.data)
}
// 赛道获取分组标签
export const getLabelByTrackTypeList = (trckType) => {
  return axios.get(`${baseUrl}/lable/trckType/list?trckType=${trckType}`).then(res => res.data)
}
// 客户绑定标签
export const doCustomerLabelSave = (customerId, labelId) => {
  return axios.post(`${baseUrl}/defLable/customerLabel/save?customerId=${customerId}&labelId=${labelId}`).then(res => res.data)
}
// 新增联系方式
export const doAddContact = (data) => {
  return axios.post(`${baseUrl}/workbench/biz/customer/contact/save`, data).then(res => res.data)
}
// 新增联系方式
export const getPhoneList = (customerId) => {
  return axios.get(`${baseUrl}/workbench/biz/customer/contact/getPhone?customerId=${customerId}`).then(res => res.data)
}
// 商机操作动态
export const getOperationList = (customerId) => {
  return axios.get(`${baseUrl}/workbench/biz/operation/findListByCustomerId/${customerId}`).then(res => res.data)
}
// 转介绍列表
export const getRecomendCustomerList = (data) => {
  return axios.post(`${newPath}/order/biz/recommend/customer/list`, data).then(res => res.data)
}
// 转介绍导出
export const doRecomendCustomerExport = (data) => {
  return axios.post(`${newPath}/order/biz/recommend/customer/export`, data).then(res => res.data)
}
/**
 * 升班校验
 * @link http://10.0.99.9:8081/order/doc.html#/default/%E5%8D%87%E7%8F%AD%E6%A0%A1%E9%AA%8C/promotionCheckUsingPOST
 */
export const doPromotionCheck = (data) => {
  return axios.post(`${newPath}/order/promotion/promotionCheck`, data).then(res => res.data)
}
/**
 * 获取出纳列表
 * @link http://crmwbench.test.hqjy.com/qw_new_api/order/doc.html#/default/%E5%87%BA%E7%BA%B3%E4%BA%BA%E6%8E%A5%E5%8F%A3/getSchoolUserListUsingGET
 */
export const getSchoolUserCashierVerifiedUser = (params) => {
  return axios.get(`${newPath}/order/ty/cashier/getSchoolUserList`, {params}).then(res => res.data)
}
/**
 * 获取校区属性接口
 * @link http://10.0.99.9:8081/order/doc.html#/default/%E5%87%BA%E7%BA%B3%E4%BA%BA%E6%8E%A5%E5%8F%A3/getSchoolPropertiesUsingGET 
 */
export const getSchoolProperties = (params) => {
  return axios.get(`${newPath}/order/ty/cashier/getSchoolProperties`, {params}).then(res => res.data)
}
/**
 * 转班升班报名单新增商品变更时触发校验
 * @param {{customerId:Number; tyProductTypeId:Number}} data 
 * @link http://crmwbench.test.hqjy.com/qw_new_api/order/doc.html#/default/%E5%A4%87%E6%A1%88%E5%88%97%E8%A1%A8/transferSignUpJudgeRecommendUsingPOST
 */
export const transferSignUpJudgeRecommend = (data) => {
  return axios.post(`${newPath}/order/biz/recommend/customer/transferSignUpJudgeRecommend`, data).then(res => res.data)
}

// 导出客户--外呼记录
export const doExportCall = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/call/record/export2`, data)
    .then((res) => res.data);
}

// 判断是否需要弹窗选择成交校区
export const doChoiceDealSchool = (data) => {
  return axios
    .post(`${newPath}/order/biz/order/choiceDealSchool`, data)
    .then((res) => res.data);
}

// 预约转nc:自考赛道教务主管人员使用
export const dozikaoTransferNc = (data) => {
  return axios
    .post(`${baseUrl}/workbench/biz/customer/appointment/transfer/nc/self`, data)
    .then((res) => res.data);
}