/*
 * @Description:
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-09 16:40:07
 * @LastEditors: Seven
 * @LastEditTime: 2021-08-18 11:06:12
 */
import { allRoutes } from "@/router/moudles"
// const app = {
//   state: {
//     secondRouter: []
//   },
//   mutations: {
//     setSecondRouter(state, result) {
//       state.secondRouter = result
//     }
//   },
//   actions: {},
//   getters: {}
// }
const state = {
    secondRouter: [],
    currentPageName: "",
    routers: allRoutes,
    currentPath: []
  },
  mutations = {
    setSecondRouter(state, result) {
      state.secondRouter = result;
    },
    setCurrentPageName (state, name) {
      state.currentPageName = name
    },
    setCurrentPath (state, pathArr) {
      state.currentPath = pathArr
    },
  },
  actions = {},
  getters = {};

export default { state, mutations, actions, getters };
