<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-18 15:22:12
 * @LastEditors: Seven
 * @LastEditTime: 2021-06-18 15:26:50
-->
<template>
  <div>
    <slot :dictData="getterDictDataValue || dictData"></slot>
  </div>
</template>

<script>
/**
 * 数据字典下拉选择组件
 */
import { mapGetters, mapActions } from "vuex";
import * as dictConstant from "@/utils/dict";

export default {
  props: {
    dictModel: null,
    refresh: {
      default: false,
    },
    code: {
      default: null,
    },
  },
  data() {
    return {
      dictData: [],
    };
  },
  computed: {
    ...mapGetters(["getterDictData"]),
    getterDictDataValue() {
      let arr = [];
      if (this.getterDictData[this.code] && this.code === "INVALID_TYPE") {
        arr = this.getterDictData[this.code].filter(
          (item) => item.label !== "无法核实"
        );
      } else {
        arr = this.getterDictData[this.code];
      }
      return arr;
    },
  },
  methods: {
    ...mapActions(["getDictData"]),
  },
  mounted() {
    if (this.getterDictData[this.code] === undefined) {
      this.$nextTick(() => {
        this.getDictData(dictConstant[this.code]).then((res) => {
          if (this.code == "INVALID_TYPE") {
            this.dictData = res.filter((item) => item.label !== "无法核实");
          } else {
            this.dictData = res;
          }
        });
      });
    }
  },
};
</script>
<style>
</style>
