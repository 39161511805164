<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-18 10:18:11
 * @LastEditors: Seven
 * @LastEditTime: 2022-03-16 09:37:58
-->
<template>
  <transition name="confirmbox-fade">
    <div class="confirm_wrapper" v-show="visible">
      <div
        class="confirm_box"
        ref="confirmBox"
        :style="{ width: width?width + 'px':'450px' }"
      >
        <p class="confirm_title">
          <span class="sign"></span>
          {{ title || "提示" }}
        </p>
        <i class="el-icon-close delete" @click="otherClick"></i>
        <div class="modal__body"><slot></slot></div>
        <div class="footer_button">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Confirm",
  props: {
    title: {
      type: String,
    },
    visible: {
      type: Boolean,
    },
    width: {
      type: String,
      default: "450",
    }
  },
  data() {
    return {};
  },
  methods: {
    otherClick() {
      this.$emit("on-cancel");
      // let confirmBox = this.$refs.confirmBox;
      // if (e.target.contains(confirmBox)) {
      //   this.cancel();
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm_wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  overflow-y: auto;
  .confirm_box {
    // height: 500px;
    vertical-align: middle;
    background-color: #fff;
    // border-radius: 4px;
    border: 1px solid #ebeef5;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    text-align: left;
    // overflow: hidden;
    backface-visibility: hidden;
    position: relative;
    .confirm_title {
      display: flex;
      align-items: center;
      height: 56px;
      line-height: 56px;
      padding-left: 18px;
      font-size: 16px;
      color: #333;
      font-weight: bold;
      border-bottom: 1px solid #e1e4eb;
      .sign {
        height: 15px;
        width: 4px;
        border-radius: 4px;
        margin-right: 8px;
      }
    }
    .delete {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }
    .modal__body {
      // text-align: center;
      color: #333;
      padding: 26px 26px;
      font-size: 14px;
      // line-height: 20px;
      max-height: 650px;
      overflow-y: auto;
      position: relative;
    }
    .footer_button {
      display: flex;
      justify-content: center;
      padding: 18px 0;
      .button {
        line-height: 1;
        cursor: pointer;
        background: #fff;
        border: 1px solid #dcdfe6;
        color: #606266;
        text-align: center;
        box-sizing: border-box;
        transition: 0.1s;
        padding: 10px 30px;
        font-size: 14px;
        border-radius: 4px;
        &.define {
          margin-left: 12px;
          color: #fff;
          background-color: #337AFF;
          border-color: #337AFF;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.confirmbox-fade-enter-active {
  -webkit-animation: confirmbox-fade-in 0.3s;
  animation: confirmbox-fade-in 0.3s;
}
.confirmbox-fade-leave-active {
  -webkit-animation: confirmbox-fade-out 0.3s;
  animation: confirmbox-fade-out 0.3s;
}
@keyframes confirmbox-fade-in {
  0% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes confirmbox-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
}
</style>