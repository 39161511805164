<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-18 14:43:45
 * @LastEditors: Seven
 * @LastEditTime: 2022-03-24 14:29:31
-->
<template>
  <div class="check-box" v-if="list.length > 0">
    <span class="tag-name" v-if="label">{{ label }}</span>
    <div class="label-list" v-if="list.length">
      <span
        :style="{marginLeft: list.length === 1?'0':'10px', padding: list.length === 1?'0 36px':'0 16px'}"
        :class="['label', active == item.value ? 'active' : '']"
        v-for="(item, index) in list"
        @click="labelClick(item, index)"
        :key="item.value"
        >{{ item.label }}</span
      >
    </div>
    <span @click="labelClick(list[0])" 
    v-else-if="!list.length" 
    :class="['label', select == 1 ? 'active' : '']" style="padding: 0 36px; margin-left: 0">{{ list[0].label }}</span>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
    },
    list: {
      type: Array,
    },
    select: {
      // type: String,
      default: null
    },
    disabled: {
      default: false
    }
  },
  data() {
    return {
      active: null,
    };
  },
  mounted() {
    this.active = this.select === null?this.select:Number(this.select)
  },
  methods: {
    setStyle() {
      this.active = null
    },
    labelClick(item, index) {
      if(this.disabled) return false
      if(this.active == item.value && this.list.length && this.label != '微信验证状态') return
      if(this.label && this.label != '微信验证状态') {
        this.active = item.value
      } else {
        if(this.active === item.value) {
          this.active = null
        } else {
          this.active = item.value
        }
      }
      if(this.label) {
        this.$emit("update:select", this.label != '微信验证状态'?item.value:this.active) 
      } else {
        if(this.disabled) return false
        this.$emit("update:select", this.active)
      }
    },
    init() {
      this.active = null
    },
    set(value) {
      this.active = Number(value)
      console.log('this.active', this.active);
    }
  },
  watch: {
    // active(val, oldVal) {
    //   if(oldVal && !val) {
    //     this.$emit("update:select", "");
    //   }
    // }
  }
}
</script>
<style lang="scss" scoped>
.check-box {
  // display: flex;
  // justify-content: space-between;
  // .label-name {
    // flex: 1;
    .tag-name {
      float: left;
      display: inline-block;
      // width: 106px;
      margin-left: 0;
    }
  // }

  .label-list {
    text-align: left;
    overflow: hidden;
    // flex: 3;
  }
  span {
    display: inline-block;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    margin-left: 10px;
  }

  .label {
    background: #ffffff;
    border: 1px solid #e1e4eb;
    position: relative;
    cursor: pointer;
  }

  .active {
    background-color: #e5f2ff;
    border: 1px solid #337aff;
    &::before {
      content: "✔";
      display: block;
      position: absolute;
      top: -8px;
      right: 3px;
      font-size: 10px;
      color: #fff;
      z-index: 1000;
    }
    &::after {
      content: "";
      display: block;
      height: 0px;
      width: 0px;
      position: absolute;
      top: 0;
      right: 0;
      color: #fff;
      border: 15px solid;
      border-color: #337aff #337aff transparent transparent;
    }
  }
}
</style>
