/*
 * @Description:
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-09 14:43:13
 * @LastEditors: in hengqi by mengze
 * @LastEditTime: 2021-10-07 17:23:25
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "@/utils/request";
import $fetch from './utils/fetch'
import dayjs from 'dayjs'
import './filters'
import '@/assets/iconfont/iconfont.css'
import "@/plugins/element";
import "element-ui/lib/theme-chalk/index.css";
// import Vuetify from "vuetify";
// import "vuetify/dist/vuetify.min.css";
import vuetify from "@/plugins/vuetify";

import "@/assets/css/common.scss";
import "@mdi/font/css/materialdesignicons.css";
import $HqNotification from "components/notification";

import messageBox from "@/plugins/hqMessageBox";
import hqModal from "@/plugins/hqModal";
import hqDrawer from "@/plugins/hqDrawer";
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts


// this.$message.error()

import Perm from "@/perm";
Vue.prototype.permUtil = Perm
Vue.prototype.$fetch = $fetch
Vue.prototype.$dayjs = dayjs
Vue.prototype.$bus = new Vue();

// 手机号码加密
import { getTel, getInputreset } from "@/utils/common";
Vue.prototype.$getTel = getTel
Vue.prototype.$getInputreset = getInputreset
// 通知
Vue.prototype.$HqNotification = $HqNotification
Vue.prototype.$dayjs = dayjs

// 过滤器
import "@/utils/filters";

// 复制到粘贴版
import VueClipboard from 'vue-clipboard2'

Vue.config.productionTip = false;
// Vue.use(Vuetify);
Vue.use(messageBox);
Vue.use(VueClipboard)

Vue.component("HqModal", hqModal);
Vue.component("HqDrawer", hqDrawer)



window.vue = new Vue({
  router,
  store,
  vuetify,
  // vuetify: new Vuetify(),
  render: (h) => h(App),
}).$mount("#app");
