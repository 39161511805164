/*
 * @Description:
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-10 09:43:49
 * @LastEditors: Seven
 * @LastEditTime: 2021-12-06 14:18:43
 */
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify)

export default new Vuetify({})
