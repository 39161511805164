/*
 * @Description:
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2020-03-17 10:18:04
 * @LastEditors: Seven
 * @LastEditTime: 2021-07-26 17:54:04
 */
import axios from "axios";

const baseUrl = "/qw_api";

// 通过customerId，去后台拿userFieId
export const getUserFieIdGetByCustomerId = (customerId) => {
  return axios
    .post(`${baseUrl}/call/saveAndId/${customerId}`)
    .then((res) => res.data);
};
// 请求CallCenter 登录验证码
export const reqTinetCode = () => {
  return axios.get(`${baseUrl}/tinet/code`).then((res) => res.data);
};

// 请求CallCenter帐号登录
export const reqTinetLogin = (params) => {
  return axios.post(`${baseUrl}/tinet/login`, params).then((res) => res.data);
};

// CallCenter用户 信息
export const reqTinetUserInfo = () => {
  return axios.get(`${baseUrl}/tinet/userInfo`).then((res) => res.data);
};

// 检测CallCenter用户账户
export const reqTinetCheck = (params) => {
  return axios.post(`${baseUrl}/tinet/check`, params).then((res) => res.data);
};
// 众投互联
export const reqLoginCheck = (params) => {
  return axios.post(`${baseUrl}/call/center/login`, params).then(res => res.data)
}
// 呼叫电话
export const reqLogincall = (params) => {
  return axios.post(`${baseUrl}/call/center/call`, params).then(res => res.data)
}
// 挂断 
export const reqLoginhangup = (params) => {
  return axios.get(`${baseUrl}/call/center/hang_up/${params}`).then(res => res.data)
}
// 注销
export const reqlogout = (params) => {
  return axios.get(`${baseUrl}/call/center/logout`, params).then(res => res.data)
}
// 拨号登录
export const reqLoginphoneCheck = (params) => {
  return axios.get(`${baseUrl}/call/center/user/info`,  { params }).then(res => res.data)
}
// 获取坐席配置 
export const requserConfigCheck = () => {
  return axios.get(`${baseUrl}/call/center/user/config`).then(res => res.data)
}

// 获取CallCenter用户绑定电话列表
export const reqTinetCnoTel = () => {
  return axios.get(`${baseUrl}/tinet/cnotel`).then((res) => res.data);
};

// 设置CallCenter用户绑定电话
export const reqTinetSetTel = (tel) => {
  return axios
    .put(`${baseUrl}/tinet/settel` + "/" + tel)
    .then((res) => res.data);
};

// CallCenter发送电话验证码
export const reqTinetTelCode = (tel) => {
  return axios
    .get(`${baseUrl}/tinet/telCode` + "/" + tel)
    .then((res) => res.data);
};

// CallCenter验证电话验证码
export const reqTinetCheckTelCode = (params) => {
  return axios
    .post(`${baseUrl}/tinet/checkTelCode`, params)
    .then((res) => res.data);
};

// CallCenter用户注销
export const reqTinetLogout = () => {
  return axios.put(`${baseUrl}/tinet/logout`).then((res) => res.data);
};
// 绑定成功后
export const reqTinetLoginBack = (tel) => {
  return axios
    .get(`${baseUrl}/tinet/cnologin` + "/" + tel)
    .then((res) => res.data);
};
// 天润呼叫中心列表
// export const getEnterpriseList = () => {
//   return axios.get(`${baseUrl}/tinet/enterprise/list`).then((res) => res.data);
// };
export const getEnterpriseList = (source) => {
  return axios.get(`${baseUrl}/call/center/config/list/${source}`).then((res) => res.data);
};

// 获取天润坐席cno对象
export const getCnoList = (enterpriseId) => {
  return axios
    .get(`${baseUrl}/tinet/cno/list/${enterpriseId}`)
    .then((res) => res.data);
};
// 绑定成功后
export const getFindSeatNumber = () => {
  return axios
    .get(`${baseUrl}/sys/user/findSeatNumber`)
    .then((res) => res.data);
};
