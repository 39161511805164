var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "confirmbox-fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible"
          }
        ],
        staticClass: "confirm_wrapper",
        on: { click: _vm.otherClick }
      },
      [
        _c("div", { ref: "confirmBox", staticClass: "confirm_box" }, [
          _c("p", { staticClass: "confirm_title" }, [
            _c("span", { staticClass: "sign" }),
            _vm._v(" " + _vm._s(_vm.title || "提示") + " ")
          ]),
          _vm.message
            ? _c("p", { staticClass: "content_text" }, [
                _vm._v(" " + _vm._s(_vm.message) + " ")
              ])
            : _vm._e(),
          _vm.messageHtml
            ? _c("p", {
                staticClass: "content_text",
                domProps: { innerHTML: _vm._s(_vm.messageHtml) }
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "footer_button" },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.define } },
                [_vm._v(" " + _vm._s(_vm.btnText || "确定") + " ")]
              )
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }