var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      class: ["orange-panel", _vm.isShow ? "show positionAbsolute" : ""],
      style: { left: _vm.isShow ? _vm.callWidth : "-320px" }
    },
    [
      _c("i", {
        staticClass: "el-icon-phone-outline",
        staticStyle: {
          "line-height": "90px",
          "margin-right": "20px",
          "font-size": "30px",
          color: "green"
        },
        attrs: { size: "30", color: "#19be6b" }
      }),
      _c(
        "div",
        { staticStyle: { "margin-top": "14px" } },
        [
          _c(
            "div",
            { staticStyle: { "font-weight": "bolder", "font-size": "16px" } },
            [
              _vm.getTinetCallStatus === 1
                ? _c("span", { staticStyle: { color: "#2d79ff" } }, [
                    _vm._v("呼叫坐席中：")
                  ])
                : _vm._e(),
              _vm.getTinetCallStatus === 2
                ? _c("span", { staticStyle: { color: "green" } }, [
                    _vm._v("振铃中：")
                  ])
                : _vm._e(),
              _vm.getTinetCallStatus === 3
                ? _c("span", { staticStyle: { color: "#ff1f31" } }, [
                    _vm._v("通话中：")
                  ])
                : _vm._e(),
              _vm.getTinetCallStatus === 4
                ? _c("span", { staticStyle: { color: "#ff8905" } }, [
                    _vm._v("结束通话：")
                  ])
                : _vm._e(),
              _vm.getTinetCallStatus === 5
                ? _c("span", { staticStyle: { color: "#ff8905" } }, [
                    _vm._v("来电号码：")
                  ])
                : _vm._e(),
              _vm.getTinetCallTel
                ? _c("span", [_vm._v(_vm._s(_vm.$getTel(_vm.getTinetCallTel)))])
                : _vm._e()
            ]
          ),
          _c(
            "el-tag",
            {
              staticStyle: { "margin-right": "4px" },
              attrs: { size: "small", effect: "dark" }
            },
            [_vm._v(_vm._s(_vm.getTinetCallTimes))]
          ),
          _c(
            "el-tag",
            { attrs: { size: "small", type: "success", effect: "dark" } },
            [_vm._v(_vm._s(_vm.getTinetCallAttribution))]
          )
        ],
        1
      ),
      _c("i", {
        staticClass: "stop-call el-icon-phone",
        attrs: { size: "30", color: "#19be6b" },
        on: { click: _vm.actionTinetDoUnLink }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }